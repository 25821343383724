import * as React from 'react';

import {
    Button,
    Container, 
    FormControlLabel, 
    Stack, 
    Switch, 
    TextField
} from '@mui/material';

import { UnityAppPlayer } from '../../Components/UnityApp';
import { Scenario } from '../../../App/Store/SimpleCreateExperiment/Types';
import { useTranslation } from 'react-i18next';

import PlayScenarioModal from './ScenariosModal/PlayScenarioModal';

export default function DevPlayView()
{
    const { t } = useTranslation();

    const [ modalOpen, setModalOpen ] = React.useState(false);
    const [ selectedScenario, setSelectedScenario ] = React.useState<Scenario | null>(null);
    const [ expId, setExpId ] = React.useState<number | null>(25);
    const [ downloadOnEnd, setDownloadOnEnd ] = React.useState(true);
    const [ skipPreparationSteps, setSkipPreparationSteps ] = React.useState(false);
    const [ trackProgress, setTrackProgress ] = React.useState(true);

    const IsReady = () => selectedScenario !== null && expId !== null

    return (
        <Container maxWidth='xl'>
            { 
                !IsReady() && 
                (
                    <>
                        <Stack
                            direction='column'
                            justifyContent='center'
                            alignItems='center'
                            my='2rem'
                        >
                            <Button 
                                variant = 'contained'
                                onClick = { () => setModalOpen(true) }
                                sx = {{
                                    mb: '2rem'
                                }}
                            >
                                { t('teacher_play_alone.scenario_modal.open') }
                            </Button>
                            <TextField label="expId"
                                value={ expId } 
                                onChange = { (event) => setExpId(Number(event.target.value) || expId) }
                            />
                            <FormControlLabel control={<Switch
                                checked = { downloadOnEnd }
                                onChange = { () => setDownloadOnEnd(!downloadOnEnd) }
                            />} label='downloadOnEnd'/>
                            <FormControlLabel control={<Switch
                                checked = { skipPreparationSteps }
                                onChange = { () => setSkipPreparationSteps(!skipPreparationSteps) }
                            />} label='skipPreparationSteps'/>
                            <FormControlLabel control={<Switch
                                checked = { trackProgress }
                                onChange = { () => setTrackProgress(!trackProgress) }
                            />} label='trackProgress'/>
                        </Stack>
                        <PlayScenarioModal
                            open = { modalOpen }
                            handleClose = { () => setModalOpen(false) }
                            selectedScenario = { selectedScenario }
                            setSelectedScenario = { (scenario) => setSelectedScenario(scenario) }
                        />
                    </>
                )
            }
            {
                IsReady() &&
                (
                    <UnityAppPlayer
                        scenarioRef = { (selectedScenario as Scenario).applicationRef }
                        downloadOnEnd = { downloadOnEnd }
                        skipPreparationSteps = { skipPreparationSteps }
                        trackProgress = { trackProgress }
                        exprimentId={ expId || undefined }
                    />
                )
            }
        </Container>
    );
}