import * as React from 'react';

import { styled, Theme, CSSObject, useTheme } from '@mui/material/styles';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { useDispatch, useSelector } from 'react-redux';

import { 
    Box,
    Toolbar,
    CssBaseline,
    Divider,
    IconButton,
    Drawer as MuiDrawer,
    AppBar as MuiAppBar,
    SwipeableDrawer,
    useMediaQuery,
} from '@mui/material';

import {
    ChevronLeft as ChevronLeftIcon, 
} from '@mui/icons-material';

import { 
    StudentCenterButtonsConfig, 
    StudentBottomButtonsConfig,
    TeacherCenterButtonsConfig, 
    TeacherBottomButtonsConfig,
    NavButtonConfig
} from './Config';

import NavigationButtons from './NavigationButtons/';
import HomeButton from './HomeButton';

import { RootState } from '../../../../App/Store';
import { staticOverlaySlice } from '../../../../App/Store/StaticOverlaySlice';
import { FullOpenDrawerButton, NonFullOpenDrawerButton } from './OpenDrawerButton';

export const FullDrawerWidth = 300;

const openedMixin = (theme: Theme): CSSObject => ({
    width: FullDrawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
    full?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => (prop !== 'open' && prop !== 'full'),
})<AppBarProps>(({ theme, open, full }) => ({
    zIndex: full ? theme.zIndex.drawer + 1 : theme.zIndex.drawer - 1,
    background: theme.palette.background.default,
     ...(full && {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: FullDrawerWidth,
            width: `calc(100% - ${FullDrawerWidth}px)`,
            background: theme.palette.background.default,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
     })
}));

const FullDrawer = styled(MuiDrawer, { 
    shouldForwardProp: (prop) => prop !== 'open' })
(
    ({ theme, open }) => ({
        width: FullDrawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

type NavigationDrawerProps = {
    readonly topBarContent? : React.ReactNode
    readonly children? : React.ReactNode
}

export default function NavigationDrawer({ topBarContent, children }: NavigationDrawerProps): JSX.Element 
{
    const dispatch = useDispatch();

    const loggedIn = useSelector((state : RootState) => state.User.loggedIn);
    const userType = useSelector((state : RootState) => state.User.userType);
    
    const drawerOpen = useSelector((state : RootState) => state.StaticOverlay.drawerOpen) && loggedIn;
    
    function OpenDrawer() 
    {
        dispatch(staticOverlaySlice.Actions.setDrawerOpen(true));
    } 
    function CloseDrawer() 
    { 
        dispatch(staticOverlaySlice.Actions.setDrawerOpen(false));
    } 

    const theme = useTheme();
    const isFull = useMediaQuery(theme.breakpoints.up('sm'));

    let CenterButtonsConfig : NavButtonConfig[] = [];
    let BottomButtonsConfig : NavButtonConfig[] = [];

    if(loggedIn)
    {
        switch(userType)
        {
            case 'student':
                CenterButtonsConfig = StudentCenterButtonsConfig;
                BottomButtonsConfig = StudentBottomButtonsConfig;
                break;
            case 'teacher':
                CenterButtonsConfig = TeacherCenterButtonsConfig;
                BottomButtonsConfig = TeacherBottomButtonsConfig;
                break;
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open = { drawerOpen } full = { isFull }>
                <Toolbar>
                    { isFull ? <FullOpenDrawerButton/> : <NonFullOpenDrawerButton/> }
                    { topBarContent }
                </Toolbar>
            </AppBar>

            <Drawer 
                isFull={ isFull }
                open={ drawerOpen }
                OpenDrawer={ OpenDrawer }
                CloseDrawer={ CloseDrawer }
            >
                <DrawerHeader 
                    sx={{ padding: '1rem' }}
                >
                    <HomeButton/>

                    <Box sx={{ flexGrow: 1 }} onClick={ OpenDrawer }/>

                    <IconButton onClick={ event => { CloseDrawer(); event.stopPropagation(); } }>
                        <ChevronLeftIcon />
                    </IconButton>

                </DrawerHeader>
                
                <Box sx={{ paddingTop: '3rem' }} onClick={ OpenDrawer }/>
     
                <NavigationButtons
                    config = { CenterButtonsConfig }
                />

                <Box sx={{ flexGrow: 1 }} onClick={ OpenDrawer }/>
                <Divider onClick={ OpenDrawer }/>

                <NavigationButtons
                    config = { BottomButtonsConfig }
                />

            </Drawer>
            <Box 
                component="main" 
                sx={{ flexGrow: 1, paddingTop: 2 }}
            >   
                <DrawerHeader />
                { children }
            </Box>
        </Box>
    );
}

type DrawerProps = 
{
    isFull: boolean,
    open: boolean,
    OpenDrawer: () => void,
    CloseDrawer: () => void,
    children: React.ReactNode
};

function Drawer({ isFull, open, OpenDrawer, CloseDrawer, children } : DrawerProps) : JSX.Element
{
    const swipable = (
        <SwipeableDrawer
                open={ open }
                anchor='left'
                onOpen={ OpenDrawer }
                onClose={ CloseDrawer }
                disableSwipeToOpen={ true }
                PaperProps={{
                    sx: {
                        backgroundColor: theme => theme.palette.background.paper, 
                        backgroundImage: 'none', 
                    }
                }}                
        >
            { children }
        </SwipeableDrawer>
    );

    const full = (
        <FullDrawer
                open={ open }            
                variant="permanent" 
        >
            { children }
        </FullDrawer>
    );

    return (
        isFull ? full : swipable
    );
}