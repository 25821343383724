import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Snackbar,
    Paper,
    Box
} from '@mui/material';

import { RootState } from '../../../../App/Store';
import { Classes } from '../../../../App/API/Classes';
import { Class } from '../../../../App/Store/SimpleCreateExperiment/Types';
import InvalidateLinkButton from './InvalidateLinkButton';
import { MarginButtonStyle } from './StudentsView';

type AddStudentsButtonProps = {
    selectedClass: Class,
    buttonStyle: MarginButtonStyle
};

export default function AddStudentsButton({ selectedClass, buttonStyle }: AddStudentsButtonProps): JSX.Element {

    const [popupOpened, setPopupOpened] = React.useState(false);
    const [linkCopiedSnackbarOpened, setLinkCopiedSnackbarOpened] = React.useState(false);
    const [t] = useTranslation();
    const token = useSelector((state: RootState) => state.User.token);
    const { data: joinKey, isLoading: isJoinKeyLoading } = Classes.useGetJoinKeyQuery({ token, classId: selectedClass.id });
    const [generateJoinKeyRequest] = Classes.useGenerateJoinKeyMutation();

    function handleCopyLink(){
        setLinkCopiedSnackbarOpened(true);
        navigator.clipboard.writeText(getAccessLink());
    }

    async function generateJoinKey() {
        await generateJoinKeyRequest({
            token,
            classId: selectedClass.id,
        }).unwrap();
    }

    function getAccessLink() {
        return window.location.origin + "/join/" + joinKey;
    }

    return (
        <>
            <Button
                variant='contained'
                onClick={() => setPopupOpened(true)}
                color='info'
                sx={buttonStyle}
            >
                {t('teacher.classes_view.list.manage.add_students')}
            </Button>
            <Dialog
                open={popupOpened}
                keepMounted
                onClose={() => setPopupOpened(false)}
            >
                <DialogTitle> {t('teacher.classes_view.list.manage.add_students')} </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        color='text.primary'
                    >
                        {t('teacher.classes_view.list.manage.add_students_desc')}
                    </DialogContentText>
                    <br/>
                    {
                        !isJoinKeyLoading && !joinKey &&
                        <Button
                            variant='contained'
                            onClick={generateJoinKey}
                            color='info'
                        >
                            {t('teacher.classes_view.list.manage.generate_link')}
                        </Button>
                    }
                    {!isJoinKeyLoading && joinKey &&<InvalidateLinkButton selectedClass={selectedClass} />}
                    <br/>
                    <br />
                    {
                        !isJoinKeyLoading && joinKey &&
                        <>
                            <Paper variant="outlined">
                                <Box sx={{ m: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Box>
                                        {window.location.origin}/join/<strong>{joinKey}</strong>
                                    </Box>

                                    <Button variant='contained' color='info' onClick={handleCopyLink}>{t('teacher.classes_view.list.manage.copy')}</Button>
                                    <Snackbar
                                        open={linkCopiedSnackbarOpened}
                                        onClose={() => setLinkCopiedSnackbarOpened(false)}
                                        autoHideDuration={2000}
                                        message="Copied to clipboard"
                                    />
                                </Box>
                            </Paper>
                        </>
                    }
                    
                </DialogContent>
            </Dialog>
        </>
    );
}