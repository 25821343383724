import * as React from "react";

import { 
    Container, 
} from "@mui/material";

import CreateExperimentationStepper from "../Stepper";
import ConfigureGroupsView from "./ConfigureGroups/ConfigureGroupsView";

export default function GroupsStep() : JSX.Element 
{
    return (
        <>
            <Container maxWidth='sm'>
                <CreateExperimentationStepper />
            </Container>

            <ConfigureGroupsView/>
        </>
    );
}