import * as React from 'react';

import { 
    Box, 
    Paper,
    Typography,
} from '@mui/material';

import ErrorMessageBox from '../../../Components/Shared/ErrorMessageBox';

type ChoiceContainerProps = {
    title: string,
    children: React.ReactNode,

    displayBottom?: boolean,
    bottomContent?: React.ReactNode,

    bottomContentSelectedBoderColor?: string

    errorMessage?: string,
    isOnError?: boolean,
};

const DefaultSelectedBorderColor = 'rgb(33, 150, 116)'

export default function ChoiceContainer({ title, children, displayBottom, bottomContent, bottomContentSelectedBoderColor = DefaultSelectedBorderColor, errorMessage, isOnError } : ChoiceContainerProps) : JSX.Element
{
    return (
        <Paper
            sx ={{
                backgroundColor: bottomContentSelectedBoderColor,
                ...(displayBottom) ? ({
                    paddingTop: '0.1rem',
                    paddingX: '0.1rem',
                }) : {}
            }}
        >
            <Paper
                sx = {{
                    padding: '1rem',
                }}
            >
                <Typography 
                    variant = 'h6'
                    fontWeight = 'bold'
                    textAlign = 'center'
                >
                    { title }
                </Typography>

                <ErrorMessageBox
                    error = { isOnError || false }
                    errorMessage = { errorMessage }
                >
                    { children }
                </ErrorMessageBox>
            </Paper>

            {
                displayBottom ? (
                    <Box
                        sx = {{
                            padding: '1rem',
                            minHeight: '3.5rem'
                        }}
                    >
                        { bottomContent }
                    </Box>
                ) : null
            }
        </Paper>
    );
}