import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../../App/Store";

import {
    Button,
    Paper,
    Popper,
    Typography,
    IconButton,
    ClickAwayListener
} from '@mui/material';

import { 
    AccountCircle as AccountCircleIcon
} from '@mui/icons-material'

import LoaderCircularProgress from "../../Shared/LoaderCircularProgress";

import { UserType, userSlice } from "../../../../App/Store/User/UserSlice";
import { Teacher } from "../../../../App/API/Teacher";
import { Student } from "../../../../App/API/Student";
import {
    useShibbolethSSOLogoutMutation
} from '../../../../App/FontAPI';

export default function ProfileMenu()
{
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = !!anchorEl;
    const id = open ? 'profile-menu-popper' : undefined;

    function Close()
    {
        setAnchorEl(null);
    }

    function OnClickOpen(event: React.MouseEvent<HTMLElement>) 
    {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }

    return (
        <>
            <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"

                aria-describedby = { id }
                onClick = { OnClickOpen }
            >
                <AccountCircleIcon />
            </IconButton>
            <ProfileMenuPopper 
                open = { open }
                id = { id }
                anchorEl = { anchorEl }
            >
                <ProfileMenuPopperContent 
                    closePopper = { Close }
                />
            </ProfileMenuPopper>
        </>
    );
}

type ProfileMenuPopperContentProps = {
    closePopper: () => void
};

function ProfileMenuPopperContent({ closePopper } : ProfileMenuPopperContentProps) : JSX.Element
{
    const token = useSelector((state: RootState) => state.User.token);
    const userType = useSelector((state: RootState) => state.User.userType);
    const loggedIn = useSelector((state: RootState) => state.User.loggedIn);

    // To mock the query when not connected
    let getNameQuery : typeof Teacher.useTeacherNameQuery = ((arg : { token: string }) => ({ data: null, isLoading: false, isSuccess: false })) as typeof Teacher.useTeacherNameQuery;
    
    if(userType === 'teacher')
        getNameQuery = Teacher.useTeacherNameQuery;
    else if(userType === 'student')
        getNameQuery = Student.useStudentNameQuery;

    const { data: userNames, isLoading, isSuccess } = getNameQuery({ token: token });

    return (
        <ClickAwayListener
            onClickAway={ closePopper }
        >
            <Paper
                sx = {{
                    padding: '1rem',
                    margin: '0.5rem'
                }}
            >
                {
                    loggedIn &&
                    <ConnectedProfileMenuPopperContent 
                        key = "ConnectedProfileMenuPopperContent"
                        isLoading = { isLoading }
                        isSuccess = { isSuccess }
                        userType = { userType }
                        userNames = { userNames }
                        closePopper = { closePopper }
                    />
                }
                {
                    !loggedIn &&
                    <DisconnectedProfileMenuPopperContent key="ConnectedProfileMenuPopperContent" closePopper={ closePopper } />
                }
            </Paper>
        </ClickAwayListener>
    );
}

type ConnectedProfileMenuPopperContentProps = 
{
    isLoading: boolean,
    isSuccess: boolean,
    userType: UserType,
    userNames: Teacher.NameResponse | Student.NameResponse | null | undefined,

    closePopper: () => void
};

function ConnectedProfileMenuPopperContent({ isLoading, userType, isSuccess, userNames, closePopper } : ConnectedProfileMenuPopperContentProps) : JSX.Element
{
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [shibbolethSSOLogout] = useShibbolethSSOLogoutMutation();
    const navigate = useNavigate();

    async function Disconnect()
    {
        try {
            await shibbolethSSOLogout().unwrap();
        }
        catch (err) {
            console.log('While shibbolethSSOLogout', err);
        }

        closePopper();

        dispatch(userSlice.Actions.setLogin({
            loggedIn : false,
            type: null,
            token: ''
        }));

        navigate('/');
    }

    return (
        <>
            <LoaderCircularProgress display = { isLoading } />
            {
                isSuccess &&
                <>
                    <Typography variant='h6' color='text.secondary'>
                        { userNames?.givenName } { userNames?.lastName }
                    </Typography>
                    <Typography variant='body1'>
                        { t(`user.type.${userType}`) }
                    </Typography>
                </>
            }
            <Button
                onClick = { Disconnect }
                variant = 'contained'
                sx = {{
                    marginTop: '1rem'
                }}
            >
                { t('user.profile.menu.disconnect') }
            </Button>
        </>
    );
}

type DisconnectedProfileMenuPopperContentProps = {
    closePopper: () => void
};

function DisconnectedProfileMenuPopperContent({ closePopper } : DisconnectedProfileMenuPopperContentProps) : JSX.Element
{
    const { t } = useTranslation();
    const navigate = useNavigate();

    function NavToAuth()
    {
        closePopper();
        navigate('/auth')
    }

    return (
        <>
            <Typography variant='body1'>
                { t('user.profile.menu.disconnect_message') }
            </Typography>                    
            <Button
                onClick = { NavToAuth }
                variant = 'contained'
                sx = {{
                    marginTop: '1rem'
                }}
            >
                { t('user.profile.menu.auth_page') }
            </Button>
        </>
    );
}

type ProfileMenuPopperProps = {
    open: boolean,
    id: string | undefined,
    anchorEl: HTMLElement | null,
    children?: React.ReactNode
};

function ProfileMenuPopper({ open, id, anchorEl, children } : ProfileMenuPopperProps) : JSX.Element
{
    return (
        <Popper
            id = { id }
            open = { open }
            anchorEl = { anchorEl }
            placement = "bottom-end"
            disablePortal = { true }
            modifiers = {
            [
                {
                    name: 'flip',
                    enabled: true,
                    options: {
                        altBoundary: true,
                        rootBoundary: 'document',
                        padding: 8,
                    },
                },
                {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                        altAxis: true,
                        altBoundary: true,
                        tether: true,
                        rootBoundary: 'document',
                        padding: 8,
                    },
                },
                {
                    name: 'arrow',
                    enabled: true,
                    options: {
                        element: anchorEl,
                    },
                },
            ]}
        >
            { children }
        </Popper>
    );
}