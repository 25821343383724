import * as React from 'react'

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../App/Store';

import Logger from '../../../../../App/Framework/Utils/Logger';
import { Classes } from '../../../../../App/API/Classes';
import { Class } from '../../../../../App/Store/SimpleCreateExperiment/Types';

import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';

type RemoveClassPopupProps = {
    popupOpened: boolean,
    setPopupOpened: React.Dispatch<React.SetStateAction<boolean>>,
    selectedClass: Class
}

export default function TeacherRemoveClassPopup({ popupOpened, setPopupOpened, selectedClass }: RemoveClassPopupProps): JSX.Element {
    const { t } = useTranslation();
    const token = useSelector((state: RootState) => state.User.token);
    const [removeClassRequest] = Classes.useRemoveClassMutation();

    async function RemoveClass() {
        try {
            const className = selectedClass.name;
            await removeClassRequest({
                token,
                id: selectedClass.id,
            }).unwrap();
            Logger.info(t('teacher.classes_view.remove_class.success') + className);
        }
        catch (err) {
            Logger.error("When removing class : ", err);
        }
    }

    return (
        <Dialog
            open={popupOpened}
            keepMounted
            onClose={() => setPopupOpened(false)}
        >
            <DialogTitle> {t('teacher.classes_view.remove_class.title')} </DialogTitle>
            <DialogContent>
                <DialogContentText
                    color='text.primary'
                >
                    {t('teacher.classes_view.remove_class.desc')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={
                        () => {
                            setPopupOpened(false);
                            RemoveClass();
                        }
                    }
                    variant='contained'
                    color='error'
                >
                    {t('teacher.classes_view.remove_class.button_validate')}
                </Button>
                <Button
                    onClick={() => setPopupOpened(false)}
                    variant='contained'
                    color='info'
                >
                    {t('teacher.classes_view.remove_class.button_cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}