import * as React from "react";

import { 
    Box, 
    List, 
    SxProps, 
    Theme 
} from "@mui/material";

import {  
    ScenarioId
} from "../../../../../App/Store/CreateExpriment/Types";

import StudentListItem from "../GroupsAndStudents/Students/StudentListItem";
import { CreateExperimentSlice, RootState } from "../../../../../App/Store";
import { useSelector } from "react-redux";
import { StudentsGroupsListItem } from "../GroupsAndStudents/Groups/StudentsGroupsList";
import Logger from "../../../../../App/Framework/Utils/Logger";

type ScenarioBoxContentProps = 
{
    scenarioId : ScenarioId,
    showDroppableBorder : boolean
};

export default function ActiveScenarioBoxContent({ scenarioId, showDroppableBorder } : ScenarioBoxContentProps) : JSX.Element
{
    const HoverSx : SxProps<Theme> = (showDroppableBorder) ? {
        border: 'solid 2px rgba(50, 185, 62, 0.7)',
        borderRadius: '.3rem',
        outline: 'solid 2px rgba(0, 128, 2, 0.7)',
        backgroundColor: 'rgba(50, 205, 50, 0.1)'
    } : {
        border: 'solid 2px transparent',
        outline: 'solid 2px transparent'
    };

    return (
        <Box
            sx = {{
                marginY: '.5rem',
                marginX: '.2rem',
                ...HoverSx
            }}
        >
            <List
                sx={{
                    minHeight: '10rem',
                }}
                disablePadding
            >
                <StudentsAndGroups 
                    scenarioId = { scenarioId }
                />
            </List>
        </Box>
    );
}

type StudentsAndGroupsProps = 
{
    scenarioId: ScenarioId
};

function StudentsAndGroups({ scenarioId } : StudentsAndGroupsProps) : JSX.Element
{
    const { groupsInScenario, studentsInScenario } = useSelector((rootState: RootState) => rootState.CreateExperiment.scenarioStep.activeScenarios[scenarioId]);
    const CreateExperimentState = useSelector((rootState: RootState) => rootState.CreateExperiment);

    const groups : JSX.Element[] = [];
    const students : JSX.Element[] = [];

    // Note : this is for potiential future visual distinction between groups and students
    // const haveGroups = studentsInScenario.length > 0;
    // const haveStudents = studentsInScenario.length > 0;

    for(const groupName of groupsInScenario)
    {
        const group = CreateExperimentSlice.Selectors(CreateExperimentState)
            .getGroupByName(groupName);

        if(!group)
        {
            Logger.error(`Group ${groupName} not found in scenario ${scenarioId}`);
            continue;
        }

        groups.push(
            <StudentsGroupsListItem 
                key = { groupName }
                group = { group }
                sx = {{
                    marginBottom: '0.5rem'
                }}
            />
        );
    }

    for(const studentId of studentsInScenario)
    {
        students.push(
            <StudentListItem 
                key = { studentId }
                studentId = { studentId }
            />
        );
    }

    return <> { groups } { students } </>
}