import * as React from 'react';

import { useTranslation } from 'react-i18next';

import { 
    Box,
    Button,
    IconButton,
    Grid, Paper, Typography 
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import { Class } from '../../../App/Store/SimpleCreateExperiment/Types';
import { useNavigate } from 'react-router-dom';

type ClassGridItemProps = {
    aClass: Class,
    displayRemoveButton: Boolean,
    onRemoveClassButton: (selectedClass : Class) => void
};

export default function ClassGridItem({ aClass, displayRemoveButton, onRemoveClassButton }: ClassGridItemProps): JSX.Element {
    const [t] = useTranslation();
    const navigate = useNavigate();

    function OnClickDetails() {
        navigate(`/teacher/class/${aClass.id}`);
    }

    return (
        <Grid item xs={6} md={3} xl={2}>
            <Paper
                sx = {{
                    padding: '1rem',
                    userSelect: 'none'
                }}
            >
                <Grid container>
                    <Grid item xs={10} >
                        <Typography variant='h6' component='h4'> {aClass.name} </Typography>
                    </Grid>
                    <Grid item xs={1} >
                        {
                            displayRemoveButton &&
                            <IconButton aria-label="delete" onClick={() => onRemoveClassButton(aClass)}>
                                <DeleteIcon />
                            </IconButton>
                        }
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                
                <Typography variant='body2' color='text.secondary'> {aClass.SchoolName} </Typography>
                
                
                <Box
                    sx = {{
                        marginY: '1rem'
                    }}
                >
                    {  }
                </Box>
                
                <Button variant='contained' size='small' fullWidth
                    onClick = { OnClickDetails }
                >
                    { t('teacher.classes_view.item.details_button') } 
                </Button>
            </Paper>
        </Grid>
    );
}