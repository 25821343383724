import { Student } from "./Types";

export type ClassConfig = {
    id: number,
    name: string
};

export const dummyClasses : ClassConfig[] = 
[
    {
        id: 0,
        name: "Class 1",
    },
    {
        id: 1,
        name: "Class 2"
    },
    {
        id: 2,
        name: "Class 3",
    },
    {
        id: 3,
        name: "Class 4"
    }
];

export const dummyClass : { [key: number]: Student } = {
    1: {
        id: 1,
        firstName : 'Gaspard',
        lastName : 'GIL'
    },
    2: {
        id: 2,
        firstName : 'Billie',
        lastName : 'KERR'
    },
    3: {
        id: 3,
        firstName : 'April',
        lastName : 'HART'
    },
    4: {
        id: 4,
        firstName : 'Sadman',
        lastName : 'ABIR'
    },
    5: {
        id: 5,
        firstName : 'Jeff',
        lastName : 'PESSOS'
    },
    6: {
        id: 6,
        firstName : 'LeBron',
        lastName : 'GAMES'
    },
    7: {
        id: 7,
        firstName : 'Ava',
        lastName : 'JAMES'
    },
    8: {
        id: 8,
        firstName : 'Sabrina',
        lastName : 'JACKSON'
    },
    9: {
        id: 9,
        firstName : 'Thomas',
        lastName : 'SANDERS'
    },
    10: {
        id: 10,
        firstName : 'Antonia',
        lastName : 'DA SILVA'
    },
    11: {
        id: 11,
        firstName : 'Gloria',
        lastName : 'SUMMERS'
    },
    12: {
        id: 12,
        firstName : 'Jenna',
        lastName : 'GAY'
    },
    13: {
        id: 13,
        firstName : 'Freddy',
        lastName : 'KRUGER'
    },
    14: {
        id: 14,
        firstName : 'Julian',
        lastName : 'WISE'
    },
    15: {
        id: 15,
        firstName : 'Emily',
        lastName : 'RIPERT'
    },
    16: {
        id: 16,
        firstName : 'Claire',
        lastName : 'REDFIELD'
    },
    17: {
        id: 17,
        firstName : 'Rania',
        lastName : 'CHABEB'
    },
    18: {
        id: 18,
        firstName : 'Haley',
        lastName : 'VALDEZ'
    },
    19: {
        id: 19,
        firstName : 'Martin',
        lastName : 'MATIN'
    },
    20: {
        id: 20,
        firstName : 'Julie',
        lastName : 'HOBBS'
    },
    21: {
        id: 21,
        firstName : 'Lucie',
        lastName : 'DUPONT'
    },
    22: {
        id: 22,
        firstName : 'Jamie',
        lastName : 'KING'
    }
};