import { UnityContextHook } from 'react-unity-webgl/distribution/types/unity-context-hook';

import { 
    CheckAppTokenUrl, 
    ProgressUpdateUrl, 
    SendResulDatatUrl, 
    SendResultDataNoProgressUrl 
} from './Config';

import Logger from '../Framework/Utils/Logger';

import { UserType } from '../Store/User/UserSlice';
import { ReactUnityEventParameter } from 'react-unity-webgl/distribution/types/react-unity-event-parameters';
import { UnityProvider } from 'react-unity-webgl/distribution/types/unity-provider';

type UnityAppHandlerConfig = 
{
    language: string,

    authToken: string,
    userType: UserType,

    exprimentId?: number,
    scenarioRef?: string,

    downloadOnEnd?: boolean,
    skipPreparationSteps?: boolean
    trackProgress?: boolean
};

type UnityAppParameters = 
{
    [parameterName: string] : {
        invokeMethodName : string,
        data: ReactUnityEventParameter | boolean | null
    }
}

export default class UnityAppHandler 
{
    private static readonly AppParametersGoName     = 'AppParameters';
    private static readonly GameManagerGoName       = 'GameManager';
    
    private contextHook : UnityContextHook;
    private config : UnityAppParameters;

    public constructor(contextHook: UnityContextHook, config : UnityAppHandlerConfig)
    {
        this.contextHook = contextHook;
        this.config = this.BuildAppParams(config);
    }

    get IsLoading() : boolean           { return !this.contextHook.isLoaded; }
    get LoadingProgression() : number   { return this.contextHook.loadingProgression; }
    get Provider() : UnityProvider      { return this.contextHook.unityProvider; }

    private BuildAppParams(config : UnityAppHandlerConfig) : UnityAppParameters
    {
        const appParams : UnityAppParameters = 
        {
            checkTokenUrl: {
                invokeMethodName: 'SetCheckTokenUrl',
                data: CheckAppTokenUrl
            },
            progressUpdateUrl: {
                invokeMethodName: 'SetProgressUpdateUrl',
                data: ProgressUpdateUrl
            },
            sendDataResultUrl: {
                invokeMethodName: 'SetSendDataResultUrl',
                data: config.trackProgress ? SendResulDatatUrl : SendResultDataNoProgressUrl
            },
            authToken: {
                invokeMethodName: 'SetAuthToken',
                data: config.authToken
            },
            userStatus: {
                invokeMethodName: 'SetUserStatus',
                data: config.userType
            },
            experimentId: {
                invokeMethodName: 'SetExperimentId',
                data: config.exprimentId
            },
            trackProgress: {
                invokeMethodName: 'SetTrackProgress',
                data: config.trackProgress
            },
            downloadOnEnd: {
                invokeMethodName: 'SetDownloadOnEnd',
                data: config.downloadOnEnd
            },
            skipPreparationSteps: {
                invokeMethodName: 'SetSkipPreparationSteps',
                data: config.skipPreparationSteps
            },
            languageCode: {
                invokeMethodName: 'SetLanguageCode',
                data: config.language
            }
        };

        if (config.scenarioRef != null && config.scenarioRef != "")
        {
            
            appParams.scenario = {
                invokeMethodName: 'SetScenarioCode',
                data: config.scenarioRef
            };
        }

        return appParams;
    }

    public SendConfigToWebApp() : void
    {
        if(this.IsLoading)
        {
            Logger.error('Trying to send configs to a not loaded UnityApplication');
            return;
        }

        for(const value of Object.values(this.config))
        {
            if(value.data === null || value.data === undefined) continue;
            
            this.SendToAppParams(value.invokeMethodName, value.data);
        }
    }

    public BuildDeepLinkUrl() : URLSearchParams
    {
        const urlSearchParams = new URLSearchParams();

        for(const [key, value] of Object.entries(this.config))
        {
            if(value.data === null || value.data === undefined) continue;

            urlSearchParams.append(key, value.data.toString());
        }

        urlSearchParams.toString();

        return urlSearchParams;
    }

    public AddEventListener(eventName: string, callback: (...parameters: ReactUnityEventParameter[]) => ReactUnityEventParameter)
    {
        this.contextHook.addEventListener(eventName, callback);
    }

    public RemoveEventListener(eventName: string, callback: (...parameters: ReactUnityEventParameter[]) => ReactUnityEventParameter)
    {
        this.contextHook.removeEventListener(eventName, callback);
    }

    public LoadLab() : void
    {
        this.InvokeInGameObject(UnityAppHandler.GameManagerGoName, 'LoadLab');
    }

    public RequestFullscreen() : void
    {
        this.contextHook.requestFullscreen(true);
    }

    public async Unload() : Promise<void>
    {
        //if(this.IsLoading) return;

        await this.contextHook.unload();
    }

    /** Call a method with a unique parameter in the AppParameters GameObject
     */
    private SendToAppParams(methodName: string, param: boolean | string | number)
    {
        if(typeof param === 'boolean')
        {
            const boolString = Boolean(param).toString();
            this.contextHook.sendMessage(UnityAppHandler.AppParametersGoName, methodName, boolString);
            return;
        }
        
        this.contextHook.sendMessage(UnityAppHandler.AppParametersGoName, methodName, param.toString());
    }

    /** Invoke a method with no parameter in a GameObject
     */
    private InvokeInGameObject(gameObjectName: string, methodName: string)
    {
        this.contextHook.sendMessage(gameObjectName, methodName);
    }
}