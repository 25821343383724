import * as React from 'react';

import { 
    Button, 
    Container, 
    Divider, 
    Grid, 
    Paper, 
    Stack, 
    Typography 
} from '@mui/material';

import {
    Shuffle as ShuffleIcon
} from '@mui/icons-material'

import { useNavigate } from 'react-router-dom';

import GroupContainer from './GroupContainer';
import StudentsList from './SudentsList';
import { createExperimentSlice } from '../../../../../App/Store/CreateExpriment';
import { useDispatch, useSelector } from 'react-redux';
import AddGroupBox from '../../../../Components/Shared/AddBox';
import { RootState } from '../../../../../App/Store';

const className = "Terminale B3";

export default function ConfigureGroupsView() : JSX.Element
{
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function OnValidate()
    {
        dispatch(createExperimentSlice.Actions.setStepFinished('groupsStep'));
        dispatch(createExperimentSlice.Actions.setStepActive('scenarioStep'));
        dispatch(createExperimentSlice.Actions.setStepReachable('scenarioStep'));

        navigate('/experimentations/create-advanced/create-scenerios');
    }

    function OnShuffleGroups()
    {
        dispatch(createExperimentSlice.Actions.putAllStudentsInRandomGroups(undefined));
    }

    return (
        <Container maxWidth="xl">
            
            <Typography variant="h5" component="h2" gutterBottom
                sx={{
                    marginTop: '1rem'
                }}
            >
                CONFIGUREZ VOS GROUPES
            </Typography>
            
            <Typography variant="body2" gutterBottom
                sx={{
                    marginTop: '2rem'
                }}
            >
                Sélectionnez le nom des élèves dans la liste puis cliquez-glissez les dans les groupe(s)
            </Typography>            

            <Grid
                container
                spacing = { 1 }
                sx={{
                    my: '1.5rem'
                }}
            >
                <Grid item xs={ 2.3 }>
                    
                    <StudentsList 
                        className = { className }
                    />

                </Grid>
    
                <Grid item>
                    <Divider orientation="vertical"/>
                </Grid>

                <Grid item xs>
                    <Paper
                        sx={{ 
                            height: '100%',
                            padding: '.5rem' 
                        }}
                    >
                        { /* Header */ }
                        <Stack
                            direction = 'row'
                            alignItems = 'center'
                            justifyContent = 'space-between'
                            sx = {{
                                marginBottom: '1rem'
                            }}
                        >
                            <Typography variant='h6' sx={{ margin: '.5rem' }}> Groupe(s) </Typography>
                            
                            <Button
                                variant = 'contained'
                                endIcon = { <ShuffleIcon /> }
                                onClick = { OnShuffleGroups }
                            >
                                { "Aléatoire" /* t('') */ }
                            </Button>
                        </Stack>

                        <Divider />

                        <Grid
                            container
                            direction = 'row'
                            columns={3}
                        >
                            <GroupsGrid />
                        </Grid>

                    </Paper>
                </Grid>

            </Grid>

            <Stack 
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={ 4 }
                sx={{
                    my: "2rem"
                }}
            >
                <Button 
                    onClick = { OnValidate }
                    variant = "contained"
                >
                    { "Valider les groupes" }
                </Button>
            </Stack>

        </Container>
    )
}

function GroupsGrid() : JSX.Element
{
    const dispatch = useDispatch();

    const groups = useSelector((state: RootState) => state.CreateExperiment.groupsStep.studentGroups);
    
    function AddGroup()
    {
        dispatch(createExperimentSlice.Actions.addGroup(undefined));
    }

    return (
        <>
            { 
                Object.values(groups).map(group => (
                    <GroupContainer 
                        key = { group.name } 
                        groupName = { group.name } 
                    />
                ))
            }
            <AddGroupBox 
                OnClick={ AddGroup }
            />
        </>
    )
}