import * as React from 'react';

import { useTranslation } from 'react-i18next';

import {
    Card,
    Paper,
    Typography,
    CircularProgress,
    lighten,
    useMediaQuery,
    useTheme
} from '@mui/material';

import { 
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineDot,
    TimelineConnector,
    TimelineContent,
    TimelineOppositeContent
} from '@mui/lab';

import { 
    Check as CheckIcon
} from '@mui/icons-material';

import { StudentExperimentProgress, TeacherExperimentProgress } from '../../../../App/API/Experiments';
import { Scenario, ScenarioStep } from '../../../../App/Store/SimpleCreateExperiment/Types';

type ExperimentStudentDetailsTimeLineProps = 
{
    experimentProgress: {
        scenario: Scenario;
        steps: ScenarioStep[];
        progress: StudentExperimentProgress | TeacherExperimentProgress;
    }
}

export default function ExperimentStudentDetailsTimeLine({ experimentProgress } : ExperimentStudentDetailsTimeLineProps)
{
    const { t } = useTranslation();

    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.up('md'));

    if(experimentProgress.steps?.length < 1
        || !experimentProgress.progress
    )
    {
        return (
            <Typography variant='body1' color='text.secondary' mt = '1.5rem'>
                { t('experimentation_view.student_details.no_progress') }
            </Typography>
        );
    }
    
    const experimentProgressElements : JSX.Element[] = [];

    for(let i = 0; i < experimentProgress.steps?.length; ++i)
    {
        const step = experimentProgress.steps[i];

        const isCurrentStep = i === experimentProgress.progress?.currentStep;

        const content = (i < experimentProgress.progress?.currentStep)
            ? <FinishedStepTimeLineElement index = { i } step = { step } isCurrentStep = { isCurrentStep }/>
            : <UnfinishedStepTimeLineElement index = { i } step = { step } isCurrentStep = { isCurrentStep }/>;

        const stepElement = (
            <TimelineItem
                key = { step.name + i }
            >
                { !isLarge && <TimelineOppositeContent display='none' /> }
                { content }
            </TimelineItem>
        );

        experimentProgressElements.push(stepElement);
    }

    return (
        <Timeline position= { isLarge ? 'alternate-reverse' : 'right' }>
            { experimentProgressElements }
        </Timeline>
    );
}

/** 
 * The tow following components can be factorized in a single one
 * 
 */

type StepTimeLineElementProps =
{
    index: number,
    step: ScenarioStep,
    isCurrentStep?: boolean
}

function FinishedStepTimeLineElement({ index, step } : StepTimeLineElementProps) : JSX.Element
{
    const { t } = useTranslation();

    return (
        <>
            <TimelineSeparator>
                <TimelineConnector sx={{ bgcolor: 'secondary.main' }}/>
                <TimelineDot color='success'>
                    <CheckIcon fontSize='small' htmlColor='white'/>
                </TimelineDot>
                <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Card
                    square
                >
                    <Paper
                        square
                        sx = {{
                            bgcolor: theme => lighten(theme.palette.background.paper, 0.1),
                            paddingX: '0.6rem',
                            paddingY: '0.2rem',
                        }}
                    >
                        <Typography variant="h6" component="span">
                            { `${t('experimentation_view.student_details.step_number')}${ index + 1 }` } - { t(`scenario_steps.${step.name}`) }
                        </Typography>
                    </Paper>
                    {/* <CardContent>
                        <Typography>{  }</Typography>
                    </CardContent> */}
                </Card>
            </TimelineContent>
        </>
    );
}

function UnfinishedStepTimeLineElement({ index, step, isCurrentStep } : StepTimeLineElementProps) : JSX.Element
{
    const { t } = useTranslation();

    return (
        <>
            <TimelineSeparator>
                <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                <TimelineDot color = 'primary'>
                { 
                    isCurrentStep &&
                    <CircularProgress size={ 30 } sx = {{ color: 'white' }}/>
                }
                </TimelineDot>
                <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Card
                    square
                >
                    <Paper
                        square
                        sx = {{
                            bgcolor: theme => lighten(theme.palette.background.paper, 0.1),
                            paddingX: '0.6rem',
                            paddingY: '0.2rem',
                        }}
                    >
                        <Typography variant="h6" component="span">
                            { `Etape N°${ index + 1 }` } - { t(`scenario_steps.${step.name}`) }
                        </Typography>
                    </Paper>
                    {
                        /*
                        // Note : Implement steps detail as errors, informations, etc... here
                        <CardContent>
                            <Typography>{  }</Typography>
                        </CardContent> 
                        */
                    }
                </Card>
            </TimelineContent>
        </>
    );
}