import { Stack } from '@mui/material';

import { LanguageSelectorView } from '../../Settings';

import ProfileMenu from './ProfileMenu';

export default function TopBar()
{
    return (
        <Stack
            direction='row'
            alignContent='center'
            justifyContent='flex-end'
            width='100%'
            spacing={ 1 }
        >
            <LanguageSelectorView />
            <ProfileMenu />
        </Stack>
    )
}