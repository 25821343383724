import * as React from 'react';

import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function LinearProgressWithLabel(props: LinearProgressProps & { value: number, percentageTextColor?: TypographyProps['color']}) 
{
    const value = props.value;
    const percentageTextColor = props.percentageTextColor;

    const linearProgressProps = { ...props }
    delete linearProgressProps.percentageTextColor;

    return (
        <Box 
            sx = {{ 
                display: 'flex', 
                alignItems: 'center' 
            }}
        >
            <Box 
                sx = {{ 
                    width: '100%', 
                    mr: 1 
                }}
            >
                <LinearProgress variant="determinate" { ...linearProgressProps } />
            </Box>
            
            <Box sx = {{ 
                minWidth: 35 
                }}
            >
                <Typography 
                    variant = "body2"
                    color = { percentageTextColor ? percentageTextColor : "text.secondary" }
                >
                    { `${Math.round(value)}%` }
                </Typography>
            </Box>
        </Box>
    );
}
