import { 
    CaseReducerActions,
    createSlice, 
    Slice, 
    SliceCaseReducers 
} from "@reduxjs/toolkit";
import { RootState, Store } from "../../Store";

import IdentifiableClass from "../ClassIdentity/IdentifiableClass";

type AppSliceConfig<TState> = { 
    name : string, 
    initialState: TState
}

/**
 * A slice of the redux store.
 * When creating a new slice, extend this class and implement the abstract getConfig withe his name on the store and his initialState.
 * You need to add the stlice actions of your new slice to the reducer in the Store/index.ts file.
 * 
 * You can right your reducers in the getReducers method.
 */
export default abstract class AppSlice<TState> extends IdentifiableClass 
{
    public abstract getReducers() : SliceCaseReducers<TState>;
    protected abstract getConfig() : AppSliceConfig<TState>;

    public readonly Slice: Slice<TState, SliceCaseReducers<TState>, string> = createSlice<TState, SliceCaseReducers<TState>>(
    {
        name: this.getConfig().name,
        initialState : this.getConfig().initialState,
        reducers: this.getReducers()
    });
    
    public get State() : TState
    {
        const rootState = Store.getState() as RootState & { [key: string]: TState };
        return rootState[this.Slice.name];
    }

    public get Actions() : CaseReducerActions<SliceCaseReducers<TState>, string>
    {
        return this.Slice.actions;
    }
}