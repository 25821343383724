import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './App/Store';
import { useTranslation } from 'react-i18next';

import { 
    Box 
} from '@mui/material';

import TopBar from './Features/Components/StaticOverlay/TopBar/TopBarView';
import NavigationDrawer from './Features/Components/StaticOverlay/NavigationPanel/NavigationPanelView';
import Footer from './Features/Components/Footer';
import QuerryErrorSnackbarAlert from './Features/Components/Shared/QuerryErrorAlert';
import LoaderCircularProgress from './Features/Components/Shared/LoaderCircularProgress';

import { 
    AppRoutes, 
    NotLoggedInRoute,
    TeacherRoutes,
    StudentRoutes
} from './AppRoutes';


import { Users } from './App/API/Users';
import { userSlice } from './App/Store/User/UserSlice';
import Logger from './App/Framework/Utils/Logger';
import GenericErrorPage from './Features/Pages/Errors/GenericErrorPage';

export default function App() : JSX.Element 
{
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const loggedIn = useSelector((rootState : RootState) => rootState.User.loggedIn);
    const userType = useSelector((rootState : RootState) => rootState.User.userType);

    const {
        data: checkTokenData,
        isLoading: checkTokenIsLoading,
        isSuccess: checkTokenIsSuccess,
        error: checkTokenError,
    } = Users.useCheckTokenQuery();

    React.useEffect(() => 
    {
        function ResetLoginState()
        {
            Logger.info('Resetting local login state');

            // eslint-disable-next-line react-hooks/exhaustive-deps
            dispatch(userSlice.Actions.setLogin({
                loggedIn : false,
                type: null,
                token: ''
            }));
        }

        if(checkTokenIsSuccess && checkTokenData)
        {
            if(!checkTokenData.valid || checkTokenData.userType !== userType)
            {
                ResetLoginState();
            }
        }
        else if(checkTokenError)
        {
            ResetLoginState();
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ checkTokenIsSuccess, checkTokenError ])

    let routes = NotLoggedInRoute;

    if(loggedIn)
    {
        switch (userType)
        {
            case 'teacher': routes = TeacherRoutes; break;
            case 'student': routes = StudentRoutes; break;
        }
    }

    return (
        <NavigationDrawer
            topBarContent = { <TopBar /> }
        >
            <Box
                sx = {{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '90vh'
                }}
            >
                <Box sx={{ flex: 'auto' }}>
                    <LoaderCircularProgress display = { checkTokenIsLoading } />
                    <QuerryErrorSnackbarAlert error = { checkTokenError } autohideDuration = { null }/>
                    {
                        checkTokenIsSuccess &&
                        <AppRoutes 
                            routesConfigs = { routes } 
                        />
                    }
                    {
                        checkTokenError && 
                        <GenericErrorPage
                            title = { t('error_pages.no_host.title') }
                            message = { t('error_pages.no_host.message') as string }
                        />
                    }
                </Box>

                <Box sx={{ bottom: '3vh' }}>
                    <Footer/>
                </Box>
            </Box>
        </NavigationDrawer>
    );
}