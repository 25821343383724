import * as React from 'react'; 

import { DropTargetMonitor, useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';

import { 
    Box,
    Collapse, 
    FormControlLabel, 
    Grid, 
    IconButton, 
    Paper, 
    Tooltip, 
    Typography 
} from '@mui/material';

import { 
    ExpandLess as ExpandLessIcon, 
    ExpandMore  as ExpandMoreIcon,
    Groups as GroupsIcon,
    Person as PersonIcon,
    SvgIconComponent,
} from '@mui/icons-material';

import { ScenarioGroup, Student, StudentGroup } from '../../../../../App/Store/CreateExpriment/Types';
import ActiveScenarioBoxContent from './ActiveScenarioBoxContent';
import { DragableItemTypes } from '../../Constants';
import { createExperimentSlice } from '../../../../../App/Store/CreateExpriment';

type ActiveScenarioBoxProps = {
    scenarioGroup : ScenarioGroup
};

export default  function ActiveScenarioBox({ scenarioGroup } : ActiveScenarioBoxProps) : JSX.Element
{
    const dispatch = useDispatch();

    const { scenarioApplicationRef, scenarioId, groupsInScenario, studentsInScenario, scenarioCategory } = scenarioGroup;

    const [ { isOver, canDrop }, dropRef ] = useDrop(
        () => ({
            accept: [ DragableItemTypes.StudentCard, DragableItemTypes.StudentGroupCard ],
            drop: OnDrop,
            // TODO : Need to be replaced by validator when it will be fixed
            canDrop: () => true,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            }),
        }),
    );

    function OnDrop(droppedItem : unknown, monitor : DropTargetMonitor<unknown, void>)
    {
        switch (monitor.getItemType())
        {
            case DragableItemTypes.StudentCard: 
                OnDropStudent(droppedItem as Student);
                break;
            case DragableItemTypes.StudentGroupCard: 
                OnDropGroup(droppedItem as StudentGroup);
                break;
        }
    }

    function OnDropStudent(student : Student)
    {
        dispatch(createExperimentSlice.Actions.addStudentToScenario({
            scenarioId: scenarioGroup.scenarioId,
            studentId: student.id
        }));
    }

    function OnDropGroup(group : StudentGroup)
    {
        dispatch(createExperimentSlice.Actions.addGroupToScenario({
            scenarioId: scenarioGroup.scenarioId,
            groupName: group.name
        }));
    }

    function OnClickOpen()
    {
        dispatch(createExperimentSlice.Actions.setScenarioDrawerOpen({
            scenarioId: scenarioGroup.scenarioId,
            open: !scenarioGroup.drawerOpen
        }));
    }

    return (
        <Grid item xs = { 1 }>
            <Paper
                ref = { dropRef }
                elevation = { 3 }
                sx = {{
                    margin: '.5rem',
                }}
            >
                <Paper 
                    sx = {{
                        cursor: 'pointer',
                        padding: '0.2rem',
                        backgroundColor: scenarioGroup.scenarioColorCode,
                    }}
                    onClick = { OnClickOpen }
                >
                    <Box
                        sx = {{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                        }}
                    >
                        <Typography variant='body2' 
                            sx = {{ 
                                userSelect: 'none',
                                alignSelf: 'flex-start',
                                marginTop: '.25rem', 
                                marginLeft: '.25rem',
                                fontWeight: 'bold',
                            }}
                        >
                            { scenarioCategory } 
                        </Typography>

                        <Typography variant='body2' 
                            sx = {{ 
                                userSelect: 'none',
                                marginY: '.5rem',
                            }}>
                            { scenarioApplicationRef } 
                        </Typography>
                        
                        <IconButton 
                            aria-label="open" 
                            onClick={ (e) => { OnClickOpen(); e.stopPropagation(); } }
                            sx = {{
                                padding: 0,
                            }}
                        >
                            { !scenarioGroup.drawerOpen ? <ExpandMoreIcon /> : <ExpandLessIcon /> }
                        </IconButton>
                    </Box>
                    
                    <ScenarioIcons 
                        groupsInScenarioLength = { groupsInScenario.length }
                        studentsInScenarioLength = { studentsInScenario.length }
                    />
                </Paper>
                
                <Collapse in = { scenarioGroup.drawerOpen }>
                    <ActiveScenarioBoxContent 
                        scenarioId = { scenarioId }
                        showDroppableBorder = { isOver && canDrop }
                    />
                </Collapse>

            </Paper>
        </Grid>
    );
}

type ScenarioIconsProps = {
    groupsInScenarioLength : number,
    studentsInScenarioLength : number,
};

function ScenarioIcons({ groupsInScenarioLength, studentsInScenarioLength } : ScenarioIconsProps)
{
    const InScenarioIcon = ({ IconElement, length, postLengthLabel } : { IconElement: SvgIconComponent, length : number, postLengthLabel : string }) => 
    {
        if(length === 0) return <></>;

        return (
            <Tooltip sx = {{ userSelect: 'none' }} title={ `${length} ${postLengthLabel}` }>
                <FormControlLabel 
                    sx = {{ 
                        marginX : '.1rem', 
                        fontSize : '.8rem' 
                    }} 
                    control = {
                        <IconElement sx = {{ marginRight:'.3rem' }} fontSize='small'/>
                    } 
                    label = {
                        <Box sx = {{ fontSize:'.9rem', userSelect: 'none' }}>
                            {`x${length}`}
                        </Box>
                    } 
                />
            </Tooltip>
        );
    };

    // const [ t ] = useTranslation();
    
    const inScenarioIconGroupsLabel = "groupes" //t('');
    const inScenarioIconStudentsLabel = "étudiants" //t('');

    return (
        <Box
            sx = {{
                display: 'flex',
                width: '100%'
            }}
        >
            <InScenarioIcon 
                IconElement = { GroupsIcon } 
                length = { groupsInScenarioLength } 
                postLengthLabel = { inScenarioIconGroupsLabel }
            />
            <InScenarioIcon 
                IconElement = { PersonIcon } 
                length = { studentsInScenarioLength } 
                postLengthLabel = { inScenarioIconStudentsLabel }
            />
        </Box>
    );
}