import { API, ApiTags, DefaultPostRequest } from "..";
import { Class } from "../../Store/SimpleCreateExperiment/Types";
import { Student } from "../../Store/Student/Types";

export namespace Classes
{
    export type ClasseStudentsRequest = DefaultPostRequest & {
        classId: number,
    };

    export type ClasseStudentsResponse = Student[];

    export type ClasseRequest = DefaultPostRequest & {
        classId: number,
    };

    export type ClasseResponse = Class;

    export type ClassName = String;
    export type ClassId = number;

    export type AddClassRequest = DefaultPostRequest & {
        className: ClassName
    };
    export type RemoveClassRequest = DefaultPostRequest & {
        id: ClassId
    };
    export type RemoveClassResponse = {};

    export type ClassJoinKeyResponse = String;

    export type ClassInvalidateLinkResponse = {};

    export type ClassRemoveStudentsRequest = DefaultPostRequest & {
        classId: number,
        studentsIds: number[],
    };
    export type ClassRemoveStudentsResponse = {};

    export const api = API.injectEndpoints(
    {
        endpoints: build => (
            {
                classStudents: build.query<ClasseStudentsResponse, ClasseStudentsRequest>(
                {
                    query: ({ token, classId }) => 
                    ({
                        url: `classes/students`,
                        method: 'POST',
                        formData : true,
                        body: {
                            token,
                            class_id: classId,
                        }
                    }),
                    providesTags: [ApiTags.Classes],
                    transformResponse: (response: any[]) => 
                    {
                        return response.map(({ id, firstName, lastName, SchoolClasses }) => (
                        {
                            id, firstName, lastName,
                            classId : SchoolClasses.id,
                            className : SchoolClasses.name,
                            schoolId : SchoolClasses.SchoolId,
                        }));
                    },
                }),
                getClass: build.query<ClasseResponse, ClasseRequest>(
                {
                    query: ({ token, classId }) => 
                    ({
                        url: `classes/getClass`,
                        method: 'POST',
                        formData : true,
                        body: {
                            token,
                            class_id: classId,
                        }
                    }),
                    transformResponse: ({ id, name, identifierName, joinKey, isGroup, SchoolId, School }) => (
                    {
                        id, name, identifierName, joinKey, isGroup, SchoolId,
                        SchoolName : School.name,
                        studentCount : -1,
                    }),
                }),
                addClass: build.mutation<ClasseResponse, AddClassRequest>(
                    {
                        query: ({ token, className }) =>
                        ({
                            url: `classes/`,
                            method: 'POST',
                            formData: true,
                            body: {
                                token,
                                name: className,
                            }
                        }),
                        invalidatesTags: [ApiTags.Classes]
                    }),
                removeClass: build.mutation<RemoveClassResponse, RemoveClassRequest>(
                {
                    query: ({ token, id }) =>
                    ({
                        url: `classes/removeclass`,
                        method: 'POST',
                        formData: true,
                        body: {
                            token,
                            id: id,
                        },
                        responseHandler: 'text'
                    }),
                    invalidatesTags: [ApiTags.Classes]
                }),
                getJoinKey: build.query<ClassJoinKeyResponse, ClasseRequest>(
                    {
                        query: ({ token, classId }) =>
                        ({
                            url: `classes/getjoinkey`,
                            method: 'POST',
                            formData: true,
                            body: {
                                token,
                                class_id: classId,
                            },
                            responseHandler: 'text'
                        }),
                        providesTags: [ApiTags.Classes]
                    }),
                generateJoinKey: build.mutation<ClassJoinKeyResponse, ClasseRequest>(
                    {
                        query: ({ token, classId }) =>
                        ({
                            url: `classes/generatekey`,
                            method: 'POST',
                            formData: true,
                            body: {
                                token,
                                class_id: classId,
                            },
                            responseHandler: 'text'
                        }),
                        invalidatesTags: [ApiTags.Classes]
                    }),
                invalidateLink: build.mutation<ClassInvalidateLinkResponse, ClasseRequest>(
                    {
                        query: ({ token, classId }) =>
                        ({
                            url: `classes/invalidate-link`,
                            method: 'POST',
                            formData: true,
                            body: {
                                token,
                                class_id: classId,
                            },
                            responseHandler: 'text'
                        }),
                        invalidatesTags: [ApiTags.Classes]
                    }),
                removeStudents: build.mutation<ClassRemoveStudentsResponse, ClassRemoveStudentsRequest>(
                    {
                        query: ({ token, classId, studentsIds }) =>
                        ({
                            url: `classes/remove-students`,
                            method: 'POST',
                            formData: true,
                            body: {
                                token,
                                classId,
                                studentsIds,
                            }
                        }),
                        invalidatesTags: [ApiTags.Classes]
                    }),
        }),
    });

    export const { 
        useClassStudentsQuery,
        useGetClassQuery,
        useAddClassMutation,
        useRemoveClassMutation,
        useGetJoinKeyQuery,
        useGenerateJoinKeyMutation,
        useInvalidateLinkMutation,
        useRemoveStudentsMutation,
    } = api;
}