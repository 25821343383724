import i18n, { InitOptions } from "i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

export default function InitTranslationSystem() : void
{
    const i18nInitConfig : InitOptions = {
        fallbackLng: "fr-FR",
        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: '/i18n/{{lng}}-{{ns}}.json'
        }
    };

    if(process.env.REACT_APP_ENV === 'DEV')
    {
        i18nInitConfig.debug = true;
    }

    i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init(i18nInitConfig);
}