import * as React from "react";

import { useTranslation, Trans } from "react-i18next";

import { 
    Box,
    Button,
    ButtonTypeMap, 
    Chip, 
    Paper, 
    Stack, 
    Typography,
    alpha,
    darken
} from "@mui/material";

import {
     
} from "@mui/icons-material";

import { Scenario } from "../../../App/Store/SimpleCreateExperiment/Types";

export type AddButtonStatesConfig = 
{
    default: {
        text: string;
        color: ButtonTypeMap<{}, "button">['props']['color'];
    };
    added: {
        text: string;
        color: ButtonTypeMap<{}, "button">['props']['color'];
    };
}

type ScenarioCardProps = {
    scenario: Scenario,
    handleClose : () => void,
    isAddedValidator?: (scenarioId : number) => boolean,
    onClickAdd?: (scenario: Scenario) => void,
    addButtonStatesConfig?: AddButtonStatesConfig
};

export default function ScenarioCard({ scenario, isAddedValidator, onClickAdd, addButtonStatesConfig, handleClose } : ScenarioCardProps)
{  
    const [ t ] = useTranslation();

    if(!isAddedValidator || !onClickAdd || !addButtonStatesConfig) return null;

    const isAdded = () => isAddedValidator(scenario.id);

    const GetCurrentConfigByState = () => 
        (isAdded()) ? addButtonStatesConfig.added : addButtonStatesConfig.default;

    return (
        <Paper
            sx = {{ 
                backgroundImage: `url(${process.env.REACT_APP_URL_RESSOURCES}${scenario.imgPath})`,
                backgroundSize: 'auto',
                backgroundRepeat: 'no-repeat',
                color: 'white',
            }}
        >   
            <Box sx={{ height: '10rem' }}></Box>

            <Paper
                sx = {{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingY: '.3rem',
                    paddingX: '.5rem',
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    backgroundColor: alpha(scenario.colorCode, 0.7)
                }}
            >
                <Stack
                    direction = "row"
                    justifyContent = "flex-start"
                    alignItems = "center"
                    spacing = { 1 }
                >
                    {
                        (scenario.category) ? <Chip
                            label = { t(`scenarios.categories.${scenario.category}`)  }
                            variant = 'outlined'
                            sx = {{
                                backgroundColor: scenario.colorCode,
                                borderColor: darken(scenario.colorCode, 0.2),
                            }}
                        /> : null
                    }
                    <Typography variant='h6'>{t(`scenarios.names.${scenario.applicationRef}`) }</Typography>
                </Stack>
                
                <Button 
                    variant = 'contained' 
                    color = { GetCurrentConfigByState().color }
                    onClick = { () => onClickAdd(scenario) }
                    sx = {{
                        color: 'white',
                    }}
                >
                    { GetCurrentConfigByState().text }
                </Button>

            </Paper>
            <Paper
                sx = {{
                    backgroundColor: theme => alpha(theme.palette.primary.light, 0.8),
                    color: theme => theme.palette.primary.contrastText,
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                    padding: '1rem',
                }}
            >
                <Typography variant='body2'>
                    <Trans i18nKey={"scenarios.descriptions." + scenario.applicationRef} />
                </Typography>
            </Paper>
        </Paper>
    );
}