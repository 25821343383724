import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../App/Store';

import { 
    Collapse, 
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper
} from '@mui/material';

import { staticOverlaySlice } from '../../../../../App/Store/StaticOverlaySlice';
import { NavButtonChildConfig } from '../Config';

type NavigationButtonChildrenProps = {
    readonly open: boolean,
    readonly buttons: NavButtonChildConfig[],
}

export default function NavigationButtonChildren({ open, buttons }: NavigationButtonChildrenProps)
{
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const drawerOpen = useSelector<RootState, boolean>((state) => state.StaticOverlay.drawerOpen);

    const Actions = staticOverlaySlice.Actions;

    const OpenDrawer = () => dispatch(Actions.setDrawerOpen(true));
    const CloseDrawer = () => dispatch(Actions.setDrawerOpen(false));

    function makeNavigation(button : NavButtonChildConfig)
    {
        return () => {
            if(!button.navigate)
                return;
    
            if(button.navigate.openOnNavigate)
            {
                OpenDrawer();
            }
            else if(button.navigate.closeOnNavigate)
            {
                CloseDrawer();
            }
            
            if(!button.navigate.location) 
                return;

            navigate(button.navigate.location);
        }
    }

    const Buttons: JSX.Element[] = [];

    for(const button of buttons)
    {
        const newButton = 
        (
            <ListItemButton 
                onClick = { makeNavigation(button) }
                key = {button.name} 
                sx = {{ pl: 4 }}
            >
                { button.icon ? <ListItemIcon> { button.icon } </ListItemIcon> : <></> }
                <ListItemText primary = { t(button.name) } />
            </ListItemButton>
        );

        Buttons.push(newButton);
    }

    return (
        <Paper>
            <Collapse 
                in={open} 
                timeout="auto" 
                unmountOnExit
                hidden={!drawerOpen}
            >
                <List component="div">
                    { Buttons }
                </List>
            </Collapse>
        </Paper>
    );
}