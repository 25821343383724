import * as React from 'react';

import { useTranslation } from 'react-i18next';

import { 
    Box,
    Button,
    Grid, Paper, SxProps, Theme, Typography 
} from '@mui/material';

import { Experimentation } from '../../../App/Store/Experiment/Types';
import DateFormat from '../../../App/Framework/Utils/DateFormat';
import { Scenario } from '../../../App/Store/SimpleCreateExperiment/Types';
import { Scenarios } from '../../../App/API/Scenarios';

type ExperimentationItemProps = {
    experimentation: Experimentation,
    buttonText: string,
    OnClickButton: (experimentation: Experimentation, scenario?: Scenario) => void,
    isStudent? : boolean,
};

export default function ExperimentationItem({ experimentation, buttonText, OnClickButton, isStudent } : ExperimentationItemProps) : JSX.Element
{
    const [ t, i18n ] = useTranslation();
    
    const startDate = DateFormat.fromString(experimentation.startDate, i18n, {
        day: "numeric",
        month: 'long',
        year: 'numeric',
    });

    const { data: scenario } = Scenarios.useGetScenarioByIdQuery({ id: experimentation.ScenarioId });
    
    const scenarioElement = scenario ? (
        <>
            <Typography variant='body1' component='span' fontSize={ 20 }
                sx = {{ color: scenario.colorCode }}
            > 
                { scenario.category ? t(`scenarios.categories.${scenario.category}`) : '' } 
            </Typography>
            <Typography variant='body1' component='span' fontSize={ 15 }> 
                {' ' + t(`scenarios.names.${scenario.applicationRef}`) } 
            </Typography>
        </>
    ) : null;
    
    let dynamicSx : SxProps<Theme> = {};

    if(experimentation.isOngoing)
    {
        dynamicSx = {
            borderColor: theme => theme.palette.success.main,
            borderStyle: 'solid',
            borderWidth: '1px'
        } as SxProps<Theme>;
    }

    function OnClickDetails()
    {
        OnClickButton(experimentation, scenario);
    }

    return (
        <Grid item xs = { 6 } md= { 3 } xl = { 2 }>
            <Paper
                sx = {{
                    ...dynamicSx,
                    padding: '1rem',
                    userSelect: 'none'
                }}
            >
                <Typography variant='h6' component='h4'>
                    { isStudent && 
                        `${experimentation.TeacherFirstName} ${experimentation.TeacherLastName} - `
                    }
                    { experimentation.schoolClassName }
                </Typography>
                <Typography variant='body2' color = 'text.secondary'> { startDate } </Typography>
                
                <Box
                    sx = {{
                        marginY: '1rem'
                    }}
                >
                    { scenarioElement }
                </Box>
                
                <Button variant='contained' size='small' fullWidth
                    onClick = { OnClickDetails }
                >
                    { buttonText } 
                </Button>
            </Paper>
        </Grid>
    );
}