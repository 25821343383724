import * as React from 'react';

import { 
    Step, 
    StepButton, 
    StepLabel
} from '@mui/material';

import { 
    ExperiementationSteps 
} from '../../../../App/Store/CreateExpriment/CreateExperimentSlice';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../App/Store';

type CreateExperiementStepperStepProps = 
{
    index: number,
    text: string,
    step: ExperiementationSteps,
    navigateToStep: () => void
};

export default function CreateExperiementStepperStep({ index, text, step, navigateToStep } : CreateExperiementStepperStepProps) : JSX.Element
{
    const disabled = useSelector((state : RootState) => state.CreateExperiment[step].hideInSteper) || false; 
    const completed = useSelector((state : RootState) => state.CreateExperiment[step].finished); 
    const active = useSelector((state : RootState) => state.CreateExperiment[step].active); 

    return (
        <Step 
            key = { step }
            disabled = { disabled }
            completed = { completed }
            active = { active }
            index = { index }
        >
            <StepButton 
                color="inherit" 
                onClick={ navigateToStep }
            >
                <StepLabel 
                    sx={{flexDirection: 'column'}} 
                >                    
                    { text }
                </StepLabel>
            </StepButton>

        </Step>
    );
}