import { Box, Stepper } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../../App/Store';
import { ExperiementationSteps } from '../../../../App/Store/CreateExpriment/CreateExperimentSlice';
import CreateExperiementStepperStep from './Step';

type StepsConfig = {
    step: ExperiementationSteps,
    name : string,
    navigationPath : string
}

export default function CreateExperimentationStepper() : JSX.Element
{
    const navigate = useNavigate();

    // TODO : Add Translations
    const CreateExperiementSteperStepsConfig : StepsConfig[] = 
    [
        {
            step: 'classStep',
            name : 'Class step',
            navigationPath : '/experimentations/create-advanced/create-class'
        },
        {
            step: 'groupsStep',
            name : 'Groups step',
            navigationPath : '/experimentations/create-advanced/configure-groups'
        },
        {
            step: 'scenarioStep',
            name : 'Scenario step',
            navigationPath : '/experimentations/create-advanced/create-scenerios'
        },
        {
            step: 'endStep',
            name : 'End step',
            navigationPath : '/experimentations/create-advanced/end'
        }
    ];
    
    const createExperiementState = useSelector((rootState : RootState) => rootState.CreateExperiment);

    const stepperSteps : JSX.Element[] = [];

    let index = 0;
    for(const stepperStepConfig of CreateExperiementSteperStepsConfig)
    {
        const stepState = createExperiementState[stepperStepConfig.step];

        if(stepState.hideInSteper)
            continue;

        stepperSteps.push(
            <CreateExperiementStepperStep 
                key = { stepperStepConfig.step }
                text = { stepperStepConfig.name }
                step = { stepperStepConfig.step }
                index = { index }
                navigateToStep = { () => 
                { 
                    if(stepState.reachable)
                        navigate(stepperStepConfig.navigationPath);
                }}
            />
        );

        ++index;
    }

    return (
        <Box sx={{ 
            width: '100%',
            marginTop: '1rem', 
            marginBottom: '3rem' 
        }}>
            <Stepper 
                activeStep = { 0 }
                nonLinear
            >
                { stepperSteps }
            </Stepper>
        </Box>
    );
}