import * as React from 'react';

import { RootState } from "../../../../App/Store";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

import SimpleCreateExperiementSlice, { simpleCreateExperimentSlice } from "../../../../App/Store/SimpleCreateExperiment/SimpleCreateExperiementSlice";
import { Scenario } from '../../../../App/Store/SimpleCreateExperiment/Types';

import ScenarioBrowserModal from '../../../Components/ScenarioModal/ScenarioBrowserModal';
import ScenarioCard, { AddButtonStatesConfig } from '../../../Components/ScenarioModal/ScenarioCard';

type CreateExperiementationScenarioModalProps = {
    open: boolean,
    handleClose: () => void
};

export default function CreateExperiementationScenarioModal({ open, handleClose } : CreateExperiementationScenarioModalProps) : JSX.Element
{
    return (
        <ScenarioBrowserModal
            open = { open }
            handleClose = { handleClose }
            buildScenarioCardComponent = { 
                (scenario, handleClose) => 
                    <CreateExperiementationScenarioCard 
                        scenario = { scenario }
                        handleClose = { handleClose }
                    /> 
            }
        />
    );
}

type CreateExperiementationScenarioCardProps = {
    scenario: Scenario,
    handleClose : () => void
};

function CreateExperiementationScenarioCard({ scenario, handleClose } : CreateExperiementationScenarioCardProps) : JSX.Element
{
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const SimpleCreateExperimentState = useSelector((state: RootState) => state.SimpleCreateExperiment);
    const isAddedValidator = () => SimpleCreateExperiementSlice.Selectors(SimpleCreateExperimentState).isScenarioSelected(scenario.id);

    function OnClickAdd(scenario : Scenario)   
    {
        if(!isAddedValidator())
        {
            handleClose();
            dispatch(simpleCreateExperimentSlice.Actions.setScenario(scenario));
        }
    }

    const AddButtonStatesConfigs : AddButtonStatesConfig = 
    {
        default: {
            text: t('experimentation.create.scenario.modal_select') as string,
            color: 'info',
        },
        added: {
            text: t('experimentation.create.scenario.modal_selected') as string,
            color: 'success'
        },
    }

    return (
        <ScenarioCard 
            scenario = { scenario }
            handleClose = { handleClose }
            isAddedValidator = { isAddedValidator }
            onClickAdd = { OnClickAdd }
            addButtonStatesConfig = { AddButtonStatesConfigs }
        />
    );
}