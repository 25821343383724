import * as React from 'react';

import { useSelector } from 'react-redux';

import { RootState } from '../../../App/Store';
import { useTranslation } from 'react-i18next';

import { 
    Button,
    ButtonProps,
    IconButton,
    Tooltip,
} from '@mui/material';

import { 
    Download as DownloadIcon,
    FileDownload as FileDownloadIcon
} from '@mui/icons-material';

import Logger from '../../../App/Framework/Utils/Logger';

import { ExperimentStats } from '../../../App/API/ExperimentStats';
import { Student } from '../../../App/Store/CreateExpriment/Types';

type DownloadStatsButtonProps = 
{
    student: Student,
    experimentId: number,
    onClick?: ButtonProps['onClick'],
    onMouseDown?: ButtonProps['onMouseDown'],
    onTouchStart?: ButtonProps['onTouchStart'],
};

export function DownloadStatsButtonIcon({ student, experimentId, onClick, onMouseDown, onTouchStart } : DownloadStatsButtonProps)
{
    const { t, i18n } = useTranslation();

    const token = useSelector((state: RootState) => state.User.token);
    const userType = useSelector((state: RootState) => state.User.userType);

    let useDownloadStatsMutation = ExperimentStats.useDownloadStatsAsStudentMutation;

    const requestData : DownloadStatsRequestData = { 
        token, 
        experimentId, 
        language: i18n.language
    };

    if(userType === 'teacher')
    {
        useDownloadStatsMutation = ExperimentStats.useDownloadStatsAsTeacherMutation;
        requestData.studentId = student.id;
    }

    const [ downloadStats ] = useDownloadStatsMutation();
    
    return (
        <Tooltip
            title = { t('experimentation_view.dl_exp_data') }
            placement='top'
        >
            <IconButton
                onMouseDown={ onMouseDown }
                onTouchStart={ onTouchStart }
                onClick = { 
                    event => {
                        OnClickDownloadStats(downloadStats, student, requestData);
                        if(onClick) onClick(event); 
                    }
                }
            >
                <FileDownloadIcon fontSize = 'medium'/>
            </IconButton>
        </Tooltip>
    );
}

export function DownloadStatsButton({ student, experimentId, onClick, onMouseDown, onTouchStart } : DownloadStatsButtonProps)
{
    const { t, i18n } = useTranslation();

    const token = useSelector((state: RootState) => state.User.token);
    const userType = useSelector((state: RootState) => state.User.userType);

    let useDownloadStatsMutation = ExperimentStats.useDownloadStatsAsStudentMutation;

    const requestData : DownloadStatsRequestData = { 
        token, 
        experimentId, 
        language: i18n.language
    };

    if(userType === 'teacher')
    {
        useDownloadStatsMutation = ExperimentStats.useDownloadStatsAsTeacherMutation;
        requestData.studentId = student.id;
    }

    const [ downloadStats ] = useDownloadStatsMutation();


    return (
        <Button 
            variant='outlined'
            startIcon={ <DownloadIcon /> }
            onMouseDown={ onMouseDown }
            onTouchStart={ onTouchStart }
            onClick = { 
                event => {
                    OnClickDownloadStats(downloadStats, student, requestData);
                    if(onClick) onClick(event); 
                }
            }
        >
            { t('experimentation_view.dl_exp_data') }
        </Button>
    );
}

type DownloadStatsRequestData = { 
    token: string, 
    experimentId: number, 
    language: string, 
    studentId?: number 
}

async function OnClickDownloadStats(downloadStats: ReturnType<typeof ExperimentStats.useDownloadStatsAsStudentMutation>[0] | ReturnType<typeof ExperimentStats.useDownloadStatsAsTeacherMutation>[0],
    student: Student, requestData : DownloadStatsRequestData)
{
    try 
    {
        const statsBlobUrl = await downloadStats(requestData).unwrap();

        const newDate = new Date();
        const minute = newDate.getMinutes();
        const hour = newDate.getHours();
        const date = newDate.getDate();
        const month = newDate.getMonth() + 1;
        const year = newDate.getFullYear();

        const link = document.createElement('a');
        link.href = statsBlobUrl;
        link.setAttribute('download', `${year}.${month}.${date}.${hour}.${minute} ${student.firstName}_${student.lastName}_results.xlsx`);
        document.body.appendChild(link);
        link.click();
    }
    catch (err)
    {
        Logger.error('While downloading stats', err);
    }
}
