import * as React from 'react';

import { Box, Grid, Paper } from '@mui/material';

import { 
    Add as AddIcon 
} 
from '@mui/icons-material';

type AddBoxProps = {
    OnClick?: () => void
}

export default function AddBox({ OnClick } : AddBoxProps)
{
    return (
        <Grid item xs={ 1 }>
            <Paper
                elevation = { 3 }
                sx={{
                    margin: '.5rem',
                    height: '10rem',
                    display: 'flex',
                    flexDirection : 'column',
                    alignItems: 'center',
                    justifyContent : 'center',
                    ":hover" : {
                        backgroundColor: 'rgb(49, 68, 89)',
                        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        cursor: 'pointer'
                    }
                }}
                onClick = { OnClick }
            >
                <Box>
                    <AddIcon sx={{ fontSize: '4.6rem' }} />
                </Box>
            </Paper>
        </Grid>
    );
}