import { PayloadAction } from '@reduxjs/toolkit';
import AppSlice from '../../Framework/Store/AppSlice';
import { Scenario, Class } from './Types';

export type ExperimentationOptions = {
    skipPreparation: boolean,
    modified : boolean,
};

export type SimpleCreateExperiementState = 
{
    class: Class | null,
    scenario: Scenario | null,
    options: ExperimentationOptions,
};

function Selectors(state: SimpleCreateExperiementState)
{
    return {
        isScenarioSelected: (scenarioId: number) => state.scenario?.id === scenarioId,
    };
}

export default class SimpleCreateExperiementSlice extends AppSlice<SimpleCreateExperiementState>
{
    public static readonly InitialState : SimpleCreateExperiementState = 
    {
        class: null,
        scenario: null,
        options: {
            skipPreparation: false,
            modified: false
        },
    }

    protected getConfig()
    {
        return {
            name: 'SimpleCreateExperiment',
            initialState: SimpleCreateExperiementSlice.InitialState
        };
    }

    public static Selectors = Selectors;

    public getReducers()
    {
        return {
            setClass: (state: SimpleCreateExperiementState, action: PayloadAction<Class>) => 
            {
                state.class = action.payload;
            },

            setScenario: (state: SimpleCreateExperiementState, action: PayloadAction<Scenario>) => 
            {
                state.scenario = action.payload;
            },

            setOptionSkipPreparation: (state: SimpleCreateExperiementState, action: PayloadAction<boolean>) => 
            {
                state.options.skipPreparation = action.payload;
            },

            setOptionsModified: (state: SimpleCreateExperiementState, action: PayloadAction<boolean>) => 
            {
                state.options.modified = action.payload;
            },

            resetCreateExperiment: (state: SimpleCreateExperiementState) =>
            {
                const initial = SimpleCreateExperiementSlice.InitialState;

                state.class = initial.class;
                state.options = initial.options;
                state.scenario = initial.scenario;
            }
        };
    }
}

export const simpleCreateExperimentSlice = new SimpleCreateExperiementSlice();