import * as React from 'react'; 

import { 
    Box, 
    List, 
    Paper, 
    SxProps,
    Theme
} from '@mui/material';

import { DropTargetMonitor, useDrop } from 'react-dnd';

import { DragableItemTypes } from '../../../Constants';
import { CreateExperimentSlice, CreateExperimentTypes } from '../../../../../../App/Store/CreateExpriment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../App/Store';
import StudentListItem from './StudentListItem';

type ScenarioStudentListProps = {
    large? : boolean
};

export default function ScenarioStudentList({ large } : ScenarioStudentListProps) : JSX.Element
{
    const CreateExperimentState = useSelector((rootState: RootState) => rootState.CreateExperiment);

    const [ { isOver, canDrop }, dropRef ] = useDrop(
        () => ({
            accept: DragableItemTypes.StudentCard,
            drop: OnDrop,
            // TODO : Need to be replaced by validator when it will be fixed
            canDrop: () => true,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            }),
        }),
    );

    /*function CanDropValidator(student: CreateExperimentationStudent, monitor: DropTargetMonitor<CreateExperimentationStudent, void>) : boolean
    {
        // BUG : CreateExperimentState is not refreshed correctly. is this a problem with react-dnd function copy ?
        return !CreateExperimentSlice.Selectors(CreateExperimentState).getIsStudentInAGroup(student.id);
    }*/

    function OnDrop(student : CreateExperimentTypes.Student, monitor : DropTargetMonitor<unknown, void>)
    {
        //dispatch(createExperimentSlice.Actions.removeStudentFromHisGroup(student.id));
    }

    const studentsItems : JSX.Element[] = [];

    for(const studentId of CreateExperimentState.groupsStep.needToBeGroupedStudents)
    {
        if(CreateExperimentSlice.Selectors(CreateExperimentState).isStudentInAScenario(studentId))
            continue;

        const studentItem = (
            <StudentListItem 
                key={ studentId }
                studentId = { studentId }
            />
        );

        studentsItems.push(studentItem);
    }

    const HoverSx : SxProps<Theme> = (isOver && canDrop) ? {
        border: 'solid 2px rgba(50, 185, 62, 0.7)',
        borderRadius: '.3rem',
        outline: 'solid 2px rgba(0, 128, 2, 0.7)',
        backgroundColor: 'rgba(50, 205, 50, 0.1)'
    } : {
        border: 'solid 2px transparent',
        outline: 'solid 2px transparent'
    };

    return (
        <Paper
            sx={{ padding: '.5rem' }}
        >
            <List
                ref = { dropRef }
                sx={{
                    minHeight: '4rem',
                    maxHeight: '60vh',
                    overflow: 'auto',
                    my: '.5rem'
                }}
                disablePadding
            >
                <Box
                    sx = {{
                        minHeight: 'inherit',
                        ...HoverSx
                    }}
                >
                    { studentsItems }
                </Box>
            </List>
        </Paper>
    );
}