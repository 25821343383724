import * as React from 'react'; 

import { 
    Box, 
    List, 
    ListItem, 
    Paper,
    SxProps,
    Theme, 
} from '@mui/material';

import { CreateExperimentSlice, CreateExperimentTypes } from '../../../../../../App/Store/CreateExpriment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../App/Store';
import { DragableItemTypes } from '../../../Constants';
import { useDrag } from 'react-dnd';
import StudentsGroupItem from './StudentsGroupItem';
import MouseDragTracker from '../../../../../Components/Shared/DragMouseTracker';

type StudentsGroupsListProps = {
    large? : boolean
};

export default function StudentsGroupsList({ large } : StudentsGroupsListProps) : JSX.Element
{
    const CreateExperimentState = useSelector((rootState: RootState) => rootState.CreateExperiment);

    const studentsItems : JSX.Element[] = [];

    for(const group of Object.values(CreateExperimentState.groupsStep.studentGroups))
    {
        if(CreateExperimentSlice.Selectors(CreateExperimentState).isGroupInAScenario(group.name))
            continue;
        
        const studentItem = (
            <StudentsGroupsListItem 
                key = { group.name } 
                group = { group }
            />
        );

        studentsItems.push(studentItem);
    }

    return (
        <Paper
            sx={{ padding: '.5rem' }}
        >
            <List
                sx={{
                    minHeight: '4rem',
                    maxHeight: '60vh',
                    overflow: 'auto',
                    my: '.5rem'
                }}
                disablePadding
            >
                <Box
                    sx = {{
                        minHeight: 'inherit'
                    }}
                >
                    { studentsItems }
                </Box>
            </List>
        </Paper>
    );
}

type StudentListItemProps = {
    group : CreateExperimentTypes.StudentGroup,
    sx? : SxProps<Theme>
};

export function StudentsGroupsListItem({ group, sx } : StudentListItemProps)
{
    const [{ isDragging }, drag] = useDrag(() => 
    ({
        type: DragableItemTypes.StudentGroupCard,
        item: group,
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    }));

    const item = (
        <ListItem
            ref = { drag }
            component = "div"
            disablePadding
            sx = {{ 
                opacity: isDragging ? 0.5 : 1,
                ...sx
            }}
        >
            <StudentsGroupItem
                key = { group.name}
                groupName = { group.name }
            />
        </ListItem>
    );

    const onDraggedItem = (
        <MouseDragTracker>
            { item }
        </MouseDragTracker>
    );

    return (
        isDragging ? onDraggedItem : item
    );
}