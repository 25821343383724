import * as React from 'react';

import {
    Alert, Snackbar, SnackbarOrigin
} from '@mui/material';

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';

type QuerryErrorAlertProps = 
{
    error: FetchBaseQueryError | SerializedError | string | undefined
    autohideDuration?: number | null,
    anchorOrigin?: SnackbarOrigin
}

export function GetErrorMessage(errorObject: FetchBaseQueryError | SerializedError | string | undefined)
{
    return (errorObject as FetchBaseQueryError)?.data as string 
    || (errorObject as SerializedError)?.message
    || (errorObject as any)?.error
    || (errorObject as string)
    || ''
}

export default function QuerryErrorSnackbarAlert({ error, autohideDuration = 6000, anchorOrigin = { horizontal: 'right', vertical: 'bottom' } } : QuerryErrorAlertProps) : JSX.Element
{
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if(error)
            setOpen(true);
    }, [error]);

    return (
        <Snackbar open = { !!error && open } autoHideDuration = {autohideDuration} anchorOrigin={anchorOrigin}>
            <Alert severity='error'
                onClose={ () => setOpen(false) }
            >Error : { GetErrorMessage(error) } 
            </Alert>
        </Snackbar>
    );
}