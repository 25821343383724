import { i18n } from 'i18next'

export default class DateFormat
{
    private constructor() {}

    /**
     * @param date parsable date string
     * @param translationProvider if not provided, the browser language will be used regardless of the translations provider
     * @param options standard Intl.DateTimeFormatOptions, look for compatibility here : https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
     * @returns formated date
     */
    public static fromString(date : string, translationProvider?: i18n, options? : Intl.DateTimeFormatOptions) : string
    {
        return DateFormat.fromDate(new Date(date), translationProvider, options);
    }

     /**
     * @param date standard `Date` object
     * @param translationProvider if not provided, the browser language will be used regardless of the translations provider
     * @param options standard `Intl.DateTimeFormatOptions`, look for compatibility here : https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
     * @returns formated date
     */
    public static fromDate(date : Date, translationProvider?: i18n, options? : Intl.DateTimeFormatOptions) : string
    {
        return date.toLocaleDateString(translationProvider?.language, options);
    }
}