import * as React from 'react';

import { 
    Box,
    CircularProgress,
    CircularProgressProps 
} from '@mui/material';

type LoaderCircularProgressProps = 
{
    display?: boolean,
    size?: CircularProgressProps['size'],
    progressProps? : CircularProgressProps
};

export default function LoaderCircularProgress({ display, size, progressProps } : LoaderCircularProgressProps)
{
    return (
        <>
        {
            display &&
            <Box 
                sx = {{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <CircularProgress
                    size = { size }
                    { ...progressProps } 
                />
            </Box>
        }
        </>
    );
}