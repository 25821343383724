import * as React from 'react'; 

import { RootState } from '../../../../App/Store';
import { useSelector } from 'react-redux';

import { 
    Button, 
    Container, 
    Divider, 
    Grid, 
    Paper, 
    Stack, 
} from '@mui/material';

import AddBox from '../../../Components/Shared/AddBox';
import ScenarioStudentList from './GroupsAndStudents/Students/ScenarioStudentList';
import StudentsGroupsList from './GroupsAndStudents/Groups/StudentsGroupsList';
import ScenarioBrowserModal from './Scenarios/ScenarioBrowserModal';
import ActiveScenariosContainer from './Scenarios/ActiveScenariosContainer';

export default function ScenarioStepView() : JSX.Element
{
    const [ scenarioModalOpen, setScenarioModalOpen ] = React.useState(false);

    const CreateExperiementState = useSelector((state: RootState) => state.CreateExperiment);

    function OnValidate()
    {
        
    }

    function GroupsAndStudentsLists() : JSX.Element
    {
        const haveGroups = Object.values(CreateExperiementState.groupsStep.studentGroups).length > 0;
        const haveStudents = CreateExperiementState.groupsStep.needToBeGroupedStudents.length > 0;
        
        const items: JSX.Element[] = [];

        if(haveGroups)
        {
            items.push(
                <Grid item
                    key = { 'students' } 
                    xs = { (haveStudents) ? 2.7 : 5.4 }
                >
                    <StudentsGroupsList 
                        large = { !haveStudents }
                    />
                </Grid>
            );
        }

        if(haveStudents)
        {
            items.push(
                <Grid item 
                    key = { 'groups' } 
                    xs = { (haveGroups) ? 2.7 : 5.4 }
                >
                    <ScenarioStudentList
                        large = { !haveGroups }
                    />
                </Grid>
            );
        }

        return <> { items } </>
    }

    const ValidateGroupsTxt = "Valider les groupes" // t('');

    return (
        <Container maxWidth="xl">
            <Grid
                container
                spacing = { 2 }
                sx = {{
                    my: '1.5rem'
                }}
            >
                <GroupsAndStudentsLists />
    
                <Grid item>
                    <Divider orientation="vertical"/>
                </Grid>

                <Grid item xs>
                    <Paper
                        sx={{ 
                            height: '100%',
                            padding: '.5rem' 
                        }}
                    >
                        <Grid
                            container
                            direction = 'row'
                            columns = { 2 }
                        >
                            <ActiveScenariosContainer />

                            <AddBox 
                                OnClick={ () => setScenarioModalOpen(true) }
                            />
                        </Grid>

                    </Paper>
                </Grid>

            </Grid>

            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={ 4 }
                sx={{
                    my: "2rem"
                }}
            >
                <Button 
                    onClick = { OnValidate }
                    variant = "contained"
                >
                    { ValidateGroupsTxt }
                </Button>
            </Stack>
            <ScenarioBrowserModal 
                open = { scenarioModalOpen }
                handleClose = { () => setScenarioModalOpen(false) }
            />
        </Container>
    );
}