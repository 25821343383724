import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Container,
    Paper,
    Typography,
    Button
} from '@mui/material';

import { RootState } from '../../../App/Store';
import { Student } from '../../../App/API/Student';

export default function JoinClass(): JSX.Element {
    const [t] = useTranslation();
    const token = useSelector((state: RootState) => state.User.token);
    const userType = useSelector((rootState: RootState) => rootState.User.userType);
    const navigate = useNavigate();
    const { joinKey } = useParams();

    function HomeButton(): JSX.Element {

        function navigateHome() {
            navigate("/");
        }

        return (
            <Button
                variant='contained'
                onClick={navigateHome}
                color='info'>
                {t('student.join_class.home')}
            </Button>
        );
    }

    function JoinClassAsStudent(joinKey : String): JSX.Element {
        const { data: getClassData } = Student.useGetClassFromJoinKeyQuery({ token, joinKey });
        const [joinClassTrigger, { isLoading: isJoinClassLoading, isError: isJoinClassError, isSuccess: isJoinClassSuccess }] = Student.useJoinClassMutation();

        async function joinClass() {
            try {
                await joinClassTrigger({
                    token, joinKey
                }).unwrap();
            }
            catch (err) {
                console.error(err);
            }
        }

        return (
            <>
                {
                    getClassData && !isJoinClassLoading && !isJoinClassError && !isJoinClassSuccess && !getClassData.isAlreadyInClass &&
                    <>
                        <p>{t('student.join_class.desc')} <strong>{getClassData.foundClass && getClassData.foundClass.name}</strong></p>
                        <Button
                            variant='contained'
                            onClick={joinClass}
                            color='info'>
                            {t('student.join_class.join')}
                        </Button>
                    </>
                }
                {
                    getClassData && getClassData.isAlreadyInClass &&
                    <>
                        <p>{t('student.join_class.already_joined')} <strong>{getClassData.foundClass && getClassData.foundClass.name}</strong></p>
                        <HomeButton/>
                    </>
                }
                {
                    getClassData && isJoinClassSuccess &&
                    <>
                        <p>{t('student.join_class.joined_desc')} <strong>{getClassData.foundClass && getClassData.foundClass.name}</strong></p>
                        <HomeButton />
                    </>
                    
                }
                
            </>
            
        );
    }

    function redirectToAuth() {
        sessionStorage.setItem('after_auth_redirect', window.location.pathname);
        navigate("/auth");
    }

    return (
        <Container maxWidth='xl' sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Paper sx={{ p: 10, justifyContent: 'center', textAlign:'center' }}>
                <Typography variant='h4'>{t('student.join_class.title')}</Typography>
                {
                    !userType &&
                    <>
                        <p>{t('student.join_class.auth_needed')}</p>
                        <Button
                            variant='contained'
                            onClick={redirectToAuth}
                            color='info'>
                            {t('student.join_class.auth')}
                        </Button>
                    </>
                        
                }
                {
                    userType && userType !== "student" &&
                    <>
                        <p>{t('student.join_class.student_needed')}</p>
                        <HomeButton/>
                    </>
                    
                }
                {
                    joinKey && userType && userType === "student" &&
                    <>{JoinClassAsStudent(joinKey)}</>
                }
            </Paper>
        </Container>
    );
}