import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../App/Store';

import {
    Container,
    Grid, 
    SxProps, 
    Theme, 
    Typography,
    Button
} from '@mui/material';

import ClassGridItem from './ClassGridItem';

import QuerryErrorSnackbarAlert from '../../Components/Shared/QuerryErrorAlert';
import LoaderCircularProgress from '../../Components/Shared/LoaderCircularProgress';

import { Teacher } from '../../../App/API/Teacher';
import { Class } from '../../../App/Store/SimpleCreateExperiment/Types';

import TeacherAddClassButton from './ManageClasses/AddClass/TeacherAddClassButton';
import TeacherRemoveClassPopup from './ManageClasses/RemoveClasses/TeacherRemoveClassPopup';

export default function TeacherClassesView(): JSX.Element
{
    const [ t ] = useTranslation();

    const token = useSelector((state: RootState) => state.User.token);
    const { data : classes = [], isLoading: isClassesLoading, error: isClassesLoadingError } = Teacher.useTeacherClassesQuery({ token });
    const { data: isClassManagementAllowed, isLoading: isClassManagementAllowedLoading, error: isClassManagementAllowedError } = Teacher.useTeacherClassManagementAllowedQuery({ token });

    const [removeClassPopupOpened, setRemoveClassPopupOpened] = React.useState(false);
    const [selectedClassToRemove, setSelectedClassToRemove] = React.useState<Class>();

    function OpenRemoveClassPopup(selectedClass: Class) {
        setSelectedClassToRemove(selectedClass);
        setRemoveClassPopupOpened(true);
    }

    return (
        <Container maxWidth='xl'>
            <Typography variant='h4' component='h2'
                sx = {{
                    marginBottom: '2rem'
                }}
            >
                { t('teacher.classes_view.title') }
            </Typography>

            {
                (removeClassPopupOpened || !removeClassPopupOpened) &&
                selectedClassToRemove &&
                <TeacherRemoveClassPopup popupOpened={removeClassPopupOpened} setPopupOpened={setRemoveClassPopupOpened} selectedClass={selectedClassToRemove} />
            }
            
            <LoaderCircularProgress display={isClassesLoading } />
            {isClassesLoadingError && <QuerryErrorSnackbarAlert error={isClassesLoadingError} /> }
            {isClassManagementAllowedError && <QuerryErrorSnackbarAlert error={isClassManagementAllowedError} /> }
            {
                (!isClassesLoading && !isClassesLoadingError && !isClassManagementAllowedLoading && !isClassManagementAllowedError) && isClassManagementAllowed != undefined &&
                <TeacherClassesGrid 
                    classes={classes}
                    displayRemoveButton={isClassManagementAllowed}
                    onRemoveButton={OpenRemoveClassPopup}
                />
            }
            <br></br>
            {
                !isClassManagementAllowedLoading &&
                !isClassManagementAllowedError &&
                isClassManagementAllowed &&
                <TeacherAddClassButton/>
            }
        </Container>
    );
}

type TeacherClassesGridProps = {
    classes: Class[],
    displayRemoveButton: Boolean
    onRemoveButton: (selectedClass: Class) => void
    sx?: SxProps<Theme>
}

export function TeacherClassesGrid({ classes, displayRemoveButton, onRemoveButton, sx }: TeacherClassesGridProps): JSX.Element
{  
    const elements : JSX.Element[] = [];

    for(const aClass of classes)
    {
        elements.push(
            <ClassGridItem
                key={aClass.id}
                aClass={aClass}
                displayRemoveButton={displayRemoveButton}
                onRemoveClassButton={onRemoveButton}
            />
        )
    }

    return (
        <Grid container spacing = { 2 } sx = { sx }> 
            { elements } 
        </Grid>
    );
}