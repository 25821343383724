import * as React from 'react'

import { 
    Box,
    Container, 
    Typography 
} from '@mui/material';

import BackButton from '../../Components/Shared/BackButton';

import { useTranslation } from 'react-i18next'

export default function LegalNoticesView() : JSX.Element 
{
    const [ t ] = useTranslation();

    return (
        <Container maxWidth='md' >
            <BackButton />

            <Box sx = {{ textAlign:'center' }}>
                <Typography variant='h3' component='h1' gutterBottom>{t('legal_notice.title')}</Typography>
                <Typography variant='h4' component='h1' gutterBottom>{t('legal_notice.identification.title')}</Typography>
                <Typography variant='body1' gutterBottom>{t('legal_notice.identification.name')}</Typography>
                <Typography variant='body1' gutterBottom>{t('legal_notice.identification.address')}</Typography>
                <Typography variant='body1' gutterBottom>{t('legal_notice.identification.mail')}</Typography>
                <Typography variant='body1' gutterBottom>{t('legal_notice.identification.capital')}</Typography>
                <Typography variant='body1' gutterBottom>{t('legal_notice.identification.manager')}</Typography>
                <Typography variant='body1' gutterBottom>{t('legal_notice.identification.host')}</Typography>

                <Typography variant='h4' component='h1' gutterBottom>{t('legal_notice.activity.title')}</Typography>
                <Typography variant='body1' gutterBottom>{t('legal_notice.activity.commercial_register')}</Typography>
                <Typography variant='body1' gutterBottom>{t('legal_notice.activity.fiscal_number')}</Typography>

                <Typography variant='h4' component='h1' gutterBottom>{t('legal_notice.cookies.title')}</Typography>
                <Typography variant='body1' gutterBottom>{t('legal_notice.cookies.content')}</Typography>

                <Typography variant='h4' component='h1' gutterBottom>{t('legal_notice.data.title')}</Typography>
                <Typography variant='body1' gutterBottom>{t('legal_notice.data.dpd')}</Typography>
                <Typography variant='body1' gutterBottom>{t('legal_notice.data.which_data')}</Typography>
                <Typography variant='body1' gutterBottom>{t('legal_notice.data.usage')}</Typography>
                <Typography variant='body1' gutterBottom>{t('legal_notice.data.opposite')}</Typography>
                <Typography variant='body1' gutterBottom>{t('legal_notice.data.cnil')}</Typography>
            </Box>
        </Container>
    );
}