import { Container } from "@mui/material";
import CreateExperimentationStepper from "../Stepper";
import ScenarioStepView from "./ScenarioStepView";

export default function ScenariosStep() : JSX.Element 
{
    return (
        <>
            <Container maxWidth='sm'>
                <CreateExperimentationStepper />
            </Container>
            <ScenarioStepView />
        </>
    );
}