import * as React from 'react';

import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { 
    DataGrid, 
    GridColDef,
    // This include is not used due to the commented part bellow (see comment for more info)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    GridRenderCellParams,
    GridValueGetterParams 
} from '@mui/x-data-grid';

import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';

import { 
    Alert, 
    // This include is not used due to the commented part bellow (see comment for more info)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Button, 
    Container, 
    Typography,
    Grid
} from '@mui/material';

import QuerryErrorSnackbarAlert from '../../../Components/Shared/QuerryErrorAlert';

import { RootState } from '../../../../App/Store';
import { Classes } from '../../../../App/API/Classes';
import { Teacher } from '../../../../App/API/Teacher';
import LoaderCircularProgress from '../../../Components/Shared/LoaderCircularProgress';
import AddStudentsButton from './AddStudentsButton';
import RemoveStudentsButton from './RemoveStudentsButton';

const TABLE_INITIAL_STATE : GridInitialStateCommunity = {
    pagination: {
        paginationModel: { page: 0, pageSize: 15 },
    },
};

const TABLE_PAGE_SIZE_OPTIONS: number[] = [15, 30];
export type MarginButtonStyle = { marginLeft: number, marginRight: number };

export default function StudentsView() : JSX.Element
{
    const [ t ] = useTranslation();
    const navigate = useNavigate();

    const { classId: classIdParam = '' } = useParams();

    const classId = parseInt(classIdParam);
    const token = useSelector((state : RootState) => state.User.token);
    const { data: students = [], isLoading : classStudentsIsLoading, error: classStudentsErr  } = Classes.useClassStudentsQuery({ token, classId });
    const { data: aClass, isLoading: classIsLoading, error: classErr } = Classes.useGetClassQuery({ token, classId });
    const { data: isClassManagementAllowed, isLoading: isClassManagementAllowedLoading } = Teacher.useTeacherClassManagementAllowedQuery({ token });

    const [editingClass, setEditingClass] = React.useState(false);
    const [selectedStudentsIds, setSelectedStudentsIds] = React.useState<number[]>([]);

    if(classIdParam === '')
    {
        navigate('/teacher/classes');
        return <></>;
    }

    const columns: GridColDef[] = 
    [
        {
          field: 'fullName',
          headerName: t('teacher.classes_view.list.full_name') as string,
          description: '',
          sortable: true,
          flex: 1,
          minWidth: 150,
          valueGetter: (params: GridValueGetterParams) =>
            `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
        // TODO : This part is for redirecting the user to the details page of a student, 
        // for now our backend is not providing enougth data to implement sutch a page, 
        // when the back will give the front thoses data uncomment the following lines to provite a redirection link in the studentsView list
        // {
        //     field: 'id',
        //     headerName: t('teacher.classes_view.list.details_header') as string,
        //     description: '',
        //     sortable: false,
        //     width: 200,
        //     renderCell: (params: GridRenderCellParams) => 
        //     {
        //         function OnClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>)
        //         {
        //             event.stopPropagation();
        //             navigate(`/teacher/student/${params.row.id}`);
        //         }

        //         return (
        //             <Button
        //                 onClick={ OnClick }
        //             >
        //                 { t('teacher.classes_view.list.details_button') }
        //             </Button>
        //         );
        //     },
        // },
        ];

    const manageButtonsStyle = {
        marginLeft: 5, marginRight: 5
    };

    return (
        <Container maxWidth='xl'>
            <Typography variant='h4' component='h1' marginY='1rem'>{ aClass?.name }</Typography>
            <Typography variant='h5' component='h2' marginY='1rem'>{ aClass?.SchoolName }</Typography>
            <Typography variant='body2' color='text.secondary' marginY='1rem'>{ students?.length } { t('teacher.classes_view.list.students_count') }</Typography>

            <Alert severity = 'warning'
                sx = {{
                    marginBottom: '1rem',
                }}
            >
                { t('teacher.classes_view.list.warning') }
            </Alert>

            <QuerryErrorSnackbarAlert error={classStudentsErr} />
            <QuerryErrorSnackbarAlert error={classErr} />
            
            <LoaderCircularProgress display = { classStudentsIsLoading || classIsLoading } />
            
            {
                (!classStudentsIsLoading && !classIsLoading) &&
                <DataGrid
                    columns={columns}
                    rows={students}
                    disableColumnFilter
                    disableColumnSelector
                    disableRowSelectionOnClick
                    disableColumnMenu
                    initialState={TABLE_INITIAL_STATE}
                    pageSizeOptions={TABLE_PAGE_SIZE_OPTIONS}
                    checkboxSelection={editingClass}
                    onRowSelectionModelChange={(newModel) => {
                        var selectedIds = [];
                        for (var studentIndex of newModel) {
                            selectedIds.push(Number(studentIndex));
                        }
                        setSelectedStudentsIds(selectedIds);
                    }}
                    localeText={{
                        footerRowSelected: (count) =>
                            count !== 1
                                ? `${count.toLocaleString()} ${t('teacher.classes_view.list.manage.students_selected')}`
                                : `${count.toLocaleString()} ${t('teacher.classes_view.list.manage.student_selected')}`,
                    }}
                />
            }
            <br/>
            {
                (!classStudentsIsLoading && !classIsLoading && !isClassManagementAllowedLoading && isClassManagementAllowed)
                &&
                <Grid container justifyContent='center'>
                        {
                            !editingClass &&
                            <Button
                            variant='contained'
                            onClick={() => setEditingClass(true)}
                            disabled={editingClass}
                            sx={manageButtonsStyle}
                        >
                            {t('teacher.classes_view.list.manage.button')}
                            </Button>
                        }
                        {
                            editingClass && aClass && <AddStudentsButton selectedClass={aClass} buttonStyle={manageButtonsStyle} />
                        }
                        {
                            editingClass && aClass && selectedStudentsIds &&
                            < RemoveStudentsButton
                                selectedClass={aClass}
                                selectedStudentsIds={selectedStudentsIds}
                                buttonStyle={manageButtonsStyle}
                                buttonEnabled={selectedStudentsIds.length > 0 } />
                        }
                </Grid>
            }
        </Container>
    );
}