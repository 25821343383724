import { API, AllApiTags } from "..";
import UserSlice, { UserType } from "../../Store/User/UserSlice";

export namespace Users
{
    export type TryLoginCredsRequest = 
    { 
        email: string, 
        password: string
    };

    export type TryLoginSSORequest = 
    {
        passenger_env: any
    };

    export type TryLoginResponse = 
    {
        token: string,
        userType: UserType
    };

    export type CheckTokenRequest = {
        token: string
    }

    export type CheckTokenResponse = 
    {
        valid: boolean,
        userType: UserType
    }

    export const api = API.injectEndpoints(
    {
        endpoints: build => (
        {
            checkToken: build.query<CheckTokenResponse, void>(
            {
                query: () => 
                ({
                    url: `users/check-token`,
                    method: 'POST',
                    formData : true,
                    body: { 
                        token: UserSlice.GetPersistantState().token 
                    },
                })
            }),

            tryLoginEppn: build.mutation<TryLoginResponse, TryLoginSSORequest>(
            {
                query: (reqData) => 
                ({
                    url: `users/check-eppn`,
                    method: 'POST',
                    formData : true,
                    body: reqData
                }),
                transformResponse: (responseData : [string, string]) => 
                ({
                    token: responseData[0],
                    userType: responseData[1] as UserType
                }),
                invalidatesTags: AllApiTags
            }),

            tryLoginGar: build.mutation<TryLoginResponse, TryLoginSSORequest>(
            {
                query: (reqData) => 
                ({
                    url: `users/check-gar`,
                    method: 'POST',
                    formData : true,
                    body: reqData,
                }),
                transformResponse: (responseData : [string, string]) => 
                ({
                    token: responseData[0],
                    userType: responseData[1] as UserType
                }),
                invalidatesTags: AllApiTags
            }),

            tryLoginCreds: build.mutation<TryLoginResponse, TryLoginCredsRequest>(
            {
                query: (reqData) => 
                ({
                    url: `users/login`,
                    method: 'POST',
                    formData : true,
                    body: reqData
                }),
                transformResponse: (responseData : [string, string]) => 
                ({
                    token: responseData[0],
                    userType: responseData[1] as UserType
                }),
                invalidatesTags: AllApiTags
            }),
        }),
    });

    export const { 
        useTryLoginCredsMutation,
        useTryLoginEppnMutation,
        useTryLoginGarMutation,
        useCheckTokenQuery
    } = api;
}