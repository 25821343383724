import * as React from 'react'

import { useTranslation } from 'react-i18next';

import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';

type EndExperimentButtonProps = {
    onEndExperiment: () => void;
}

export default function EndExperimentButton({ onEndExperiment } : EndExperimentButtonProps) : JSX.Element
{
    const { t } = useTranslation();
    
    const [ alertOpen, setAlertOpen ] = React.useState(false);

    return (
        <>
            <Button
                onClick = { () => setAlertOpen(true) }
                variant='contained'
                color = 'error'
            >
                { t('experimentation_view.list.end_experiment') }
            </Button>
            <Dialog
                open = { alertOpen }
                keepMounted
                onClose = { () => setAlertOpen(false) }
            >
                <DialogTitle> { t('experimentation_view.list.confirm_end_experiment_title') } </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        color='text.primary'
                    >
                        { t('experimentation_view.list.confirm_end_experiment_desc') }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick = { 
                            () => { 
                                setAlertOpen(false); 
                                onEndExperiment(); 
                            }
                        }
                        variant='contained'
                        color='error'
                    >
                        { t('experimentation_view.list.end_experiment') }
                    </Button>
                    <Button 
                        onClick = { () => setAlertOpen(false) }
                        variant='contained'
                        color='info'
                    >
                        { t('experimentation_view.list.confirm_end_experiment_cancel') }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}