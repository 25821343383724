import { API, ApiTags, DefaultPostRequest } from "..";
import { Class } from "../../Store/SimpleCreateExperiment/Types";

export namespace Teacher
{
    export type NameRequest = DefaultPostRequest & {};
    export type NameResponse = 
    {
        lastName: string,
        givenName: string
    };

    export type ClassesRequest = DefaultPostRequest & {};
    export type ClassesResponse = Class[];

    export type ClassesManagementAllowedRequest = DefaultPostRequest & {};
    export type ClassesManagementAllowedResponse = Boolean;

    export const api = API.injectEndpoints(
    {
        endpoints: build => (
        {
            teacherName: build.query<NameResponse, NameRequest>(
            {
                query: (reqData) => 
                ({
                    url: `teachers/name`,
                    method: 'POST',
                    formData : true,
                    body: reqData,
                }),
                providesTags: [ ApiTags.User ],
            }),

            teacherClasses: build.query<ClassesResponse, ClassesRequest>(
            {
                query: (reqData) => 
                ({
                    url: `teachers/classes`,
                    method: 'POST',
                    formData : true,
                    body: reqData
                }),
                providesTags: [ApiTags.Classes],
                transformResponse: (response: any[]) =>
                {
                    return response.map(aClass => ({
                        id: aClass.id,
                        name: aClass.name,
                        identifierName: aClass.identifierName,
                        joinKey: aClass.joinKey,
                        isGroup: aClass.isGroup,
                        SchoolId: aClass.SchoolId,
                        SchoolName: aClass.School.name,
                        studentCount: aClass.studentCount
                    }));
                },

            }),

            teacherClassManagementAllowed: build.query<ClassesManagementAllowedResponse, ClassesManagementAllowedRequest>(
                {
                    query: (reqData) =>
                    ({
                        url: `teachers/is-class-management-allowed`,
                        method: 'POST',
                        formData: true,
                        body: reqData
                    })
                }
            ),
        }),
    });

    export const { 
        useTeacherClassesQuery,
        useTeacherNameQuery,
        useTeacherClassManagementAllowedQuery,
    } = api;
}