import * as React from 'react';

import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../App/Store';

import { 
    Box, 
    Typography 
} from '@mui/material';

import {
    School as SchoolIcon
} from '@mui/icons-material';

import ChoiceContainer from './ChoiceContainer';
import SelectOptionWithIcon, { SelectOptionItemConfig } from '../../../Components/Shared/SelectOptionWithIcon';
import { simpleCreateExperimentSlice } from '../../../../App/Store/SimpleCreateExperiment/SimpleCreateExperiementSlice';
import { Class } from '../../../../App/Store/SimpleCreateExperiment/Types';

import { Teacher } from '../../../../App/API/Teacher';

export default function ClassChoice() : JSX.Element
{
    const dispatch = useDispatch();

    const [ classError, setClassError ] = React.useState(false);

    const selectedClass = useSelector((state : RootState) => state.SimpleCreateExperiment.class);
    const token = useSelector((state : RootState) => state.User.token);

    const { data : classes = [] } = Teacher.useTeacherClassesQuery({ token });

    const classButtonsConfig : SelectOptionItemConfig[] = classes.map(aClass => 
    ({ 
        icon: <SchoolIcon/>,
        text : aClass.name,
        onClick: () => OnClickClass(aClass)
    }));

    function OnClickClass(theClass : Class) : void
    {
        dispatch(simpleCreateExperimentSlice.Actions.setClass(theClass))
        setClassError(false);
    }

    const BottomContent = (
        <Typography 
            variant = 'body2'
            fontWeight = 'bold'
            textAlign = {'center'}
        >
            { selectedClass?.name }
        </Typography>
    );

    return (
        <ChoiceContainer
            title = { t('experimentation.create.class.choice_title') }
            displayBottom = { !!(selectedClass) }
            bottomContent = { BottomContent }
            isOnError = { classError }
            errorMessage = { t('experimentation.create.class.choice_noClassError') as string }
        >
            <Box
                sx = {{
                    paddingX: '1rem',
                    paddingY: '2rem',
                }}
            >
                <SelectOptionWithIcon 
                    placeholderIcon = { <SchoolIcon/> }
                    placeholder = { t('experimentation.create.class.choice_button') as string }
                    buttonGroupAriaLabel = 'select class'
                    buttonAriaLabel = 'select class'
                    itemsConfigs = { classButtonsConfig }
                    activeItemKey = { selectedClass?.name }
                    
                    variant = 'contained'
                    fullWidth
                />
            </Box>
            
        </ChoiceContainer>
    );
}
