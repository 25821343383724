import { API, DefaultPostRequest } from "..";

export namespace ExperimentStats
{
    export type DownloadStatsAsStudentRequest = DefaultPostRequest & {
        experimentId: number,
        // Note : for now the backend only support 'fr-Fr' and 'en' as language, witch is fine because we only support those two languages in front to
        language: string,
    };

    // Xmlx Blob url
    export type DownloadStatsResponse = string;
    
    export const api = API.injectEndpoints(
    {
        endpoints: build => (
        {
            downloadStatsAsStudent: build.mutation<DownloadStatsResponse, DownloadStatsAsStudentRequest>(
            {
                query: (reqData) => 
                ({
                    url: `stats/student/export-stats`,
                    method: 'POST',
                    formData : true,
                    body: reqData,
                    
                    responseType: 'blob',

                    responseHandler: async (response: Response) => 
                    {
                        const blob = await response.blob();
                        return window.URL.createObjectURL(blob);
                    },
                })
            }),
            downloadStatsAsTeacher: build.mutation<DownloadStatsResponse, DownloadStatsAsStudentRequest>(
            {
                query: (reqData) => 
                ({
                    url: `stats/teacher/export-stats`,
                    method: 'POST',
                    formData : true,
                    body: reqData,
                    
                    responseType: 'blob',

                    responseHandler: async (response: Response) => 
                    {
                        const blob = await response.blob();
                        return window.URL.createObjectURL(blob);
                    },
                })
            })
        }),
    });

    export const {
        useDownloadStatsAsStudentMutation,
        useDownloadStatsAsTeacherMutation,
    } = api;
}