import * as React from 'react';

import {
    Navigate,
    Route,
    Routes
} from "react-router-dom";

import TeacherHomeView from './Features/Pages/Home/TeacherHomeView';
import StudentHomeView from './Features/Pages/Home/StudentHomeView';
import CredentialsLoginView from './Features/Pages/Auth/CredentialsLoginView';
import AuthMethodsView from './Features/Pages/Auth/AuthMethodsView';
import SSOAuth from './Features/Pages/Auth/SSOAuth';
import JoinClass from './Features/Pages/JoinClass/JoinClass';

import TeacherClassesView from './Features/Pages/TeacherClasses/TeacherClassesView';

import { MyExperimentations } from './Features/Pages/MyExperimentations';

import {
    CGUView,
    RGPDView,
    LegalNoticesView
} from './Features/Pages/Legals'

import { 
    CreateExperimentationView, 
    CreateExperimentationClassStepView,
    CreateExperimentationGroupsStepView,
    CreateExperimentationScenariosStepView,
    CreateExperimentationEndStepView
} from './Features/Pages/CreateExperimentation';

import {
    TeacherPlayAloneView,
    UnityAppDemoView,
    StudentPlayView,
    DevPlayView
} from './Features/Pages/Play';

import SimpleCreateExperimentation from './Features/Pages/SimpleCreateExpriementation/SimpleCreateExperimentation';
import StudentsView from './Features/Pages/TeacherClasses/Students/StudentsView';

import {
    TeacherExperimentationView,
    ExperimentStudentDetails
} from './Features/Pages/Experimentation';
import EnvParser from './App/Framework/Utils/EnvParser';

export type RouteConfig = {
    index? : true,
    path?: string,
    element: JSX.Element
}

function BuildSSORoutes() : RouteConfig[]
{
    const SSOConfigs = Object.values(EnvParser.SSOAuthConfigs);

    const SSORoutes : RouteConfig[] = []; 

    for(const SSOConfig of SSOConfigs)
    {
        SSORoutes.push({
            path: SSOConfig.url,
            element: <SSOAuth
                SSOConfig = { SSOConfig }
            />
        });
    }

    return SSORoutes;
}

function GetDemoRoutes() : RouteConfig[]
{
    return [
        {
            path: '/demo',
            element: <UnityAppDemoView/>
        },
        {
            path: '/pharma-univ-gabaa-receptor',
            element: <UnityAppDemoView 
                applicationRef = 'pharma_univ_gabaa'
            />
        },
        {
            path: '/pharma-univ-opioid-derivatives',
            element: <UnityAppDemoView 
                applicationRef = 'pharma_univ_opioid_derivatives'
            />
        },
        {
            path: '/pharma-univ-antipsychotics',
            element: <UnityAppDemoView 
                applicationRef = 'pharma_univ_antipsychotics'
            />
        },
        {
            path: '/pharma-univ-rotarod',
            element: <UnityAppDemoView 
                applicationRef = 'pharma_univ_rotarod'
            />
        },
        {
            path: '/pharma-univ-tailflick',
            element: <UnityAppDemoView 
                applicationRef = 'pharma_univ_tailflick'
            />
        },
        {
            path: '/pharma-univ-catalepsy',
            element: <UnityAppDemoView 
                applicationRef = 'pharma_univ_catalepsy'
            />
        },
        {
            path: '/stereotaxy-test',
            element: <UnityAppDemoView
                applicationRef='stereotaxy'
            />
        },
        {
            path: '/pharma-univ-emn-plus-maze',
            element: <UnityAppDemoView
                applicationRef='pharma_plusmaze'
            />
        },
        {
            path: '/demo-pharma-region-1',
            element: <UnityAppDemoView
                applicationRef='pharma_univ_rotarod'
            />
        },
        {
            path: '/demo-pharma-region-2',
            element: <UnityAppDemoView
                applicationRef='pharma_univ_antipsychotics'
            />
        },
        {
            path: '/stereotaxy-072024',
            element: <UnityAppDemoView
                applicationRef='stereotaxy'
            />
        },
        {
            path: '/pharma-gabaa-receptor-4846531',
            element: <UnityAppDemoView
                applicationRef='pharma_univ_gabaa'
            />
        },
        {
            path: '/pharma-opioid-derivatives-9435484',
            element: <UnityAppDemoView
                applicationRef='pharma_univ_opioid_derivatives'
            />
        },
        {
            path: '/pharma-antipsychotics-6428791',
            element: <UnityAppDemoView
                applicationRef='pharma_univ_antipsychotics'
            />
        },
        {
            path: '/pharma-rotarod-3497138',
            element: <UnityAppDemoView
                applicationRef='pharma_univ_rotarod'
            />
        },
        {
            path: '/pharma-tailflick-1976435',
            element: <UnityAppDemoView
                applicationRef='pharma_univ_tailflick'
            />
        },
        {
            path: '/pharma-catalepsy-5649439',
            element: <UnityAppDemoView
                applicationRef='pharma_univ_catalepsy'
            />
        },
        {
            path: '/pharma-plus-maze-8649785',
            element: <UnityAppDemoView
                applicationRef='pharma_plusmaze'
            />
        },
        {
            path: '/ufr-sante-franche-comte-plusmaze-4397345',
            element: <UnityAppDemoView
                applicationRef='pharma_plusmaze'
            />
        },
        {
            path: '/ufr-sante-franche-comte-openfield-8642378',
            element: <UnityAppDemoView
                applicationRef='pharma_openfield'
            />
        },
        {
            path: '/ufr-sante-franche-comte-rotarod-9764895',
            element: <UnityAppDemoView
                applicationRef='pharma_univ_rotarod'
            />
        },
    ]
}

export const PublicRoutes : RouteConfig[] = 
[
    {
        path: '/auth',
        element: <AuthMethodsView />        
    },
    {
        path: '/auth/cred-login',
        element: <CredentialsLoginView />
    },
    {
        path: '/auth/logout',
        element: <>Logout : Not implemented</>
    },
    {
        path: '/legals/CGU',
        element: <CGUView />
    },
    {
        path: '/legals/RGPD',
        element: <RGPDView />
    },
    {
        path: '/legals/legal-notices',
        element: <LegalNoticesView />
        },
        {
            path: '/join/:joinKey',
            element: <JoinClass/>
        },
    // The following lines is adding routes dynamically at the array declaration, push a route in the `conditionallyAddedRoutes` array to add it
    ...(() => 
    {
        const conditionallyAddedRoutes : RouteConfig[] = [];

        // Add demo routes if the env variable is set to true
        if(process.env.REACT_APP_DEMO_ENABLED === 'true')
        {
            conditionallyAddedRoutes.push(...GetDemoRoutes());
        }

        // Add the dev play route if the env variable is set to true
        if(process.env.REACT_APP_ENABLE_DEV_PLAY === 'true')
        {
            conditionallyAddedRoutes.push({
                path: '/experimentations/play-dev',
                element: <DevPlayView />
            });
        }

        // Build SSO Routes, the route path is dinamically generated from env.REACT_APP_AUTH_SSO
        conditionallyAddedRoutes.push(...BuildSSORoutes());
        
        return conditionallyAddedRoutes;
    })(),
];

export const TeacherRoutes : RouteConfig[] = 
[
    ...PublicRoutes,
    {
        index : true,
        element: <TeacherHomeView />
    },
    {
        path: '/experimentations/create',
        element: <SimpleCreateExperimentation/>
    },
    {
        path: '/experimentations/create-advanced',
        element: <CreateExperimentationView/>
    },
    {
        path: '/experimentations/create-advanced/create-class',
        element: <CreateExperimentationClassStepView/>
    },
    {
        path: '/experimentations/create-advanced/configure-groups',
        element: <CreateExperimentationGroupsStepView/>
    },
    {
        path: '/experimentations/create-advanced/create-scenerios',
        element: <CreateExperimentationScenariosStepView />
    },
    {
        path: '/experimentations/create-advanced/end',
        element: <CreateExperimentationEndStepView />
    },
    {
        path: '/experimentations/play-alone',
        element: <TeacherPlayAloneView />
    },
    {
        path: '/experimentations/my-experimentations',
        element: <MyExperimentations />
    },
    {
        path: '/experimentations/:experimentationId',
        element: <TeacherExperimentationView />
    },
    {
        path: '/experimentations/student-details/:experimentationIdParam_studentIdParam',
        element: <ExperimentStudentDetails />
    },
    {
        path: '/teacher/classes',
        element: <TeacherClassesView />
    },
    {
        path: '/teacher/class/:classId',
        element: <StudentsView />
    },
    {
        path: '/teacher/student/:studentId',
        element: <>Student profile View : Not Implemented</>
    },
    {
        path: '*',
        element : <Navigate replace to='/' />
    },
    // The following lines is adding routes dynamically at the array declaration, push a route in the `conditionallyAddedRoutes` array to add it
    ...(() => 
    {
        const conditionallyAddedRoutes : RouteConfig[] = [];
        return conditionallyAddedRoutes;
    })(),
];

export const StudentRoutes : RouteConfig[] = 
[
    ...PublicRoutes,
    {
        index : true,
        element: <StudentHomeView />
    },
    {
        path: '/experimentations/my-experimentations',
        element: <MyExperimentations />
    },
    {
        path: '/experimentations/:experimentationId',
        element: <ExperimentStudentDetails />
    },
    {
        path: '/experimentations/play',
        element: <StudentPlayView />
    },
    {
        path: '/experimentations/play-alone',
        element: <TeacherPlayAloneView />
    },
    {
        path: '*',
        element : <Navigate replace to='/' />
    },
    // The following lines is adding routes dynamically at the array declaration, push a route in the `conditionallyAddedRoutes` array to add it
    ...(() => 
    {
        const conditionallyAddedRoutes : RouteConfig[] = [];            
        return conditionallyAddedRoutes;
    })(),
];

export const NotLoggedInRoute = 
[
    ...PublicRoutes,
    {
        path: '*',
        element : <Navigate replace to='/auth' />
    },
    // The following lines is adding routes dynamically at the array declaration, push a route in the `conditionallyAddedRoutes` array to add it
    ...(() => 
    {
        const conditionallyAddedRoutes : RouteConfig[] = [];            
        return conditionallyAddedRoutes;
    })(),
];

type AppRoutesProps = {
    readonly routesConfigs: RouteConfig[]
}

export function AppRoutes({ routesConfigs } : AppRoutesProps) : JSX.Element
{
    const routes : JSX.Element[] = [];

    for(const routeConfig of routesConfigs)
    {
        const route = (
            <Route
                index = { routeConfig.index }
                path = { routeConfig.path }
                element = { routeConfig.element }

                key = { routeConfig.path || 'index' }
            />
        );

        routes.push(route);
    }

    return (
        <Routes>
            { routes }
        </Routes>
    )
}