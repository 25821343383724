import { configureStore } from '@reduxjs/toolkit';

import StaticOverlaySlice, { staticOverlaySlice } from './StaticOverlaySlice';
import { CreateExperimentSlice, createExperimentSlice } from './CreateExpriment';
import UserSlice, { userSlice } from './User/UserSlice';
import SimpleCreateExperimentSlice, { simpleCreateExperimentSlice } from './SimpleCreateExperiment/SimpleCreateExperiementSlice';

import { API } from '../API';
import { FrontAPI } from '../FontAPI';

export const Store = configureStore({
    reducer: {
        StaticOverlay: staticOverlaySlice.Slice.reducer,
        CreateExperiment: createExperimentSlice.Slice.reducer,
        User: userSlice.Slice.reducer,
        SimpleCreateExperiment: simpleCreateExperimentSlice.Slice.reducer,
        [API.reducerPath]: API.reducer,
        [FrontAPI.reducerPath]: FrontAPI.reducer,
    },

    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware()
        .concat(API.middleware)
        .concat(FrontAPI.middleware),
});

export {
    StaticOverlaySlice,
    CreateExperimentSlice,
    UserSlice,
    SimpleCreateExperimentSlice,
};

export type RootState = ReturnType<typeof Store.getState>
export type AppDispatch = typeof Store.dispatch