import * as React from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { createExperimentSlice } from '../../../App/Store/CreateExpriment';

export default function CreateExperimentationView() : JSX.Element
{
    const dispatch = useDispatch();
    const navigate = useNavigate();

    React.useEffect(() => 
    {
        dispatch(createExperimentSlice.Actions.setStepActive('classStep'));
        dispatch(createExperimentSlice.Actions.setStepReachable('classStep'));

        navigate('/experimentations/create-advanced/create-class');
    });
    
    return (<></>);
}