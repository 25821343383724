import * as React from 'react';

import { 
    Box, 
    List, 
    ListItem,
    ListItemText, 
} from '@mui/material';

import { CreateExperimentTypes, CreateExperimentSlice } from '../../../../../../App/Store/CreateExpriment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../App/Store';

type StudentsGroupItemListProps = {
    groupName: string
};

export default function StudentsGroupItemList({ groupName } : StudentsGroupItemListProps) : JSX.Element
{
    const CreateExperimentState = useSelector(
        (state: RootState) => state.CreateExperiment)
    
    const studentsInGroup = CreateExperimentSlice.Selectors(CreateExperimentState).getGroupByName(groupName)?.studentsIds;

    const studentsCards : JSX.Element[] = [];

    if(studentsInGroup)
    {
        for(const studentId of studentsInGroup)
        {
            const student = CreateExperimentSlice.Selectors(CreateExperimentState).getStudentById(studentId);
    
            const studentCard = (
                <StudentsGroupItemListItem 
                    key = { studentId }
                    student = { student }
                />
            );
    
            studentsCards.push(studentCard);
        }
    }

    return ( 
        <Box
            sx = {{
                minHeight: '5rem',
                padding: '.4rem'
            }}
        >
            <Box
                sx = {{
                    minHeight: 'inherit'
                }}
            >
                <List disablePadding>
                    { studentsCards } 
                </List>
            </Box>     
        </Box> 
    );
}

type StudentListItemProps = {
    student: CreateExperimentTypes.Student
};

function StudentsGroupItemListItem({ student } : StudentListItemProps) : JSX.Element
{
    return (
        <ListItem 
            component = "div"
            disablePadding
        >
            <Box
                sx={{ 
                    px: '16px'
                }}
            >
                <ListItemText
                    sx={{ userSelect: 'none' }}
                    primary={`${student.firstName} ${student.lastName}`}
                />
            </Box>
        </ListItem>
    )
}