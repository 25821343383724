import * as React from 'react'

import { 
    Box,
    Container, 
    Typography 
} from '@mui/material';

import BackButton from '../../Components/Shared/BackButton';

import { useTranslation } from 'react-i18next'


export default function RGPDView() : JSX.Element 
{
    const [ t ] = useTranslation();
    
    return (
        <Container maxWidth='md' >
            <BackButton />
            <Box>
                <Typography variant='h3' component='h1' gutterBottom>{t('rgpd.title')}</Typography>
                <Typography variant='h4' component='h1' gutterBottom>{t('rgpd.article1.title')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article1.1')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article1.2')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article1.3')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article1.4')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article1.5')}</Typography>

                <Typography variant='h4' component='h1' gutterBottom>{t('rgpd.article2.title')}</Typography>
                <ul>
                    <li><Typography variant='body1' gutterBottom>{t('rgpd.article2.1')}</Typography></li>
                    <li><Typography variant='body1' gutterBottom>{t('rgpd.article2.2')}</Typography></li>
                    <li><Typography variant='body1' gutterBottom>{t('rgpd.article2.3')}</Typography></li>
                </ul>

                <Typography variant='h4' component='h1' gutterBottom>{t('rgpd.article3.title')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article3.1')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article3.2')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article3.3')}</Typography>

                <Typography variant='h4' component='h1' gutterBottom>{t('rgpd.article4.title')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article4.1')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article4.2')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article4.3')}</Typography>

                <Typography variant='h4' component='h1' gutterBottom>{t('rgpd.article5.title')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article5.1')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article5.2.title')}</Typography>
                <ul>
                    <li><Typography variant='body1' gutterBottom>{t('rgpd.article5.2.1')}</Typography></li>
                    <li><Typography variant='body1' gutterBottom>{t('rgpd.article5.2.2')}</Typography></li>
                    <li><Typography variant='body1' gutterBottom>{t('rgpd.article5.2.3')}</Typography></li>
                    <li><Typography variant='body1' gutterBottom>{t('rgpd.article5.2.4')}</Typography></li>
                </ul>
                <Typography variant='body1' gutterBottom>{t('rgpd.article5.3.title')}</Typography>
                <ul>
                    <li><Typography variant='body1' gutterBottom>{t('rgpd.article5.3.1')}</Typography></li>
                    <li><Typography variant='body1' gutterBottom>{t('rgpd.article5.3.2')}</Typography></li>
                </ul>

                <Typography variant='h4' component='h1' gutterBottom>{t('rgpd.article6.title')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article6.1')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article6.2')}</Typography>

                <Typography variant='h4' component='h1' gutterBottom>{t('rgpd.article7.title')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article7.1')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article7.2')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article7.3')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article7.4')}</Typography>

                <Typography variant='h4' component='h1' gutterBottom>{t('rgpd.article8.title')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article8.1')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article8.2')}</Typography>

                <Typography variant='h4' component='h1' gutterBottom>{t('rgpd.article9.title')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article9.1')}</Typography>

                <Typography variant='h4' component='h1' gutterBottom>{t('rgpd.article10.title')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article10.1')}</Typography>

                <Typography variant='h4' component='h1' gutterBottom>{t('rgpd.article11.title')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article11.1.title')}</Typography>
                <ul>
                    <li><Typography variant='body1' gutterBottom>{t('rgpd.article11.1.1')}</Typography></li>
                    <li><Typography variant='body1' gutterBottom>{t('rgpd.article11.1.2')}</Typography></li>
                    <li><Typography variant='body1' gutterBottom>{t('rgpd.article11.1.3')}</Typography></li>
                    <li><Typography variant='body1' gutterBottom>{t('rgpd.article11.1.4')}</Typography></li>
                    <li><Typography variant='body1' gutterBottom>{t('rgpd.article11.1.5')}</Typography></li>
                </ul>
                <Typography variant='body1' gutterBottom>{t('rgpd.article11.2')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article11.3')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article11.4')}</Typography>
                <Typography variant='body1' gutterBottom>{t('rgpd.article11.5')}</Typography>
            </Box>
        </Container>
    );
}