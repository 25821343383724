import * as React from 'react';

import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";

import { 
    Box,
    Divider, 
    List, 
    ListItem, 
    ListItemButton, 
    ListItemText, 
    Paper, 
    SxProps, 
    Theme, 
    Typography 
} from "@mui/material";

import { DragableItemTypes } from "../../Constants";
import { CreateExperimentSlice, createExperimentSlice, CreateExperimentTypes } from '../../../../../App/Store/CreateExpriment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../App/Store';
import MouseDragTracker from '../../../../Components/Shared/DragMouseTracker';

type StudentsListProps = {
    className : string
}

export default function StudentsList({ className } : StudentsListProps)
{
    const dispatch = useDispatch();

    const CreateExperimentState = useSelector((state: RootState) => state.CreateExperiment);

    const [ { isOver, canDrop }, dropRef ] = useDrop(
        () => ({
            accept: DragableItemTypes.StudentCard,
            drop: OnDrop,
            // TODO : Need to be replaced by validator when it will be fixed
            canDrop: () => true,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            }),
        }),
    );

    /*function CanDropValidator(student: CreateExperimentationStudent, monitor: DropTargetMonitor<CreateExperimentationStudent, void>) : boolean
    {
        // BUG : CreateExperimentState is not refreshed correctly. is this a problem with react-dnd function copy ?
        return !CreateExperimentSlice.Selectors(CreateExperimentState).getIsStudentInAGroup(student.id);
    }*/

    function OnDrop(student : CreateExperimentTypes.Student, monitor : DropTargetMonitor<unknown, void>)
    {
        dispatch(createExperimentSlice.Actions.removeStudentFromHisGroup(student.id));
    }

    const studentsItems : JSX.Element[] = [];

    for(const studentId of CreateExperimentState.groupsStep.needToBeGroupedStudents)
    {
        if(CreateExperimentSlice.Selectors(CreateExperimentState).isStudentInAScenario(studentId))
            continue;

        const student = CreateExperimentSlice.Selectors(CreateExperimentState).getStudentById(studentId);

        const studentItem = (
            <ListItem 
                key = { student.id } 
                component = "div"
                disablePadding
            >
                <StudentListItem 
                    student = { student }
                />
            </ListItem>
        );

        studentsItems.push(studentItem);
    }

    const HoverSx : SxProps<Theme> = (isOver && canDrop) ? {
        border: 'solid 2px rgba(50, 185, 62, 0.7)',
        borderRadius: '.3rem',
        outline: 'solid 2px rgba(0, 128, 2, 0.7)',
        backgroundColor: 'rgba(50, 205, 50, 0.1)'
    } : {
        border: 'solid 2px transparent',
        outline: 'solid 2px transparent'
    };

    return (
        <Paper
            sx={{ padding: '.5rem' }}
        >
            <Typography variant='h6' sx={{ margin: '.5rem' }}>{ className }</Typography>
            <Divider/>

            <List
                ref = { dropRef }
                sx={{
                    minHeight: '4rem',
                    maxHeight: '60vh',
                    overflow: 'auto',
                    my: '.5rem'
                }}
                disablePadding
            >
                <Box
                    sx = {{
                        minHeight: 'inherit',
                        ...HoverSx
                    }}
                >
                    { studentsItems }
                </Box>
            </List>
        </Paper>
    );
}

type StudentListItemProps = {
    student : CreateExperimentTypes.Student
};

function StudentListItem({ student } : StudentListItemProps)
{
    const [{ isDragging }, drag] = useDrag(() => 
    ({
        type: DragableItemTypes.StudentCard,
        item: student,
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    }));

    const item = (
        <ListItemButton
            ref = { drag }
            sx = {{ 
                py: 0,
                opacity: isDragging ? 0.5 : 1
            }}
        >
            <ListItemText
                primary={`${student.firstName} ${student.lastName}`}
            />
        </ListItemButton>
    );

    const onDraggedItem = (
        <MouseDragTracker>
            { item }
        </MouseDragTracker>
    );

    return (
        (isDragging) ? onDraggedItem : item
    );
}