import * as React from 'react';

import { useTranslation } from 'react-i18next';

import {
    Grid,
    Typography,
    lighten
} from '@mui/material';

import { Scenario } from '../../../../App/Store/SimpleCreateExperiment/Types';
import { Student } from '../../../../App/Store/CreateExpriment/Types';

type ExperimentStudentDetailsHeaderProps = { 
    scenario?: Scenario,
    student?: Student
};

export default function ExperimentStudentDetailsHeader({ scenario, student } : ExperimentStudentDetailsHeaderProps) : JSX.Element
{
    const { t } = useTranslation();

    return (
        <>
        {
            scenario &&
            <Grid
                container
                direction = "row"
                alignItems='center'
                sx = {{
                    backgroundColor: theme => lighten(theme.palette.background.paper, 0.3),
                    my: '1rem'
                }}
            >
                <Grid 
                    item
                    sx = {{
                        backgroundColor: scenario?.colorCode,
                        paddingY: '0.6rem',
                        paddingX: '1rem',
                    }}
                >
                    {
                        scenario?.category &&
                        <Typography
                            variant='body1'
                            component = 'span'
                            fontWeight = 'bold'
                            fontSize = '18pt'
                        >
                            { t(`scenarios.categories.${scenario?.category}`) + ' ' }
                        </Typography>
                    }
                    <Typography
                        variant='body2'
                        component='span'
                        fontSize = '13pt'
                    >
                        { t(`scenarios.names.${scenario?.applicationRef}`) as string }
                    </Typography>
                </Grid>
                
                <Grid
                    item
                    sx = {{
                        marginX: '1rem'
                    }}
                >
                    <Typography variant='h6'  component='p'>
                        { student?.firstName } { student?.lastName }
                    </Typography>
                </Grid>
            </Grid>
        }
        </>
    );
}