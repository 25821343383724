import * as React from 'react';

import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import { 
    Box, 
    Divider, 
    Stack 
} from '@mui/material';

import { theme } from '../../../Themes'

export default function Footer() : JSX.Element
{   
    const [ t ] = useTranslation();

    const linkStyle : React.CSSProperties = {
        textDecoration: 'none',
        color: theme.palette.text.secondary
    };

    return (
        <Box
            sx = {{
                marginTop: '6rem',
                marginBottom: '1rem',
            }}
        >
            <Divider />
            <Stack
                direction = "row"
                divider = { <Divider orientation="vertical" flexItem /> }
                spacing = { 2 }
                justifyContent = "center"
                sx = {{
                    marginTop: '1rem',
                }}
            >
                <Link style={linkStyle} to="/legals/CGU">{ t('legals.cgu_link') }</Link>
                <Link style={linkStyle} to="/legals/RGPD">{ t('legals.rgpd_link') }</Link>
                <Link style={linkStyle} to="/legals/legal-notices"> { t('legals.legal_notice_link') } </Link>
            </Stack>
        </Box>
    );
}