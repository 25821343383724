import { 
    PayloadAction
} from '@reduxjs/toolkit';

import AppSlice from '../Framework/Store/AppSlice';

export type StaticOverlayState = 
{
    drawerOpen: boolean
};

export const InitialState : StaticOverlayState = {
    drawerOpen: false
};

export default class StaticOverlaySlice extends AppSlice<StaticOverlayState>
{
    protected override getConfig()
    {
        return {
            name: 'StaticOverlay',
            initialState: InitialState
        }
    }

    public override getReducers()
    {
        return {
            setDrawerOpen(state: StaticOverlayState, drawerOpenAction : PayloadAction<boolean>)
            {
                state.drawerOpen = drawerOpenAction.payload;
            }
        }
    }
}

export const staticOverlaySlice = new StaticOverlaySlice();