export default class Logger 
{
    public static info(...args : any)
    {
        console.groupCollapsed('[Info]', ...args);
        console.trace();
        console.groupEnd();
    }

    public static log(...args : any)
    {
        console.groupCollapsed('[LOG]', ...args);
        console.trace();
        console.groupEnd();
    }

    public static warn(...args : any)
    {
        console.warn('[WARN]', ...args);
    }

    public static error(...args : any)
    {
        console.error('[ERR]', ...args);
    }
}