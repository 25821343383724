import * as React from 'react';

import { Box } from '@mui/material';

type MouseDragTrackerProps = {
    children: React.ReactNode;
    defaultPosition?: { x: number, y: number }
}

export default function MouseDragTracker({ children, defaultPosition } : MouseDragTrackerProps) : JSX.Element
{
    const [dragPosition, setDragPosition] = React.useState(defaultPosition || { x: 0, y: 0 });
    const ref = React.useRef<HTMLDivElement>(null);

    function handleMouseMove (event: MouseEvent) 
    {
        const { clientX, clientY } = event;

        const rect = ref.current?.getBoundingClientRect() || new DOMRect();

        const xOffSet = rect.width / 2;
        const yOffSet = rect.width / 8;

        setDragPosition({ x: clientX - xOffSet, y: clientY - yOffSet });
    };


    React.useEffect(() => 
    {
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("drag", handleMouseMove);

        return function cleanup() {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("drag", handleMouseMove);
        };
    });

    return (
        <Box
            ref = {ref}
            sx = {{
                position: "fixed",
                backgroundColor: "transparent",
                border: 'solid 1px rgba(255, 255, 255, 0.2)',
                borderRadius: '0.5rem',
                padding: '0.1rem',
                zIndex: 1000,
                pointerEvents : "none",
                left: dragPosition.x,
                top: dragPosition.y,
                cursor: 'grabbing',
            }}
        >
            { children }
        </Box>
    );
}