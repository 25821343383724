import * as React from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../../App/Store';
import { Box, Typography } from '@mui/material';

export default function WellcomeWidget()
{
    const { t } = useTranslation();
    
    const userType = useSelector((rootState : RootState) => rootState.User.userType) || '';

    const wellcomeText = t('home_view.welcome.text');

    const wellcomeSubText = 
        t('home_view.welcome.sub_text')
            .replace('$userType$', t(`user.type.${userType}`).toLocaleLowerCase());

    return (
        <Box
            sx = {{
                marginY: '1rem'
            }}
        >
            <Typography variant='h4' component='h3' paddingY='0.2rem'>
                { wellcomeText }
            </Typography>
            <Typography variant='body1' color='text.secondary'>
                { wellcomeSubText }
            </Typography>
        </Box>
        
    );
}