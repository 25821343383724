import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    Alert,
    Avatar,
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Snackbar,
    Stack,
    TextField
} from '@mui/material';

import { 
    AccountCircle as AccountCircleIcon, 
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';

import QuerryErrorSnackbarAlert from '../../Components/Shared/QuerryErrorAlert';

import { Users } from '../../../App/API/Users';
import Logger from '../../../App/Framework/Utils/Logger';
import { userSlice } from '../../../App/Store/User/UserSlice';

import { useTranslation } from 'react-i18next';

const SNACKBAR_AUTOHIDE_DURATION = 6000;

const DEFAULT_CREDENTIALS = { email: '', password: '' };

export default function CredentialsLoginView() : JSX.Element
{
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [ tryLogin, { isLoading, data, error } ] = Users.useTryLoginCredsMutation();

    const [ crendentials, setCrendentials ] = React.useState(DEFAULT_CREDENTIALS);
    const [showPassword, setShowPassord] = React.useState(false);

    const [t] = useTranslation();

    async function OnClickLogin()
    {
        try {
            const response = await tryLogin(crendentials).unwrap();

            dispatch(userSlice.Actions.setLogin({
                loggedIn : true,
                userType : response.userType,
                token : response.token
            }));
    
            const afterAuthRedirectUrl = sessionStorage.getItem('after_auth_redirect');
            if (afterAuthRedirectUrl != null) {
                sessionStorage.removeItem('after_auth_redirect');
                navigate(afterAuthRedirectUrl);
            }
            else
                navigate('/');
        }
        catch(err) 
        {
            Logger.error('While tryLogin', err);
        }
    }

    return (
        <>
            <>
                <Snackbar open = { isLoading } autoHideDuration = {SNACKBAR_AUTOHIDE_DURATION} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                    <Alert severity='info'>Loading ...</Alert> 
                </Snackbar>
                <QuerryErrorSnackbarAlert error={error}/>
                <Snackbar open = { !!data } autoHideDuration = {SNACKBAR_AUTOHIDE_DURATION} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                    <Alert severity='success'>Logged in as { data?.userType }</Alert>
                </Snackbar>
            </>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing = { 2 }
            >
                <Avatar
                    sx={{ width: 'fit-content', height: 'fit-content' }}
                >
                    <AccountCircleIcon sx={{ width: '10rem', height: '10rem' }}/>
                </Avatar>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing = { 2 }
                    onKeyDown={ e => { if(e.key === 'Enter') OnClickLogin() } }
                >   
                    <TextField
                        sx = {{ width: '100%' }}
                        required
                        id="email"
                        label={t('auth.login.email')}
                        type='email'
                        defaultValue = { crendentials.email }
                        onChange={ (e) => setCrendentials({ email: e.target.value, password: crendentials.password }) }
                    />
                    <FormControl>
                        <InputLabel htmlFor="password">{t('auth.login.password')}</InputLabel>
                        <OutlinedInput
                            id="password"
                            defaultValue={ crendentials.password }
                            onChange={ (e) => setCrendentials({ email: crendentials.email, password: e.target.value }) }
                            type={showPassword ? 'text' : 'password'}
                            endAdornment = {
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick = { () => setShowPassord(!showPassword) }
                                        onMouseDown = { (e) => e.preventDefault() }
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label={t('auth.login.password')}
                        />
                    </FormControl>

                    <Button
                        type='submit'
                        disabled={isLoading}
                        variant='contained'
                        onClick={ OnClickLogin }
                    >
                        {t('auth.login.login')}
                    </Button>
                </Stack>
            </Stack>
        </>
    );
}