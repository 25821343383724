// Auth methods images should be 200x200 or at least a square

import UniversityImg from '../../assets/images/universite-bordeaux.jpg';
import DevCredLoginImg from '../../assets/images/dev-cred-login.png';

import { To } from "react-router-dom";
import EnvParser from '../../../App/Framework/Utils/EnvParser';
import { TFunction } from 'i18next';

export type AuthMethod = {
    img?: string,
    title: string,
    url: To,
}

function BuildAuthMethods(t: TFunction)
{
    const authMethods: AuthMethod[] = [];

    const authMethodsKeys = Object.keys(EnvParser.SSOAuthConfigs);

    if(process.env.REACT_APP_ENABLE_CRED_LOGIN === 'true')
    {
        authMethods.push({
            img: DevCredLoginImg,
            title: t('auth.login.credentials_login'),
            url : '/auth/cred-login'
        });
    }
    
    if(authMethodsKeys.includes('UBX'))
    {
        authMethods.push({
            img: UniversityImg,
            title: 'Université de Bordeaux',
            url : '/SSO_UBX'
        });
    }
    
    return authMethods;
}

function AuthMethods(t: TFunction): AuthMethod[] {
    return [
        ...BuildAuthMethods(t),
    ];
}


export default AuthMethods;