import { CreateExperimentState } from "./CreateExperimentSlice";

import { 
    GroupName, 
    ScenarioGroup, 
    ScenarioId, 
    Student, 
    StudentGroup, 
    StudentId 
} from "./Types";

export function Selectors(state: CreateExperimentState) 
{
    return {
        getActiveStep: () => 
            Object.values(state).find(step => step.active),
    
        getStudentById: (id: StudentId) : Student => 
            state.classStep.students[id],

        getGroupByName: (name: GroupName) : StudentGroup | undefined => 
            state.groupsStep.studentGroups[name],

        getActiveScenariosByGroupName: (name: GroupName) : ScenarioGroup | undefined => 
            Object.values(state.scenarioStep.activeScenarios)
                .find(group => group.groupsInScenario.includes(name)),

        getActiveScenariosByStudentId: (id: StudentId) : ScenarioGroup | undefined => 
                Object.values(state.scenarioStep.activeScenarios)
                    .find(scenario => scenario.studentsInScenario.includes(id)),

        getStudentGroupByStudentId: (id: StudentId) : StudentGroup | undefined => 
            Object.values(state.groupsStep.studentGroups)
                .find(group => group.studentsIds.includes(id)),

        getStudentGroupIndexByStudentId: (id: StudentId) : number => 
            Object.values(state.groupsStep.studentGroups)
                .findIndex(group => group.studentsIds.includes(id)),
        
        isStudentInAGroup: (id: StudentId) : boolean => 
            state.groupsStep.needToBeGroupedStudents.includes(id),

        isScenarioActive: (scenarioId: ScenarioId) : boolean => 
            state.scenarioStep.activeScenarios[scenarioId] !== undefined,

        getActiveScenarioByScenarioId: (scenarioId: ScenarioId) : ScenarioGroup | undefined => 
            state.scenarioStep.activeScenarios[scenarioId],

        isGroupInAScenario: (groupName: GroupName) : boolean => 
            Object.values(state.scenarioStep.activeScenarios)
                .some(scenario => scenario.groupsInScenario.includes(groupName)),

        isStudentInAScenario: (studentId: StudentId) : boolean => 
            Object.values(state.scenarioStep.activeScenarios)
                .some(scenario => scenario.studentsInScenario.includes(studentId)),
    }
}