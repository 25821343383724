import * as React from 'react';

import { useTranslation } from 'react-i18next';

import {
    Grid, 
    Stack,
    Typography,
    lighten
} from '@mui/material';

import {
    East as EastIcon
} from '@mui/icons-material';

import DateFormat from '../../../App/Framework/Utils/DateFormat';

import { Scenario } from '../../../App/Store/SimpleCreateExperiment/Types';
import { Experimentation } from '../../../App/Store/Experiment/Types';

type HeaderProps = { 
    scenario: Scenario | undefined,
    experiment: Experimentation | undefined
};

export default function ExperimentHeader({ scenario, experiment } : HeaderProps) : JSX.Element
{
    const { t, i18n } = useTranslation();

    const startDate = DateFormat.fromString(experiment?.startDate || '', i18n, {
        day: "numeric",
        month: 'long',
        year: 'numeric',
    });

    const endDate = DateFormat.fromString(experiment?.endDate || '', i18n, {
        day: "numeric",
        month: 'long',
        year: 'numeric',
    });

    return (
        <>
        {
            (scenario && experiment) &&
            <Grid
                container
                direction = "row"
                alignItems='center'
                sx = {{
                    backgroundColor: theme => lighten(theme.palette.background.paper, 0.3),
                    my: '1rem'
                }}
            >
                <Grid 
                    item
                    sx = {{
                        backgroundColor: scenario?.colorCode,
                        paddingY: '0.6rem',
                        paddingX: '1rem',
                    }}
                >
                    {
                        scenario?.category &&
                        <Typography
                            variant='body1'
                            component = 'span'
                            fontWeight = 'bold'
                            fontSize = '18pt'
                        >
                            { t(`scenarios.categories.${scenario?.category}`) + ' ' }
                        </Typography>
                    }
                    <Typography
                        variant='body2'
                        component='span'
                        fontSize = '13pt'
                    >
                        { t(`scenarios.names.${scenario?.applicationRef}`) as string }
                    </Typography>
                </Grid>
                
                <Grid
                    item
                    sx = {{
                        marginX: '1rem'
                    }}
                >
                    <Typography variant='h6'  component='p'>
                        { experiment.schoolClassName }
                    </Typography>
                </Grid>
                    
                <Grid
                    item
                >
                    <Stack
                        direction = {{ xs: 'column', sm: 'row' }}
                        alignItems = {{ xs: 'center', sm: undefined }}
                        spacing = { 1 }
                    >
                        <Typography variant='body2'>
                            { startDate }
                        </Typography>

                        {
                            experiment?.endDate &&
                            <Stack direction='row' alignItems='center' spacing={ 1 }>
                                <EastIcon />
                                <Typography variant='body2'>{ endDate }</Typography>
                            </Stack>
                        }
                    </Stack>
                </Grid>
                
            </Grid>
        }
        </>
    );
}