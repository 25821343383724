import * as React from 'react';

import {
    Container,
    Stack,
} from '@mui/material';

import BackButton from '../../../Components/Shared/BackButton';

import ExperimentStudentDetailsHeader from './ExperimentStudentDetailsHeader';

import { DownloadStatsButton } from '../DownloadStats';

import { StudentExperimentProgress, TeacherExperimentProgress } from '../../../../App/API/Experiments';
import { Scenario, ScenarioStep } from '../../../../App/Store/SimpleCreateExperiment/Types';

import ExperimentStudentDetailsTimeLine from './ExperimentStudentDetailsTimeLine';

type ExperiementStudentDetailsContentProps = {
    student: TeacherExperimentProgress['Student'],
    experimentProgress: {
        scenario: Scenario;
        steps: ScenarioStep[];
        progress: StudentExperimentProgress | TeacherExperimentProgress;
    },
    experimentId: number
}

export default function ExperiementStudentDetailsContent({ student, experimentProgress, experimentId } : ExperiementStudentDetailsContentProps)
{
    return (
        <>
            <Container maxWidth='lg'>
                <BackButton />
            </Container>
            <Container maxWidth='xl'>
                <ExperimentStudentDetailsHeader
                    scenario = { experimentProgress.scenario }
                    student = { student }
                />
            </Container>
            <Container>
                <Stack
                    direction='row-reverse'
                >
                    {
                        experimentProgress?.progress?.completed &&
                        <DownloadStatsButton 
                            student = { student }
                            experimentId = { experimentId }
                        />
                    }
                </Stack>

                <ExperimentStudentDetailsTimeLine
                    experimentProgress={ experimentProgress }
                />
            </Container>
        </>
    )
}