import * as React from 'react';

import { 
    Box, 
    Typography 
} from '@mui/material';

type GenericErrorPageProps = {
    title: string,
    message?: string
};

export default function GenericErrorPage({ title, message } : GenericErrorPageProps)
{
    return (
        <Box 
            sx = {{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Box>
                <Typography variant = 'h2' align='center'>{ title }</Typography>
                <Typography variant = 'body1' color = 'text.secondary' align='center'>{ message }</Typography>
            </Box>
        </Box>
    );
}