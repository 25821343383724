import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { 
    FormControlLabel, 
    Paper, 
    Switch, 
    Typography 
} from '@mui/material';

import { RootState } from '../../../../App/Store';
import { createExperimentSlice } from '../../../../App/Store/CreateExpriment';

type CollabModePorps = {
    title: string,
    content: string,
    OnClick?: (state: boolean) => void
};

export default function CollabMode({ title, content, OnClick } : CollabModePorps) : JSX.Element
{
    const dispatch = useDispatch();

    const collabModeEnabled = useSelector((rootState : RootState) => rootState.CreateExperiment.classStep.collabMode);

    function OnClickCheck()
    {
        if(OnClick) OnClick(!collabModeEnabled);
        dispatch(createExperimentSlice.Actions.setCollabMode(!collabModeEnabled));
    }

    // TODO : translation
    const collabModeEnabledTxt = "Mode collaboration activé" //t('createExperiment.collabMode.enabled');
    const collabModeDisabledTxt = "Mode collaboration désactivé" //t('createExperiment.collabMode.disabled');

    return (
        <Paper
            sx= {{
                padding: '1rem',
            }}
        >
            <Typography 
                variant='h5'
                sx = {{
                    marginBottom: '0.3rem'
                }}
            >
                { title }
            </Typography>
            <Typography 
                variant='body2'
            >
                { content }
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tempor sem turpis, at tristique est tempus id. Aliquam ac orci in felis vulputate sollicitudin. In in vehicula erat. Duis dapibus quam quis risus varius placerat. Nunc vehicula a lectus at mattis. Duis eleifend viverra lectus, sed lacinia justo feugiat ac. Donec quis lorem id velit tristique pellentesque pretium in metus. Etiam eleifend nibh ut laoreet hendrerit.  
            </Typography>

            <Paper
                square = { false }
                variant="outlined"
                sx = {{
                    marginTop: '1rem',
                    maxWidth: 'fit-content',
                    paddingY: '0.3rem',
                    paddingX: '0.7rem',
                    backgroundColor: collabModeEnabled ? 'rgb(0, 184, 81)' : undefined,
                    borderRadius: '2rem',
                    userSelect: 'none'
                }}
            >
                <FormControlLabel
                    control = {
                        <Switch
                            checked = { collabModeEnabled }
                            onChange = { OnClickCheck }
                            value = "checked"
                            color = "default"
                        />
                    }
                    labelPlacement = "end"
                    label = { collabModeEnabled ? collabModeEnabledTxt : collabModeDisabledTxt }
                />
            </Paper>

        </Paper>
    );
}