import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";


type ErrorMessageBoxProps = {
    error?: boolean,
    errorMessage?: string,
    fitContent?: boolean
    children?: ReactNode
};

export default function ErrorMessageBox({ error, errorMessage, fitContent, children } : ErrorMessageBoxProps) : JSX.Element
{
    return (
        <>
            <Typography variant='body2' sx = {{ paddingY: '0.3rem', color: 'red' }}>{ error ? errorMessage : '' }</Typography>
            <Box 
                sx = {{
                    padding: '0.3rem',
                    border : error ? 'red 1px solid' : undefined,
                    borderRadius: error ? '4px' : undefined,
                    maxWidth: fitContent ? 'fit-content' : undefined
                }}
            >
                { children }    
            </Box>
        </>
    );
}