import * as React from 'react';

import {  
    Box,
    Button,
    Container, 
    Typography
} from '@mui/material';

import {
    School as SchoolIcon
} from '@mui/icons-material'

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { createExperimentSlice } from '../../../../App/Store/CreateExpriment';
import { ClassConfig, dummyClass, dummyClasses } from '../../../../App/Store/CreateExpriment/dummyData';

import { 
    SelectOptionItemConfig, 
    DarkSelectOptionWithIcon 
} from '../../../../Features/Components/Shared/SelectOptionWithIcon';

import CreateExperimentationStepper from "../Stepper";
import { RootState } from '../../../../App/Store';
import CollabMode from './CollabMode';
import ErrorMessageBox from '../../../Components/Shared/ErrorMessageBox';

export default function ClassStep() : JSX.Element 
{
    const [ t ] = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const classButtonsConfig : SelectOptionItemConfig[] = dummyClasses.map(aClass => 
    ({ 
        icon: <SchoolIcon/>,
        text : aClass.name,
        onClick: () => OnClickClass(aClass)
    }));

    function OnClickClassButton(open : boolean, setOpen : (op : boolean) => void) : void
    {
        if(!open) setOpen(true);
    }

    function OnClickClass(classConfig : ClassConfig) : void
    {
        setClassError(false);
        dispatch(createExperimentSlice.Actions.setStudents(Object.values(dummyClass)));
        dispatch(createExperimentSlice.Actions.setClass(classConfig));
    }

    const [ classError, setClassError ] = React.useState(false);

    const selectedClass = useSelector((rootState : RootState) => rootState.CreateExperiment.classStep.className);
    const collabModeEnabled = useSelector((rootState : RootState) => rootState.CreateExperiment.classStep.collabMode);

    function classValidator(_selectedClass? : string) : boolean { return !!(_selectedClass && _selectedClass !== ''); }

    function OnClickNext()
    {
        if(classValidator(selectedClass))
        {
            setClassError(false);
            dispatch(createExperimentSlice.Actions.setStepFinished('classStep'));

            if(collabModeEnabled)
            {
                dispatch(createExperimentSlice.Actions.setStepReachable('groupsStep'));
                dispatch(createExperimentSlice.Actions.setStepActive('groupsStep'));
                navigate('/experimentations/create-advanced/configure-groups');
            }
            else
            {
                dispatch(createExperimentSlice.Actions.setStepReachable('scenarioStep'));
                dispatch(createExperimentSlice.Actions.setStepActive('scenarioStep'));
                navigate('/experimentations/create-advanced/create-scenerios');
            }
        }
        else
        {
            setClassError(true);
        }
    }

    return (
        <>
            <Container maxWidth='sm'>
                <CreateExperimentationStepper />
            </Container>

            <Container 
                maxWidth="xl"
            >
                <Box
                >
                    <Typography variant="h5" gutterBottom
                        sx={{
                            textAlign: 'left',
                            marginBottom: '1rem'
                        }}
                    >
                        { t('experimentation.classStep.classChoice.description') }
                    </Typography>
                    
                    <ErrorMessageBox
                        error = { classError }
                        errorMessage = { t('experimentation.classStep.classChoice.noClassSelectedError') as string }
                    >
                        <DarkSelectOptionWithIcon 
                            placeholderIcon = { <SchoolIcon/> }
                            placeholder = { t('experimentation.classStep.classChoice.buttonPlaceHolder') as string }
                            buttonGroupAriaLabel = ''
                            buttonAriaLabel = ''
                            onClick = { OnClickClassButton }
                            itemsConfigs={ classButtonsConfig }
                            activeItemKey = { selectedClass }
                        />
                    </ErrorMessageBox>
                </Box>
                
                <Box
                    sx = {{
                        marginTop: "4rem"
                    }}
                >
                    <CollabMode 
                        title = "Titre"
                        content = ""
                        OnClick = { (state : boolean) => {} }
                    />
                </Box>

                <Box sx= {{
                    display: 'flex',
                    my: '5rem'
                }}>
                    <Box sx = {{ mx: 'auto' }}>
                        <Button 
                            onClick={ OnClickNext }
                            variant="contained"
                        >
                            { t('experimentation.classStep.createButton') }
                        </Button>
                    </Box>
                </Box>

            </Container>
        </>
    );
}

    /* 
    <Typography variant="h5" component="h2" gutterBottom
        sx={{
            marginTop: '1rem'
        }}
    >
        { t('experimentation.classStep.title') }
    </Typography>
    
    <Typography variant="body2" gutterBottom
        sx={{
            marginTop: '2rem'
        }}
    >
        { t('experimentation.classStep.description') }
    </Typography>
    */