import React from 'react';
import { useTranslation } from 'react-i18next'

import { Button, ButtonProps, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import LanguageSelector from './LanguageSelector';
import { styled } from '@mui/material/styles';

interface LanguageButtonProps extends ButtonProps
{
    open: boolean;
}

const LanguageButton = 
    styled(Button)<LanguageButtonProps>
    (({ theme, open }) => ({
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
    }));

function LanguageButtonA({ languageSelector } : { languageSelector: LanguageSelector }) : JSX.Element
{
    const endIcon = languageSelector.open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    const ariaControls = languageSelector.open ? 'language-selection-menu' : undefined;
    
    return (
        <ButtonGroup variant="contained" ref = { languageSelector.buttonAnchor }>
            <LanguageButton 
                open = { languageSelector.open }
                startIcon = { <LanguageIcon></LanguageIcon> } 
                endIcon = { endIcon }
                aria-controls = { ariaControls }
                aria-expanded = { languageSelector.open }
                aria-haspopup = "menu"
                onClick = { () => languageSelector.handleToggle() }
                color="primary"
            >
                {languageSelector && languageSelector.currentConfig && languageSelector.currentConfig.languageText }
            </LanguageButton>
        </ButtonGroup>
    )
}

export default function LanguageSelectorView() : JSX.Element
{
    const translations = useTranslation();

    const languageSelector = new LanguageSelector(
        {
            buttonAnchor : React.useRef<HTMLDivElement>(null),
            transations : translations,
            openState : React.useState(false),
            selectedState : React.useState(translations.i18n.language),
        }
    );

    function BuildPopper() : JSX.Element
    {
        return (
            <Popper
                open = { languageSelector.open }
                anchorEl = { languageSelector.buttonAnchor.current }
                role = { undefined }
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style = {{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway = { (event) => languageSelector.handleClose(event) }>
                                { BuildPopperMenuList() }
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        )
    }

    function BuildPopperMenuList() : JSX.Element
    {
        const entries : JSX.Element[] = [];

        for(const [ lang, translations ] of Object.entries(languageSelector.configurations))
        {
            entries.push(
                <MenuItem
                    key = { translations.languageText }
                    selected = { lang === languageSelector.selectedKey }
                    onClick = { () => languageSelector.handleChangeLanguage(lang, translations) }
                >
                    {translations.languageText}
                </MenuItem>
            );
        }

        return (
            <MenuList id="language-selection-menu" autoFocusItem>
                {entries}
            </MenuList>
        );
        
    }

    return (
        <>
            <LanguageButtonA
                languageSelector={languageSelector}
            />
            { BuildPopper() }
        </>
    );
}