import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';

import { RootState } from '../../../../App/Store';
import { MarginButtonStyle } from './StudentsView';
import { Class } from '../../../../App/Store/SimpleCreateExperiment/Types';
import { Classes } from '../../../../App/API/Classes';

type RemoveStudentsButtonProps = {
    selectedClass: Class,
    selectedStudentsIds: number[],
    buttonStyle: MarginButtonStyle,
    buttonEnabled: boolean
};

export default function RemoveStudentsButton({ selectedClass, selectedStudentsIds, buttonStyle, buttonEnabled }: RemoveStudentsButtonProps): JSX.Element {

    const [popupOpened, setPopupOpened] = React.useState(false);
    const [t] = useTranslation();
    const token = useSelector((state: RootState) => state.User.token);
    const [removeStudentsRequest] = Classes.useRemoveStudentsMutation();

    async function removeStudents() {
        await removeStudentsRequest({
            token,
            classId: selectedClass.id,
            studentsIds: selectedStudentsIds
        }).unwrap();
    }

    return (
        <>
            <Button
                variant='contained'
                onClick={() => setPopupOpened(true)}
                color='error'
                sx={buttonStyle}
                disabled={!buttonEnabled}
            >
                {t('teacher.classes_view.list.manage.remove_students')}
            </Button>
            <Dialog
                open={popupOpened}
                keepMounted
                onClose={() => setPopupOpened(false)}
            >
                <DialogTitle> {t('teacher.classes_view.list.manage.remove_students')} </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        color='text.primary'
                    >
                        {t('teacher.classes_view.list.manage.remove_students_desc')}
                    </DialogContentText>

                    <DialogActions>
                        <Button
                            onClick={
                                () => {
                                    setPopupOpened(false);
                                    removeStudents();
                                }
                            }
                            variant='contained'
                            color='error'
                        >
                            {t('teacher.classes_view.list.manage.remove_students_button_validate')}
                        </Button>
                        <Button
                            onClick={() => setPopupOpened(false)}
                            variant='contained'
                            color='info'
                        >
                            {t('teacher.classes_view.list.manage.remove_students_button_cancel')}
                        </Button>
                    </DialogActions>

                </DialogContent>
            </Dialog>
        </>
    );
}