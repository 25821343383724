import { IdentitiesGenerator, Identity } from "./IdentitiesGenerator";

export type IdentifiableClassType = typeof IdentifiableClass

export default abstract class IdentifiableClass
{
    protected readonly _identity: Identity;
    public get identity() : Identity { return this._identity; }

    public constructor()
    {
        this._identity = IdentitiesGenerator.GetIdentityOf(this.constructor as IdentifiableClassType)
    }

    public static getClassIdentity(childClass: IdentifiableClassType): Identity
    {
        return IdentitiesGenerator.GetIdentityOf(childClass)
    }
}