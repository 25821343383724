import React from 'react';

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'

import { ThemeProvider } from '@emotion/react';

import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend'

import InitTranslationSystem from './Language/InitTranslationSystem';

import { theme } from './Themes';
import { Store } from './App/Store';
import App from './App';

const root = createRoot(document.getElementById('root')!);

InitTranslationSystem();

root.render(
	<React.StrictMode>
		<Provider store = { Store }>
			<ThemeProvider theme = { theme }>
				<BrowserRouter>
					<DndProvider
						backend={ TouchBackend } 
						options = {{
							enableTouchEvents: true,
							enableMouseEvents: true,
							ignoreContextMenu : true
						}}>
						<App />
					</DndProvider>
				</BrowserRouter>
			</ThemeProvider>
		</Provider>
	</React.StrictMode>
);

//reportWebVitals((...args: any) => Logger.info('[WebVitals]', ...args));