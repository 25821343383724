import { Container, Stack } from '@mui/material';
import CreateExperimentationStepper from '../Stepper';

export default function EndStepView() : JSX.Element
{
    return (
        <>
            <Container maxWidth='sm'>
                <CreateExperimentationStepper />
            </Container>
            <Container maxWidth="xl">
                <Stack 
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={ 4 }
                    sx={{
                        my: "2rem"
                    }}
                >
                    {
                        // Container de à gauche le scénario & à droite le container de groupes / étudiants
                        // Le container a une hauteur max
                        // Le design de benjamin n'est pas adapter au téléphone
                    }
                </Stack>
            </Container>
        </>
    );
}
