import * as React from 'react';

import { 
    Grid,
    SxProps, 
    Theme,
} from '@mui/material';

import ExperimentationItem from './ExperimentationItem';
import { Scenario } from '../../../App/Store/SimpleCreateExperiment/Types';
import { Experimentation } from '../../../App/Store/Experiment/Types';

type ExperiementationsGridProps = {
    experimentations: Experimentation[],
    expItemButtonText: string,
    OnClickExpItemButton: (experimentation: Experimentation, scenario?: Scenario) => void,
    
    experimentDisplayValidator?: (experimentation: Experimentation) => boolean,
    isStudent? : boolean,
    sx?: SxProps<Theme>,
}

export default function ExperimentationsGrid({ experimentations, expItemButtonText, OnClickExpItemButton, experimentDisplayValidator, isStudent, sx } : ExperiementationsGridProps) : JSX.Element
{  
    const elements : JSX.Element[] = [];

    for(const experimentation of experimentations)
    {
        if(experimentDisplayValidator && !experimentDisplayValidator(experimentation))
            continue;

        elements.push(
            <ExperimentationItem
                key = { experimentation.id }
                experimentation = { experimentation }
                buttonText = { expItemButtonText }
                OnClickButton = { OnClickExpItemButton }
                isStudent = { isStudent }
            />
        )
    }

    return (
        <Grid container spacing = { 2 } sx = { sx }> 
            { elements } 
        </Grid>
    );
}