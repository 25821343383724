import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery(
{
    baseUrl: process.env.REACT_APP_URL_API,

    /*
    // TODO : Implement Bearer token in headers in back instead of passing it in the body, 
    // then we can uncomment this, remove `token` from DefaultPostRequest and remove store calls from all the components using post requests with a token
    prepareHeaders: (headers, { getState }) => 
    {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = (getState() as RootState).auth.token
        if (token) {
            headers.set('authentication', `Bearer ${token}`)
        }
        return headers
    },
    */
});

/** AppTags represents the different tags used in the API
 * When a tag is added to this enum, it will be automatically added to the API tagTypes.
 * A AppTag refer to a group of endpoints, when you set `providesTags` value in an endpoint, it will be added to the tag you specified.
 * 
 * This means that when you add `invalidateTags` on a tag, all the endpoints that have `providesTags` set to this tag will be invalidated.
 * Invalidating a tag means that the data will be refetched the next time you call the endpoint.
 * 
 * You must use `invalidateTags` and `providesTags` with care because it can lead to a lot of useless refetching.
 * Most of the time `invalidateTags` is set on `mutation` type of endpoint and `providesTags` is set on `query` type of endpoint (because mutations change data in backend most of the time and querry don't).
 * 
 * To have good exemples of how to use `providesTags` and `invalidateTags`, see the `Experiments` endpoints and the others endpoints that use `providesTags` and `invalidateTags`. 
 */
export enum ApiTags {
    Criteria = 'Criteria',
    Experiments = 'Experiments',
    Scenarios = 'Scenarios',
    User = 'User',
    Classes = 'Classes'

}
/** AllApiTags is an array of all `ApiTag` available in the API 
 * To invalidate all thoses tags manually you can use `dispatch` on `API.util.invalidateTags` 
 * like : 
 * ```ts
 * function MyConomponent()
 * {
 *      const dispatch = useDispatch();
 *      const invalidateAction () => dispatch(API.util.invalidateTags(AllApiTags));
 *      ...
 * }
 * ```
 */
export const AllApiTags = Object.values(ApiTags);

export const API = createApi({
    reducerPath: 'api',
    baseQuery,
    tagTypes: AllApiTags,
    endpoints: () => ({})
});

export type DefaultPostRequest = 
{
    // TODO : Remove token from here when the back will be refactored (refer to comment above in baseQuery for more details)
    token: string,
};