import * as React from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../App/Store';

import {
    Container,
    Typography
} from '@mui/material';

import { UnityAppPlayer } from '../../Components/UnityApp';
import ExperimentationsGrid from '../../Components/ExperimentationsGrid/ExperimentationsGrid';

import QuerryErrorSnackbarAlert from '../../Components/Shared/QuerryErrorAlert';
import LoaderCircularProgress from '../../Components/Shared/LoaderCircularProgress';
import ReloadOnNavigate from '../../Components/Shared/ReloadOnNavigate';

import { Experiments } from '../../../App/API/Experiments';

import { Scenario } from '../../../App/Store/SimpleCreateExperiment/Types';
import { Experimentation } from '../../../App/Store/Experiment/Types';
import Logger from '../../../App/Framework/Utils/Logger';

export default function StudentPlayView()
{
    const { t } = useTranslation();

    const userType = useSelector((state: RootState) => state.User.userType);
    const token = useSelector((state: RootState) => state.User.token);

    // Requests hooks
    const { data: activeExp = [], isLoading: activeIsLoading, error: activeError } = Experiments.useStudentActiveExpriementationsQuery({ token });
    const { data: progresses = [], isLoading : progressesIsLoading, error: progressesError } = Experiments.useStudentGetExperimentProgressesQuery({ token });

    const [ scenario, setSelectedScenario ] = React.useState<Scenario | null>(null);
    const [ experimentation, setExperimentation ] = React.useState<Experimentation | null>(null);

    const IsReady = () => scenario !== null && experimentation !== null
    const IsLoaded = () => !activeIsLoading && !activeError && !progressesIsLoading && !progressesError

    function OnClickExpItemButton(selectedExperimentation: Experimentation, selectedScenario?: Scenario)
    {
        if(!selectedScenario)
        {
            Logger.error('No scenario found for experimentation when trying to set currentScenario, StudentPlayView need a scenario to work properly', experimentation);
            return;
        }

        setExperimentation(selectedExperimentation);
        setSelectedScenario(selectedScenario);
    }

    function ExperimentDisplayValidator(experimentation: Experimentation) : boolean
    {
        const progress = progresses.find(progress => progress.ExperimentId === experimentation.id);
        return !progress?.completed
    }

    return (
        <Container maxWidth='xl'>
            <QuerryErrorSnackbarAlert error = { activeError } />
            <QuerryErrorSnackbarAlert error = { progressesError } />
            <Typography variant='h4' component='h2'
                sx = {{
                    marginBottom: '2rem'
                }}
            >
                { t('student_play.experimentation_choice.title') }
            </Typography>
            
            <LoaderCircularProgress display = { !IsLoaded() } />
            {
                (!IsReady() && IsLoaded()) && 
                (
                    (activeExp.length > 0)
                    ? (
                        <ExperimentationsGrid 
                            experimentations = { activeExp }
                            expItemButtonText = { t('student_play.experimentation_choice.select_button') }
                            OnClickExpItemButton = { OnClickExpItemButton }
                            experimentDisplayValidator={ ExperimentDisplayValidator }
                            isStudent = { userType === 'student' }
                        />
                    ) 
                    : (
                        <Typography>{ t('student_play.experimentation_choice.no_experiment') }</Typography>
                    )
                )
            }
            {
                IsReady() &&
                <ReloadOnNavigate
                    onRedirected = { () => { console.error('ReloadOnNavigate'); } }
                >
                    <UnityAppPlayer
                        scenarioRef = { (scenario as Scenario).applicationRef }
                        exprimentId = { (experimentation as Experimentation).id }
                        skipPreparationSteps = { (experimentation as Experimentation).skipPreparationSteps }
                        downloadOnEnd = { false }
                        trackProgress = { true }
                    />
                </ReloadOnNavigate>
            }
        </Container>
    );
}