export default class Hostinfo 
{
    private constructor() {}

    public static GetHostName() : string
    {
        return window.location.hostname;
    }

    public static GetHostUrl() : string
    {
        return window.location.origin;
    }
}