import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { 
    Button, 
    Grid,
    Paper, 
    lighten
} from '@mui/material';

import laboratoryIllustrationImage from '../../assets/images/laboratory-illustration.png';

export default function PlayExperimentationWidget()
{
    const { t } = useTranslation();
    const navigate = useNavigate();

    function OnClickPlayExperiment()
    {
        navigate('/experimentations/play');
    }

    return (
        <Grid
            container
            spacing={ 2 }
            marginBottom = "2rem"
        >
            <Grid
                item
                xs = { 12 }
                md = { 6 }
            >
                <Paper
                    sx = {{
                        padding: '0.5rem',
                        backgroundColor: (theme) => lighten(theme.palette.background.paper, 0.04),
                        ":hover" : {
                            backgroundColor: 'rgb(49, 68, 89)',
                            transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                            cursor: 'pointer'
                        }
                    }}
                    onClick = { OnClickPlayExperiment }
                >
                    <Paper
                        sx = {{
                            background: `url(${laboratoryIllustrationImage}) no-repeat`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '20rem',
                            marginY: '0.2rem',
                            
                        }}    
                    >
                    </Paper>
                    
                    <Button
                        onClick = { OnClickPlayExperiment }
                        variant='contained'
                        fullWidth
                    >
                        { t('navbar.experiment.play') }
                    </Button>
                </Paper>
            </Grid>
        </Grid>
    );
}