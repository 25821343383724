import * as React from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';


import OptionsModal from '../OptionsModal/OptionsModal';
import ChoiceContainer from './ChoiceContainer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../App/Store';

export default function OptionsChoice() : JSX.Element
{
    const [ t ] = useTranslation();

    const optionsValidated = useSelector((state : RootState) => state.SimpleCreateExperiment.options.modified);

    const [ isBrowserOpen, setBrowserOpen ] = React.useState(false);

    const BottomContent = (
        <Typography 
            variant='body2'
            fontWeight='bold'
            textAlign={'center'}
        >
            { t('experimentation.create.options.choice_validated') }
        </Typography>
    );
    
    return (
        <>
            <ChoiceContainer
                title = { t('experimentation.create.options.choice_title') }
                displayBottom = { optionsValidated }
                bottomContent = { BottomContent }

                bottomContentSelectedBoderColor='#779abe'

                isOnError = { false }
                errorMessage = { t('experimentation.create.options.choice_notValidated') as string }
            >
                <Box
                    sx = {{
                        paddingX: '1rem',
                        paddingY: '2rem',
                    }}
                >
                    <Button 
                        variant = 'contained' 
                        fullWidth
                        onClick = { () => setBrowserOpen(true) }
                    >
                        { t('experimentation.create.options.choice_button') }
                    </Button>
                </Box>
            </ChoiceContainer>
            <OptionsModal
                open = { isBrowserOpen }
                handleClose = { () => setBrowserOpen(false) }
            />
        </>
    );
}