import * as React from 'react';

import { List } from '@mui/material';

import { NavButtonConfig } from '../Config';
import NavigationButton from './NavigationButton';

type NavigationButtonProps = {
    readonly config: NavButtonConfig[]
};

export default function NavigationButtons({ config } : NavigationButtonProps): JSX.Element
{
    const buttons: JSX.Element[] = [];

    for(const button of config)
    {
        const newButton = 
        (
            <NavigationButton
                key = { button.name }
                button = {button}
            />
        );

        buttons.push(newButton);
    }

    return (<List>{buttons}</List>);
}