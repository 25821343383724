import { UserStorageMode } from "../../Store/User/UserSlice";

import Logger from "./Logger";

export type SSOAuthStorageMode = UserStorageMode;
export type SSOAuthMode = 'eppn' | 'gar';

export type SSOAuthEntry = {
    url: string,
    authMode: SSOAuthMode
    storageMode: SSOAuthStorageMode,
    display?: boolean,
}

export type SSOAuthConf = { [SSOName : string]: SSOAuthEntry }

export default class EnvParser
{
    public static get SSOAuthConfigs() : SSOAuthConf
    {
        if(!EnvParser._SSOAuthConfigs)
        {
            EnvParser._SSOAuthConfigs = EnvParser.ParseSSOAuth();
        }

        return EnvParser._SSOAuthConfigs;
    }

    private static _SSOAuthConfigs : SSOAuthConf | null = null;

    // Shape verification, check if object is defined and every `storageMode` is equal to 'local' || 'session' and check if `url` is a string
    private static ValidateSSOAuthJsonShape(json: unknown) : boolean
    {
        if(!json || typeof json === 'object') return false;

        const entries = Object.values(json as SSOAuthConf);

        const storagevalidator = (authMethod : SSOAuthEntry) => 
            authMethod.storageMode === 'local' 
            || authMethod.storageMode === 'session';

        const authModevalidator = (authMethod : SSOAuthEntry) => 
            authMethod.authMode === 'gar' 
            || authMethod.authMode === 'eppn';
        
        const urlValidator = (authMethod : SSOAuthEntry) => typeof authMethod.url === 'string';
        
        return entries.every(authMethod => storagevalidator(authMethod) && urlValidator(authMethod) && authModevalidator(authMethod));
    }

    private static ParseSSOAuth() : SSOAuthConf
    {
        try
        {
            if(!process.env?.REACT_APP_AUTH_SSO)
                throw new Error(`not defined, please define it in your .env file, it must be in a JSON object format \nexemple : REACT_APP_AUTH_SSO={ "METHOD_NAME": { url: string, display?: boolean, storageMode?: 'local' | 'session' } }`);
            
            const parsed = JSON.parse(process.env.REACT_APP_AUTH_SSO) as SSOAuthConf;
            
            if(EnvParser.ValidateSSOAuthJsonShape(parsed))
            {
                throw new Error(`not defined it the right format ! Please define it in your .env file, it must be in a JSON object format \nexemple : REACT_APP_AUTH_SSO={ "METHOD_NAME": { url: string, display?: boolean, storageMode?: 'local' | 'session' } }`);
            }
            
            return parsed;
        }
        catch (err)
        {
            Logger.error('Error while parsing env.REACT_APP_AUTH_SSO', 'ERR :', err, 'REACT_APP_AUTH_SSO:', process.env?.REACT_APP_AUTH_SSO);
            return {};
        }
    }
}