import * as React from "react";

import { useSelector } from "react-redux";
import { useDrag } from "react-dnd";

import { 
    ListItem, 
    ListItemButton, 
    ListItemText, 
    SxProps,
    Theme
} from "@mui/material";

import { RootState } from "../../../../../../App/Store";

import { StudentId } from "../../../../../../App/Store/CreateExpriment/Types";
import { CreateExperimentSlice } from "../../../../../../App/Store/CreateExpriment";
import { DragableItemTypes } from "../../../Constants";
import MouseDragTracker from "../../../../../Components/Shared/DragMouseTracker";

type StudentListItemProps = {
    studentId : StudentId,
    sx? : SxProps<Theme>
};

export default function StudentListItem({ studentId, sx } : StudentListItemProps)
{
    const CreateExperimentState = useSelector((state: RootState) => state.CreateExperiment);
    const student = CreateExperimentSlice.Selectors(CreateExperimentState).getStudentById(studentId);

    const [{ isDragging }, drag] = useDrag(() => 
    ({
        type: DragableItemTypes.StudentCard,
        item: student,
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    }));

    const item = (
        <ListItem 
            key = { studentId } 
            component = "div"
            disablePadding
        >
            <ListItemButton
                ref = { drag }
                sx = {{ 
                    py: '.15rem',
                    opacity: isDragging ? 0.5 : 1,
                    ...sx
                }}
            >
                <ListItemText
                    primary={`${student.firstName} ${student.lastName}`}
                />
            </ListItemButton>
        </ListItem>
    );

    const onDraggedItem = (
        <MouseDragTracker>
            { item }
        </MouseDragTracker>
    );

    return (
        isDragging ? onDraggedItem : item
    );
}