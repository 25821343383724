import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { 
    Button 
} from '@mui/material';

import { 
    KeyboardArrowLeft
} from '@mui/icons-material';


export default function BackButton() : JSX.Element
{
    const [ t ] = useTranslation();
    
    const navigate = useNavigate();

    function OnClick()
    {
        navigate(-1);
    }

    return (
        <Button
            onClick={ OnClick }
            startIcon = { <KeyboardArrowLeft /> }
            sx = {{ marginBottom: '1rem' }}
        >
            { t('navigation.back') }
        </Button>
    );
}