import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../App/Store';

import {
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';

import { 
    ExpandLess, 
    ExpandMore
} from '@mui/icons-material';

import { staticOverlaySlice } from '../../../../../App/Store/StaticOverlaySlice';

import { NavButtonConfig } from '../Config'
import NavigationButtonChildren from './NavigationButtonChildren';

type NavigationButtonProps = {
    readonly button: NavButtonConfig,
};


export default function NavigationButton({ button } : NavigationButtonProps, key : string) : JSX.Element
{
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const drawerOpen = useSelector<RootState, boolean>((state) => state.StaticOverlay.drawerOpen);

    const Actions = staticOverlaySlice.Actions;

    const OpenDrawer = () => dispatch(Actions.setDrawerOpen(true));
    const CloseDrawer = () => dispatch(Actions.setDrawerOpen(false));

    // TODO : Must be in store
    const [ open, setOpen ] = React.useState(false);

    const navigation : React.MouseEventHandler<HTMLDivElement> = (event) => 
    {
        if(!button.navigate)
            return;

        if(button.navigate.openOnNavigate)
        {
            if(!open) setOpen(true);
            OpenDrawer();
        }
        else if(button.navigate.closeOnNavigate)
        {
            if(open) setOpen(false);
            CloseDrawer();
        }
        
        if(!button.navigate.location) return;

        navigate(button.navigate.location);
    };

    return (
        <ListItem key={key} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
                onClick= { navigation }
                sx={{
                    minHeight: 48,
                    justifyContent: drawerOpen ? 'initial' : 'center',
                    pl: 2.5,
                    pr: 1,
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: drawerOpen ? 3 : 'auto',
                        justifyContent: 'center',
                    }}
                >
                    { button.icon }
                </ListItemIcon>

                <ListItemText primary={t(button.name)} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                
                <IconButton aria-label="open" onClick={(event) => { setOpen(!open); event.stopPropagation(); }}>
                    { button.childrend && drawerOpen ? (open ? <ExpandLess /> : <ExpandMore />) : <></>}
                </IconButton>

            </ListItemButton>
            {
                button.childrend 
                    ? <NavigationButtonChildren
                        open = { open }
                        buttons = { button.childrend }
                    /> 
                    : <></>
            }
        </ListItem>
    );
}