import * as React from "react";

import {
    Grid, 
    IconButton, 
    Paper, 
    Stack, 
    Typography 
} from "@mui/material";

import {
    Delete as DeleteIcon
} from '@mui/icons-material'

import GroupStudentList from "./GroupStudentList";
import { useDispatch } from "react-redux";
import { createExperimentSlice } from "../../../../../App/Store/CreateExpriment";

type GroupContainerProps = {
    groupName: string
};

export default function GroupContainer({ groupName } : GroupContainerProps) : JSX.Element
{
    const dispatch = useDispatch();

    function OnClickDelete()
    {
        dispatch(createExperimentSlice.Actions.removeGroup(groupName));
    }
    
    return (
        <Grid item xs={ 1 }>
            <Paper
                elevation={ 3 }
                sx={{
                    margin: '.5rem',
                    userSelect: 'none'
                }}
            >
                <Paper>
                    <Stack
                        direction = 'row'
                        alignItems = 'center'
                        justifyContent = 'space-between'
                        sx = {{
                            px: '.5rem',
                        }}
                    >
                        <Typography variant='body2'>{ groupName }</Typography>
                        
                        <IconButton onClick={ OnClickDelete }>
                            <DeleteIcon fontSize='small'/>
                        </IconButton>
                    </Stack>
                </Paper>

                <GroupStudentList groupName = { groupName } />
            </Paper>            
        </Grid>
    );
}