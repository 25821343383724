import * as React from 'react';

import { useLocation } from 'react-router-dom';

type ReloadOnNavigateProps = {
    children?: React.ReactNode,
    onRedirected?: () => void
};

export default function ReloadOnNavigate({ children, onRedirected } : ReloadOnNavigateProps) : JSX.Element
{
    const location = useLocation();

    React.useEffect(() => 
    {
        return () => 
        {
            if(onRedirected) onRedirected();
        }
    });

    return (
        <React.Fragment key={location.key}>
            { children }
        </React.Fragment>
    );
}