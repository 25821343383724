import * as React from 'react';

import { useSelector } from 'react-redux';

import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../../../App/Store';

import LoaderCircularProgress from '../../../Components/Shared/LoaderCircularProgress';

import { Experiments, StudentExperimentProgress, TeacherExperimentProgress } from '../../../../App/API/Experiments';
import { Scenario, ScenarioStep } from '../../../../App/Store/SimpleCreateExperiment/Types';

import { connectSocket } from '../../../../App/SocketSystem/SocketSystem';

import ExperiementStudentDetailsContent from './ExperiementStudentDetailsContent';

export default function ExperimentStudentDetails() : JSX.Element
{
    const navigate = useNavigate();

    // this all part is made for parsing the route parametters
    // because of the nature of react-router we can't have multiple parameters for the route `/experiment/{param}`
    // so we do the little trick of concatente to paramters when it is necessary
    // a teacher use this route with thoses params : `/experiment/expId_studentId`
    // and a student just like that (because he know his own id) : `/experiment/expId`
    // so when we are a teacher we split the result by `_`
    // this could maybe be avoided by using a global state (maybe in the store) how store the selected student, but i think this is more bugproof with this method
    const {
        experimentationId: experimentationIdOnlyParam,
        experimentationIdParam_studentIdParam = ''
    } = useParams();

    const  [ experimentationIdParam, studentIdParam ] = experimentationIdParam_studentIdParam.split('_');
    const experimentId = parseInt(experimentationIdOnlyParam || experimentationIdParam) || -1;
    // only used if it's the teacher who search for the student details
    const studentId = parseInt(studentIdParam);
    
    const { token, userType, firstName, lastName } = useSelector((state: RootState) => state.User);
    
    // This component can be rendered as a teacher or a student, so we do this little trick to choose the right querry to send 
    const getExpProgressQuery = userType === 'student'
        ? Experiments.useStudentGetExperimentProgressQuery
        : Experiments.useTeacherGetExperimentProgressesQuery;

    const { data: experimentProgressResponse, isLoading: isLoadingProgress, refetch : refetchExpProgress } = (getExpProgressQuery({
        token,
        experimentId: experimentId || -1
    }) as ReturnType<typeof Experiments.useStudentGetExperimentProgressQuery> | ReturnType<typeof Experiments.useTeacherGetExperimentProgressesQuery>);


    // This all part is made tu unify student expriment progress and Teacher experiment progress
    // This could be avoided by creating defrent routes for getting thoses data and plit thoses data from the backend and not the frontend
    const experimentProgress : { scenario: Scenario, steps: ScenarioStep[], progress: StudentExperimentProgress | TeacherExperimentProgress } 
        = { ...experimentProgressResponse } as Experiments.ExperimentProgressResponse || {};
        
    experimentProgress.progress = Array.isArray(experimentProgressResponse?.progresses)
        ? experimentProgressResponse?.progresses.find((p : TeacherExperimentProgress) => p.StudentId === studentId)
        : experimentProgressResponse?.progress;

    const student = (experimentProgress.progress as TeacherExperimentProgress)?.Student 
        || { id : experimentProgress?.progress?.StudentId, firstName, lastName };

    // Socket connect / cleaning and subscibe to the refresh envent to refetch the expProgress
    React.useEffect(() => 
    {
        const socket = connectSocket('experiment-view', token);

        socket.on('refresh', (experimentIdToRefresh) => 
        {
            // this could be choosed in the back to avoid not needed socket event
            if(experimentIdToRefresh === experimentId)
                refetchExpProgress();
        });
        
        return () => 
        {
            socket.disconnect();
        }
    }, [ token, experimentId, refetchExpProgress ]);

    if(experimentId === -1)
    {
        navigate('/experimentations/my-experimentations');
        return <></>;
    }

    return (
        <>
            <LoaderCircularProgress display = { isLoadingProgress } />
            <ExperiementStudentDetailsContent 
                student = { student }
                experimentProgress = { experimentProgress }
                experimentId = { experimentId }
            />
        </>
    )
}