import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Grow,
    IconButton,
} from '@mui/material';

import {
    Menu as MenuIcon
} from '@mui/icons-material';

import { NeurolaboLogoIcon } from '../../Shared/NeurolaboLogoIcons';

import { RootState } from '../../../../App/Store';
import { staticOverlaySlice } from '../../../../App/Store/StaticOverlaySlice';

export function FullOpenDrawerButton() : JSX.Element
{
    const dispatch = useDispatch();

    const [ hovering, setHoverring ] = React.useState(false);

    const loggedIn = useSelector((state : RootState) => state.User.loggedIn);
    const drawerOpen = useSelector((state : RootState) => state.StaticOverlay.drawerOpen) && loggedIn;

    function handleOnsetHoverring(value: boolean)
    {
        setHoverring(value);
    }

    function OnClick()
    { 
        dispatch(staticOverlaySlice.Actions.setDrawerOpen(true)); 
    }

    return (
        <IconButton
            onClick = { OnClick }
            onMouseEnter = { () => handleOnsetHoverring(true) }
            onMouseLeave = { () => handleOnsetHoverring(false) }

            color="inherit"
            aria-label="Open navigation"
            edge="start"
            sx={{
                marginRight: 2,
                ...(drawerOpen && { display: 'none' }),
            }}
        >
            {
                hovering
                    ? (<Grow key="menu-icon" in={hovering} unmountOnExit>{<span style={{ width: 30, height: 30 }}><MenuIcon style={{ margin: 3.8 }}/></span>}</Grow>) 
                    : (<Grow key="neurolabo-icon" in={!hovering} unmountOnExit>{<span style={{ width: 30, height: 30 }}><NeurolaboLogoIcon/></span>}</Grow>)
            }
        </IconButton>
    );
}

export function NonFullOpenDrawerButton() : JSX.Element
{
    const dispatch = useDispatch();

    const drawerOpen = useSelector((state : RootState) => state.StaticOverlay.drawerOpen);

    const onClickHandler = () => dispatch(staticOverlaySlice.Actions.setDrawerOpen(true));

    return (
        <IconButton
            onClick = { onClickHandler }

            color="inherit"
            aria-label="Open navigation"
            edge="start"
            sx={{
                marginRight: 2,
                ...(drawerOpen && { display: 'none' }),
            }}
        >
            <MenuIcon style={{ margin: 3.8 }}/>
        </IconButton>
    );
}