import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { 
    Button, 
    Grid,
    Paper, 
    lighten
} from '@mui/material';

import laboratoryIllustrationImage from '../../assets/images/laboratory-illustration.png';

export default function CreateExpriementationWidget()
{
    const { t } = useTranslation();
    const navigate = useNavigate();

    function OnClickCreateExperiment()
    {
        navigate('/experimentations/create');
    }

    return (
        <Grid
            container
            spacing={ 2 }
            marginBottom = "2rem"
        >
            <Grid
                item
                xs = { 12 }
                md = { 6 }
            >
                <Paper
                    sx = {{
                        padding: '0.5rem',
                        backgroundColor: (theme) => lighten(theme.palette.background.paper, 0.04),
                    }}
                >
                    <Paper
                        sx = {{
                            background: `url(${laboratoryIllustrationImage}) no-repeat`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '20rem',
                            marginY: '0.2rem'
                        }}    
                    >
                    </Paper>
                    
                    <Button
                        onClick = { OnClickCreateExperiment }
                        variant='contained'
                        fullWidth
                    >
                        { t('navbar.experiment.create') }
                    </Button>
                </Paper>
            </Grid>
        </Grid>
    );
}