import { i18n as I18n, TFunction } from 'i18next';
import { UseTranslationResponse } from 'react-i18next'

export type LanguageSelectorConfig = { 
    languageText: string
};

export default class LanguageSelector
{
    public readonly buttonAnchor : React.RefObject<HTMLDivElement>;

    public readonly open : boolean;
    public readonly setOpen : React.Dispatch<React.SetStateAction<boolean>>;
    public readonly selectedKey : string;
    public readonly setSelectedKey : React.Dispatch<React.SetStateAction<string>>;

    private readonly t : TFunction<"translation", undefined>;
    private readonly i18n : I18n;

    public constructor(config : {
            buttonAnchor : React.RefObject<HTMLDivElement>,
            transations : UseTranslationResponse<"translation", undefined>,
            openState : [ boolean, React.Dispatch<React.SetStateAction<boolean>>],
            selectedState : [ string, React.Dispatch<React.SetStateAction<string>>]
        }
    )
    {
        this.t = config.transations.t || null;
        this.i18n = config.transations.i18n || null;

        this.buttonAnchor = config.buttonAnchor || null;
        [this.open, this.setOpen] = config.openState || null;
        [this.selectedKey, this.setSelectedKey] = config.selectedState || null;
    }

    public get currentConfig() : LanguageSelectorConfig
    {
        return this.configurations[this.selectedKey];
    }

    public get configurations() : { [key: string]: LanguageSelectorConfig }
    {
        return {
            "fr-FR": {
                languageText: this.t('language.french')
            },
            "en": {
                languageText: this.t('language.english')
            }
        }
    }

    public handleChangeLanguage(key : string, option : LanguageSelectorConfig) : void
    {
        this.setSelectedKey(key);
        this.i18n.changeLanguage(key);
        this.setOpen(false);
    }

    public handleClose(event: Event) 
    {
        if (this.buttonAnchor.current && this.buttonAnchor.current.contains(event.target as HTMLElement)) 
            return;

        this.setOpen(false);
    }

    public handleToggle() 
    {
        this.setOpen(prevOpen => !prevOpen);
    }
}