import { useTranslation } from 'react-i18next';

import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogTitleProps,
    Typography,
    IconButton,
    TextField,
    Grid,
    Box,
    Button
} from '@mui/material';

import {
    Close as CloseIcon,
} from '@mui/icons-material'
import { useState } from 'react';

type AddClassModalProps = {
    open: boolean,
    handleClose: () => void,
    handleAddClass: (name: String) => void
};

export default function TeacherAddClassForm({ open, handleClose, handleAddClass }: AddClassModalProps) {
    const [t] = useTranslation();

    const [className, setClassName] = useState("");

    function OnAddSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        handleAddClass(className);
        setClassName("");
        handleClose();
    }

    return (
        <Dialog
            open={open}
            keepMounted
            onClose={handleClose}
        >
            <AddClassDialogTitle
                title={t('teacher.classes_view.add_class.title') as string}
                onClick={handleClose}
                sx={{
                    minWidth: '100%'
                }}
            />

            <DialogContent
                sx={{
                    minWidth: '50vh'
                }}
            >
                <Grid
                    container
                    direction='row'
                    spacing={2}
                    columns={1}
                    marginTop="10px"
                    justifyContent='center'
                >
                    <form onSubmit={OnAddSubmit}>
                        <TextField
                            required
                            id="name"
                            label={t('teacher.classes_view.add_class.name_label') as string}
                            value={className}
                            onChange={(event) => setClassName(event.target.value)}
                        />
                        <br/>
                        <br/>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            {t('teacher.classes_view.add_class.add_button')}
                        </Button>
                    </form>
                    
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

function AddClassDialogTitle({ title, children, onClick, ...other }: DialogTitleProps) {
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other} >
            <Typography sx={{ flex: 1, mt: 1, mb: 1 }} variant="h5" component="div">
                {title}
            </Typography>
            {onClick ? (
                <IconButton
                    aria-label="close"
                    onClick={onClick}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 16,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
            {children}
        </DialogTitle>
    );
}