import * as React from 'react'; 

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { 
    Dialog, 
    DialogContent, 
    DialogTitle, 
    DialogTitleProps, 
    Grid, 
    IconButton, 
    Typography 
} from '@mui/material';

import {
    Close as CloseIcon,
} from '@mui/icons-material'

import SkipPreparationOpt from './SkipPreparationOpt';
import { simpleCreateExperimentSlice } from '../../../../App/Store/SimpleCreateExperiment/SimpleCreateExperiementSlice';

type ScenarioSelectionModalProps = {
    open: boolean,
    handleClose: () => void,
};

export default function OptionsModal({ open, handleClose } : ScenarioSelectionModalProps)
{
    const [ t ] = useTranslation();

    const dispatch = useDispatch();
    
    function SetModified(state: boolean)
    {    
        dispatch(simpleCreateExperimentSlice.Actions.setOptionsModified(state));
    }

    return (
        <Dialog
            open = { open }
            keepMounted
            onClose = { handleClose }
        >
            <OptionsDialogTitle 
                title = { t('experimentation.create.options.choice_title') as string }
                onClick = { handleClose }
                sx = {{
                    minWidth: '100%'
                }}
            />

            <DialogContent
                sx = {{
                    minWidth: '50vh'
                }}
            >
                <Grid
                    container
                    direction = 'row'
                    spacing = { 2 }
                    columns = { 1 }
                >
                    <SkipPreparationOpt
                        setModified = { SetModified }
                    />                    
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

function OptionsDialogTitle({ title, children, onClick, ...other } : DialogTitleProps) 
{
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        <Typography sx={{ flex: 1, mt: 1, mb: 1 }} variant="h5" component="div">
            {title}
        </Typography>
        { onClick ? (
          <IconButton
            aria-label = "close"
            onClick = { onClick }
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        { children }
      </DialogTitle>
    );
}