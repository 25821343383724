import { API, ApiTags, DefaultPostRequest } from "..";
import { Class } from "../../Store/SimpleCreateExperiment/Types";

export namespace Student
{
    export type NameRequest = DefaultPostRequest & {};
    export type NameResponse = 
    {
        lastName: string,
        givenName: string
        };

    export type JoinKey = String;

    export type JoinClassRequest = DefaultPostRequest & {
        joinKey: JoinKey
    };
    export type JoinClassResponse = {};

    export type GetClassFromJoinKeyRequest = DefaultPostRequest & {
        joinKey: JoinKey
    };
    export type GetClassFromJoinKeyResponse =
        {
            foundClass: Class,
            isAlreadyInClass: boolean
        };

    export const api = API.injectEndpoints(
    {
        endpoints: build => (
        {
            studentName: build.query<NameResponse, NameRequest>(
            {
                query: (reqData) => 
                ({
                    url: `students/name`,
                    method: 'POST',
                    formData : true,
                    body: reqData,
                }),
                providesTags: [ ApiTags.User ],
            }),
            joinClass: build.mutation<JoinClassResponse, JoinClassRequest>(
            {
                query: (reqData) =>
                ({
                    url: `students/join-class`,
                    method: 'POST',
                    formData: true,
                    body: reqData,
                }),
            }),
            getClassFromJoinKey: build.query<GetClassFromJoinKeyResponse, GetClassFromJoinKeyRequest>(
                {
                    query: (reqData) =>
                    ({
                        url: `students/get-class-from-join-key`,
                        method: 'POST',
                        formData: true,
                        body: reqData,
                    }),
                })
        }),
    });

    export const {
        useStudentNameQuery,
        useJoinClassMutation,
        useGetClassFromJoinKeyQuery
    } = api;
}