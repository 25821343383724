import * as React from 'react';

import { useTranslation } from 'react-i18next';

import { Scenario } from '../../../../App/Store/SimpleCreateExperiment/Types';

import ScenarioBrowserModal from '../../../Components/ScenarioModal/ScenarioBrowserModal';
import ScenarioCard, { AddButtonStatesConfig } from '../../../Components/ScenarioModal/ScenarioCard';

type PlayScenarioModalProps = {
    open: boolean,
    handleClose: () => void,
    selectedScenario: Scenario | null,
    setSelectedScenario: (scenario : Scenario | null) => void
};

export default function PlayScenarioModal({ open, handleClose, selectedScenario, setSelectedScenario } : PlayScenarioModalProps) : JSX.Element
{
    return (
        <ScenarioBrowserModal
            open = { open }
            handleClose = { handleClose }
            buildScenarioCardComponent = { 
                (scenario, handleClose) => 
                    <PlayScenarioCard 
                        scenario = { scenario }
                        handleClose = { handleClose }
                        selectedScenario = { selectedScenario }
                        setSelectedScenario = { setSelectedScenario }
                    /> 
            }
        />
    );
}

type PlayScenarioCardProps = {
    scenario: Scenario,
    handleClose : () => void,
    selectedScenario: Scenario | null,
    setSelectedScenario: (scenario : Scenario | null) => void
};

function PlayScenarioCard({ scenario, handleClose, selectedScenario, setSelectedScenario } : PlayScenarioCardProps) : JSX.Element
{
    const { t } = useTranslation();
    
    const isAddedValidator = 
        (scenarioId : number) => selectedScenario?.id === scenarioId;

    function OnClickAdd(clickedScenario : Scenario)   
    {
        if(isAddedValidator(clickedScenario.id)) return;

        setSelectedScenario(clickedScenario);
        handleClose();
    }

    const AddButtonStatesConfigs : AddButtonStatesConfig = 
    {
        // TODO : Add translations
        default: {
            text: t('demo.scenario_modal.select'),
            color: 'info',
        },
        added: {
            text: t('demo.scenario_modal.select'),
            color: 'info'
        },
    }

    return (
        <ScenarioCard 
            scenario = { scenario }
            handleClose = { handleClose }
            isAddedValidator = { isAddedValidator }
            onClickAdd = { OnClickAdd }
            addButtonStatesConfig = { AddButtonStatesConfigs }
        />
    );
}