import * as React from 'react'; 

import { useTranslation } from 'react-i18next';

import { 
    Box,
    Dialog, 
    DialogContent, 
    DialogTitle, 
    DialogTitleProps, 
    Grid, 
    IconButton, 
    Typography
} from '@mui/material';

import {
    Close as CloseIcon,
} from '@mui/icons-material'

import { Scenario } from '../../../App/Store/SimpleCreateExperiment/Types';
import { Scenarios } from '../../../App/API/Scenarios';
import LoaderCircularProgress from '../Shared/LoaderCircularProgress';
import { useSelector } from 'react-redux';
import { RootState } from '../../../App/Store';

type ScenarioSelectionModalProps = {
    open: boolean,
    handleClose: () => void,
    buildScenarioCardComponent: (scenario : Scenario, handleClose: () => void) => JSX.Element 
};

export default function ScenarioBrowserModal({ open, handleClose, buildScenarioCardComponent } : ScenarioSelectionModalProps)
{
    const [ t ] = useTranslation();

    const token = useSelector((rootState : RootState) => rootState.User.token);
    const userType = useSelector((rootState : RootState) => rootState.User.userType);

    const useScenariosQuery = Scenarios.GetScenariosQueryByUserType(userType)
    const { data: scenarios = [], isLoading } = useScenariosQuery({ token });

    return (
        <Dialog
            open = { open }
            keepMounted
            onClose = { handleClose }
        >
            <ScenarioDialogTitle 
                title = { t('experimentation.create.scenario.modal_title') as string }
                onClick = { handleClose }
            />

            <DialogContent>
                {
                    (isLoading) ? 
                    (
                        <Box sx={{ display: 'flex' }}>
                            <LoaderCircularProgress display />
                        </Box>
                    ) :
                    (
                        <Grid
                            container
                            direction = 'row'
                            spacing = { 2 }
                            columns = { 1 }
                        >
                            <ScenarioGroups 
                                scenarios = { scenarios }
                                handleClose = { handleClose }
                                buildScenarioCardComponent = { buildScenarioCardComponent }
                            />
                        </Grid>
                    )
                }
            </DialogContent>

        </Dialog>
    );
}

function ScenarioDialogTitle({ title, children, onClick, ...other } : DialogTitleProps) 
{
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            <Typography sx={{ flex: 1, mt: 1, mb: 1 }} variant="h5" component="div">
                {title}
            </Typography>
            { 
                onClick ? (
                    <IconButton
                        aria-label = "close"
                        onClick = { onClick }
                        sx={{
                            position: 'absolute',
                            right: 16,
                            top: 16,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null
            }
            { children }
        </DialogTitle>
    );
}

type ScenarioGroupProps = {
    scenarios: Scenario[],
    handleClose : () => void,
    buildScenarioCardComponent: (scenario : Scenario, handleClose: () => void) => JSX.Element 
};

function ScenarioGroups({ scenarios, handleClose, buildScenarioCardComponent } : ScenarioGroupProps) : JSX.Element
{
    const groups : JSX.Element[] = [];

    for(const scenario of scenarios)
    {
        const group = (
            <Grid item xs = { 1 } key = { scenario.id }>
                { buildScenarioCardComponent(scenario, handleClose) }
            </Grid>
        );

        groups.push(group);
    }

    return (
        <> { groups } </>
    );
}
