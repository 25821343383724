import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../../../App/Store';

import {
    Container,
    Grid,
    Button
} from '@mui/material';

import TeacherAddClassForm from './TeacherAddClassForm';
import { Classes } from '../../../../../App/API/Classes';
import Logger from '../../../../../App/Framework/Utils/Logger';

export default function TeacherAddClassButton(): JSX.Element {
    const [t] = useTranslation();
    const [addClassFormOpened, setAddClassFormOpened] = React.useState(false);
    const [newClassRequest] = Classes.useAddClassMutation();
    const token = useSelector((state: RootState) => state.User.token);
    const navigate = useNavigate();

    function OnClickAdd() {
        setAddClassFormOpened(true);
    }

    async function OnAddClass(name: String) {
        try {
            const newClass = await newClassRequest({
                token,
                className: name,
            }).unwrap();

            Logger.info(t('teacher.classes_view.add_class.success') + newClass.name);
            navigate('/teacher/class/' + newClass.id);
        }
        catch (err) {
            Logger.error("When adding class : " + err);
        }
    }

    return (
        <>
            <Container maxWidth='md'>
                <Grid container justifyContent='center'>
                    <Grid item xs={12} md={4}>
                        <Button variant='contained' size='small' fullWidth onClick={OnClickAdd}>
                            {t('teacher.classes_view.add_class.button')}
                        </Button>
                    </Grid>
                </Grid>
            </Container>
            <TeacherAddClassForm
                open={addClassFormOpened}
                handleClose={() => setAddClassFormOpened(false)}
                handleAddClass={OnAddClass}
            />
        </>
    );
}