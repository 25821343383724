import { ThemeOptions } from '@mui/material/styles';

export const themeOptions : ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#779abe',
    },
    secondary: {
      main: '#24b6f7',
    },
    text: {
      primary: 'rgba(255,255,255,0.87)',
      secondary: '#779abe',
      //hint: 'rgba(119,154,190,0.38)',
      disabled: 'rgba(119,154,190,0.5)',
    },
    divider: '#2F6891',
    background: {
      default: '#0b1521',
      paper: '#0d233c',
    },
  },
};