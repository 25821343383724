import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    Container
} from '@mui/material';

import UserSlice, { UserType, userSlice } from '../../../App/Store/User/UserSlice';

import {
    useGetEppnQuery,
    useShibbolethSSOLogoutMutation
} from '../../../App/FontAPI';

import { API, AllApiTags } from '../../../App/API';
import { Users } from '../../../App/API/Users';

import Logger from '../../../App/Framework/Utils/Logger';

import { SSOAuthEntry, SSOAuthMode } from '../../../App/Framework/Utils/EnvParser';
import QuerryErrorSnackbarAlert from '../../Components/Shared/QuerryErrorAlert';
import LoaderCircularProgress from '../../Components/Shared/LoaderCircularProgress';

type SSOAuthProps = {
    SSOConfig: SSOAuthEntry,
    onSuccess?: (userData: { userType: UserType, token: string }) => void,
}

function GetLoginFromAuthModeMutation(authMode: SSOAuthMode): typeof Users.useTryLoginEppnMutation {
    switch (authMode) {
        case 'eppn': return Users.useTryLoginEppnMutation;
        case 'gar': return Users.useTryLoginGarMutation;
    }
}

export default function SSOAuth({ SSOConfig, onSuccess }: SSOAuthProps) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { data: eppnData, isLoading: isLoadingGetEppn, isSuccess: isSuccessGetEppn, error: errorGetEppn } = useGetEppnQuery();

    const [shibbolethSSOLogout, { error: logoutError }] = useShibbolethSSOLogoutMutation();
    const [SSOLogin, { isLoading: isLoadingSSOLogin, error: SSOLoginError }] = GetLoginFromAuthModeMutation(SSOConfig.authMode)();

    const shibbolethGateDone = sessionStorage.getItem('shibbolethGateDone');

    React.useEffect(() => {
        if (!shibbolethGateDone) {
            Logout();
        }

        // change the mode and refresh userSlice store if storageMode has been changed
        if (SSOConfig.storageMode !== UserSlice.StorageSystemMode) {
            dispatch(userSlice.Actions.setStorageType(SSOConfig.storageMode));
        }
    }, []);

    React.useEffect(() => {
        if (shibbolethGateDone && isSuccessGetEppn) {
            TryLogin();
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isSuccessGetEppn, shibbolethGateDone]);

    async function TryLogin() {
        try {
            const loginRes = await SSOLogin({
                passenger_env: eppnData
            }).unwrap();

            dispatch(userSlice.Actions.setLogin({
                loggedIn: true,
                userType: loginRes.userType,
                token: loginRes.token
            }));

            if (onSuccess) {
                onSuccess({
                    userType: loginRes.userType,
                    token: loginRes.token
                })
            }

            Logger.info('Successfuly logged in SSO', SSOConfig.authMode);

            const afterAuthRedirectUrl = sessionStorage.getItem('after_auth_redirect');
            if (afterAuthRedirectUrl != null) {
                sessionStorage.removeItem('after_auth_redirect');
                navigate(afterAuthRedirectUrl);
            }
            else
                navigate('/');
            sessionStorage.removeItem('shibbolethGateDone');
        }
        catch (err) {
            Logger.error('While TryLogin', SSOConfig.authMode, err);
            sessionStorage.removeItem('shibbolethGateDone');
        }
    }

    async function Logout() {
        try {
            await shibbolethSSOLogout().unwrap();
            HandleSessionLogout();
        }
        catch (err) {
            Logger.error('While shibbolethSSOLogout', err);
            HandleSessionLogout();
        }
    }

    function HandleSessionLogout() {
        dispatch(API.util.invalidateTags(AllApiTags));
        sessionStorage.setItem('shibbolethGateDone', 'true');
        window.location.reload();
    }

    return (
        <Container maxWidth='xl'>
            <QuerryErrorSnackbarAlert error={errorGetEppn} />
            <QuerryErrorSnackbarAlert error={SSOLoginError} />
            <QuerryErrorSnackbarAlert error={logoutError} />
            <LoaderCircularProgress display={isLoadingGetEppn || isLoadingSSOLogin} />
        </Container>
    );
}