import styled from "@emotion/styled";

export const ButtonStartIcon = styled('span')(() => ({
    display: 'inherit',
    marginRight: 8,
    marginLeft: -4
}));
  
export const ButtonEndIcon = styled('span')(() => ({
	display: 'inherit',
    marginRight: -4,
    marginLeft: 8
}));