import * as React from 'react'; 

import { 
    alpha, 
    Box, 
    Button, 
    ButtonTypeMap, 
    Chip, 
    Dialog, 
    DialogContent, 
    DialogTitle, 
    DialogTitleProps, 
    Grid, 
    IconButton, 
    Paper, 
    Stack, 
    Typography 
} from '@mui/material';

import {
    Add as AddIcon,
    Check as CheckIcon,
    Close as CloseIcon,
} from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../App/Store';
import { Scenario, ScenarioGroup } from '../../../../../App/Store/CreateExpriment/Types';
import { CreateExperimentSlice, createExperimentSlice } from '../../../../../App/Store/CreateExpriment';

export default function ScenarioBrowserModal({ open, handleClose } : ScenarioSelectionModalProps)
{
    return (
        <Dialog
            open = { open }
            keepMounted
            onClose = { handleClose }
        >
            <ScenarioDialogTitle 
                title='Ajouter des modules pour personnaliser votre expérimentation'
                onClick = { handleClose }
            >
                <Typography color='ActiveCaption' variant="h6" component="div">
                    { "Votre Sélection" }
                </Typography>

                <SelectedScenarios />

            </ScenarioDialogTitle>

            <DialogContent>
                <Grid
                    container
                    direction = 'row'
                    spacing = { 2 }
                    columns = { 1 }
                >
                    <ScenarioGroups 
                        scenarios = { Scenarios }
                    />
                </Grid>
            </DialogContent>

        </Dialog>
    );
}

function ScenarioDialogTitle(props: DialogTitleProps) 
{
    const { title, children, onClick, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        <Typography sx={{ flex: 1, mt: 1, mb: 1 }} variant="h5" component="div">
            {title}
        </Typography>
        { onClick ? (
          <IconButton
            aria-label = "close"
            onClick = {onClick}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        {children}
      </DialogTitle>
    );
  }

type ScenarioSelectionModalProps = {
    open: boolean,
    handleClose?: () => void,
};

function SelectedScenarios() : JSX.Element
{
    const dispatch = useDispatch();

    const activeScenarios = useSelector((state: RootState) => state.CreateExperiment.scenarioStep.activeScenarios);

    const scenariosStack : JSX.Element[] = [];

    function OnDeleteChip(scenarioGroup : ScenarioGroup)
    {
        dispatch(createExperimentSlice.Actions.removeActiveScenario(scenarioGroup.scenarioId));
    }

    for(const scenarioGroup of Object.values(activeScenarios))
    {
        const scenarioChip = (
            <Chip 
                key = { scenarioGroup.scenarioId }
                label = { scenarioGroup.scenarioApplicationRef } 
                onDelete={() => OnDeleteChip(scenarioGroup)} 
                sx = {{
                    backgroundColor: scenarioGroup.scenarioColorCode,
                }}
            />
        );

        scenariosStack.push(scenarioChip);
    }

    return (
        <Stack 
            direction="row" 
            spacing = { 1 } 
            sx = {{ 
                marginTop: '0.4rem',
                paddingBottom : '.8rem',
                overflow: 'scroll'
            }}
        >
            { scenariosStack }
        </Stack>
    );
}

// TODO : Add translation for all & store this data in an other file
const Scenarios : Scenario[] = [
    {
        id: 0,
        applicationRef: 'Stéréotaxie',
        colorCode: '#B4604C',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus odio dui, commodo a velit sit amet, semper blandit ipsum. Donec a ornare sem. Phasellus tempus dapibus nisl, in congue enim tincidunt sit amet. Sed eu lacus quis nunc convallis blandit. Nam tincidunt ex elit, vel consequat odio euismod eget. Integer varius pharetra lorem ac consectetur.',
        imageUrl: 'https://picsum.photos/600/400?random=1'
    }, {
        id: 1,
        applicationRef: '+Maze',
        category: 'Gn',
        colorCode: '#A96CC3',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus odio dui, commodo a velit sit amet, semper blandit ipsum. Donec a ornare sem. Phasellus tempus dapibus nisl, in congue enim tincidunt sit amet. Sed eu lacus quis nunc convallis blandit. Nam tincidunt ex elit, vel consequat odio euismod eget. Integer varius pharetra lorem ac consectetur.',
        imageUrl: 'https://picsum.photos/600/400?random=2'
    }, {
        id: 2,
        applicationRef: 'Openfield',
        category: 'Gn',
        colorCode: '#A96CC3',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus odio dui, commodo a velit sit amet, semper blandit ipsum. Donec a ornare sem. Phasellus tempus dapibus nisl, in congue enim tincidunt sit amet. Sed eu lacus quis nunc convallis blandit. Nam tincidunt ex elit, vel consequat odio euismod eget. Integer varius pharetra lorem ac consectetur.',
        imageUrl: 'https://picsum.photos/600/400?random=3'
    }, {
        id: 3,
        applicationRef: 'Attractive behavior',
        colorCode: '#C1951E',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus odio dui, commodo a velit sit amet, semper blandit ipsum. Donec a ornare sem. Phasellus tempus dapibus nisl, in congue enim tincidunt sit amet. Sed eu lacus quis nunc convallis blandit. Nam tincidunt ex elit, vel consequat odio euismod eget. Integer varius pharetra lorem ac consectetur.',
        imageUrl: 'https://picsum.photos/600/400?random=4'
    }, {
        id: 4,
        applicationRef: 'Resident intruder',
        colorCode: '#D15096',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus odio dui, commodo a velit sit amet, semper blandit ipsum. Donec a ornare sem. Phasellus tempus dapibus nisl, in congue enim tincidunt sit amet. Sed eu lacus quis nunc convallis blandit. Nam tincidunt ex elit, vel consequat odio euismod eget. Integer varius pharetra lorem ac consectetur.',
        imageUrl: 'https://picsum.photos/600/400?random=5'
    }, {
        id: 5,
        applicationRef: 'GABA-A',
        category : 'Ph',
        colorCode: '#4E9D98',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus odio dui, commodo a velit sit amet, semper blandit ipsum. Donec a ornare sem. Phasellus tempus dapibus nisl, in congue enim tincidunt sit amet. Sed eu lacus quis nunc convallis blandit. Nam tincidunt ex elit, vel consequat odio euismod eget. Integer varius pharetra lorem ac consectetur.',
        imageUrl: 'https://picsum.photos/600/400?random=6'
    }, {
        id: 6,
        applicationRef: 'Dérivés d\'opioïdes',
        category : 'Ph',
        colorCode: '#4E9D98',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus odio dui, commodo a velit sit amet, semper blandit ipsum. Donec a ornare sem. Phasellus tempus dapibus nisl, in congue enim tincidunt sit amet. Sed eu lacus quis nunc convallis blandit. Nam tincidunt ex elit, vel consequat odio euismod eget. Integer varius pharetra lorem ac consectetur.',
        imageUrl: 'https://picsum.photos/600/400?random=7'
    }, {
        id: 7,
        applicationRef: 'Antipsychotiques',
        category : 'Ph',
        colorCode: '#4E9D98',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus odio dui, commodo a velit sit amet, semper blandit ipsum. Donec a ornare sem. Phasellus tempus dapibus nisl, in congue enim tincidunt sit amet. Sed eu lacus quis nunc convallis blandit. Nam tincidunt ex elit, vel consequat odio euismod eget. Integer varius pharetra lorem ac consectetur.',
        imageUrl: 'https://picsum.photos/600/400?random=8'
    }, {
        id: 8,
        applicationRef: '+Maze',
        category : 'Ph',
        colorCode: '#4E9D98',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus odio dui, commodo a velit sit amet, semper blandit ipsum. Donec a ornare sem. Phasellus tempus dapibus nisl, in congue enim tincidunt sit amet. Sed eu lacus quis nunc convallis blandit. Nam tincidunt ex elit, vel consequat odio euismod eget. Integer varius pharetra lorem ac consectetur.',
        imageUrl: 'https://picsum.photos/600/400?random=9'
    }, {
        id: 9,
        applicationRef: 'Openfield',
        category : 'Ph',
        colorCode: '#4E9D98',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus odio dui, commodo a velit sit amet, semper blandit ipsum. Donec a ornare sem. Phasellus tempus dapibus nisl, in congue enim tincidunt sit amet. Sed eu lacus quis nunc convallis blandit. Nam tincidunt ex elit, vel consequat odio euismod eget. Integer varius pharetra lorem ac consectetur.',
        imageUrl: 'https://picsum.photos/600/400?random=10'
    }
];

type ScenarioGroupProps = {
    scenarios: Scenario[],
};

function ScenarioGroups({ scenarios } : ScenarioGroupProps) : JSX.Element
{
    const groups : JSX.Element[] = [];

    for(const scenarioConfig of scenarios)
    {
        const group = (
            <Grid item xs={ 1 } key = { scenarioConfig.id }>
                <ScenarioCard config = { scenarioConfig } />
            </Grid>
        );

        groups.push(group);
    }

    return (
        <> { groups } </>
    );
}

type ScenarioCardProps = {
    config: Scenario,
};

function ScenarioCard({ config } : ScenarioCardProps)
{  
    const dispatch = useDispatch();

    const CreateExperimentState = useSelector((state: RootState) => state.CreateExperiment);
    const isAdded = () => CreateExperimentSlice.Selectors(CreateExperimentState).isScenarioActive(config.id);

    const AddButtonStatesConfigs = 
    {
        default: {
            // TODO : Add translation
            text: 'Ajouter', // t(''),
            color: 'info' as ButtonTypeMap<{}, "button">['props']['color'],
            icon: <AddIcon/>,
        },
        added: {
            // TODO : Add translation
            text: 'Ajouté', // t('')
            color: 'success' as ButtonTypeMap<{}, "button">['props']['color'],
            icon: <CheckIcon />,
        },
    }

    const GetCurrentConfigByState = () => 
        (isAdded()) ? AddButtonStatesConfigs.added : AddButtonStatesConfigs.default;

    function OnClickAdd()
    {
        if(isAdded())
            dispatch(createExperimentSlice.Actions.removeActiveScenario(config.id));
        else
            dispatch(createExperimentSlice.Actions.addActiveScenario(config));
    }

    return (
        <Paper
            sx = {{ 
                backgroundImage: `url(${ config.imageUrl })`,
                color: 'white',
            }}
        >   
            <Box sx={{ height: '10rem' }}></Box>

            <Paper
                sx = {{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingY: '.3rem',
                    paddingX: '.5rem',
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    backgroundColor: theme => alpha(theme.palette.primary.main, 0.9)
                }}
            >
                <Typography variant='h6'>{ config.applicationRef }</Typography>
                
                <Button 
                    variant = 'contained' 
                    color = { GetCurrentConfigByState().color } 
                    startIcon = { GetCurrentConfigByState().icon } 
                    onClick = { OnClickAdd }
                    sx = {{
                        color: 'white',
                    }}
                >
                    { GetCurrentConfigByState().text }
                </Button>

            </Paper>
            <Paper
                sx = {{
                    backgroundColor: theme => alpha(theme.palette.primary.light, 0.8),
                    color: theme => theme.palette.primary.contrastText,
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                    padding: '1rem',
                }}
            >
                <Typography variant='body2'>{ config.description }</Typography>
            </Paper>
        </Paper>
    );
}