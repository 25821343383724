import * as React from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../App/Store';
import { useNavigate } from 'react-router-dom';

import { 
    Container, 
    Divider, 
    Typography 
} from '@mui/material';

import LoaderCircularProgress from '../../Components/Shared/LoaderCircularProgress';
import ExperimentationsGrid from '../../Components/ExperimentationsGrid/ExperimentationsGrid';

import { Experiments } from '../../../App/API/Experiments';

import { Experimentation } from '../../../App/Store/Experiment/Types';

export default function MyExperimentations() : JSX.Element
{
    const [ t ] = useTranslation();
    const navigate = useNavigate();

    const token = useSelector((state: RootState) => state.User.token);
    const userType = useSelector((state: RootState) => state.User.userType);

    // Querry selection
    let activeExpriementationsQuery = Experiments.useTeacherActiveExpriementationsQuery;
    let useOldExpriementationsQuery = Experiments.useTeacherOldExpriementationsQuery;
    
    if(userType === 'student')
    {
        activeExpriementationsQuery = Experiments.useStudentActiveExpriementationsQuery;
        useOldExpriementationsQuery = Experiments.useStudentOldExpriementationsQuery;
    }

    // Requests hooks
    const { data: activeExp = [], isLoading: activeIsLoading, error: activeError } = activeExpriementationsQuery({ token });
    const { data: oldExp = [], isLoading: oldIsLoading, error: oldError } = useOldExpriementationsQuery({ token });

    function OnClickExpItemButton(experimentation: Experimentation)
    {
        navigate(`/experimentations/${experimentation.id}`);
    }

    return (
        <Container maxWidth='xl'>
            <Typography variant='h4' component='h2'
                sx = {{
                    marginBottom: '2rem'
                }}
            >
                { t('my_experimentations.current_title') }
            </Typography>
            
            
            <LoaderCircularProgress display = { activeIsLoading } />
            {   
                (!activeIsLoading && !activeError) &&
                (
                    <ExperimentationsGrid 
                        experimentations = { activeExp }
                        expItemButtonText = { t('my_experimentations.item.details_button') }
                        OnClickExpItemButton = { OnClickExpItemButton }
                        isStudent = { userType === 'student' }
                    /> 
                )
            }
            
            <Divider
                sx = {{
                    marginY: '2rem'
                }}
            />

            <Typography variant='h4' component='h2'
                sx = {{
                    marginBottom: '2rem'
                }}
            >
                { t('my_experimentations.past_title') }
            </Typography>
            
            <LoaderCircularProgress display = { oldIsLoading } />
            
            { 
                (!oldIsLoading && !oldError) &&
                (
                    <ExperimentationsGrid 
                        experimentations = { oldExp } 
                        expItemButtonText = { t('my_experimentations.item.details_button') }
                        OnClickExpItemButton = { OnClickExpItemButton }
                        isStudent = { userType === 'student' }
                    />
                )
            }
            
        </Container>
    );
}