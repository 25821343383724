import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../App/Store';

import { 
    Button,
    Paper, 
    Stack, 
    SxProps, 
    Theme, 
    Typography, 
    lighten 
} from '@mui/material';

import QuerryErrorSnackbarAlert from '../../Components/Shared/QuerryErrorAlert';
import ExperimentationsGrid from '../../Components/ExperimentationsGrid/ExperimentationsGrid';
import LoaderCircularProgress from '../../Components/Shared/LoaderCircularProgress';

import { Experiments } from '../../../App/API/Experiments';
import { Experimentation } from '../../../App/Store/Experiment/Types';

const maxExerimentationsDisplayed = 3;

type MyExperiementationsWidgetPorps = {
    sx?: SxProps<Theme>;
}

export default function MyExperiementationsWidget({ sx } : MyExperiementationsWidgetPorps) : JSX.Element
{
    const [ t ] = useTranslation();
    const navigate = useNavigate();

    const token = useSelector((state: RootState) => state.User.token);
    const userType = useSelector((state: RootState) => state.User.userType);

    // Querry selection
    let activeExpriementationsQuery = Experiments.useTeacherActiveExpriementationsQuery;
    let useOldExpriementationsQuery = Experiments.useTeacherOldExpriementationsQuery;
    
    if(userType === 'student')
    {
        activeExpriementationsQuery = Experiments.useStudentActiveExpriementationsQuery;
        useOldExpriementationsQuery = Experiments.useStudentOldExpriementationsQuery;
    }

    // Requests hooks
    const { data: activeExp = [], isLoading: activeIsLoading, error: activeError } = activeExpriementationsQuery({ token });
    const { data: oldExp = [], isLoading: oldIsLoading, error: oldError } = useOldExpriementationsQuery({ token });

    const toBedisplayed : Experimentation[] = [];

    if (!activeIsLoading && !oldIsLoading && !activeError && !oldError)
    {
        toBedisplayed.push(...activeExp.slice(0, maxExerimentationsDisplayed));

        if(toBedisplayed.length < maxExerimentationsDisplayed)
        {
            const needToBeAddedCount = maxExerimentationsDisplayed - toBedisplayed.length;
            toBedisplayed.push(...oldExp.slice(0, needToBeAddedCount));
        }
    }

    function OnClickExpItemButton(experimentation: Experimentation)
    {
        navigate(`/experimentations/${experimentation.id}`);
    }

    return ( 
        <Paper
            sx = {{
                padding: '1rem',
                backgroundColor: (theme) => lighten(theme.palette.background.paper, 0.04),
                ...sx,
            }}
        >
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
            >
                <Typography variant='h5' component='h2'>{ t(`home_view.my_experiementation_widget.title_${userType}`) }</Typography>
                <Button
                    variant='outlined'
                    onClick={ () => navigate('/experimentations/my-experimentations') }
                >
                    { t('home_view.my_experiementation_widget.view_all_button') }
                </Button>
            </Stack>

            <LoaderCircularProgress display = { activeIsLoading } />
            <QuerryErrorSnackbarAlert error={activeError}/>
            
            { 
                (!activeIsLoading && !activeError) &&
                <ExperimentationsGrid
                    experimentations = { toBedisplayed }
                    expItemButtonText = { t('my_experimentations.item.details_button') }
                    OnClickExpItemButton = { OnClickExpItemButton }
                    isStudent = { userType === 'student' }
                    sx = {{ 
                        marginY: '1rem'
                    }}
                />
            }
        </Paper>
    );
}