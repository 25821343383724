import * as React from "react";

import { useNavigate } from "react-router-dom";

import { 
    Button, 
} from '@mui/material';

import { NeurolaboLogoFullIcon } from '../../Shared/NeurolaboLogoIcons';


export default function HomeButton() : JSX.Element
{
    const navigate = useNavigate();

    return (
        <Button
            onClick = { () => navigate('/') }
        >
            <NeurolaboLogoFullIcon/>
        </Button>
    );
}