import { API, ApiTags, DefaultPostRequest } from "..";
import { Scenario } from "../../Store/SimpleCreateExperiment/Types";
import { UserType } from "../../Store/User/UserSlice";

export namespace Scenarios
{
    export type GetScenariosResponse = Scenario[];

    export type GetScenarioByIdRequest = { id : number };

    export type GetScenarioByIdResponse = Scenario;

    export const api = API.injectEndpoints(
    {
        endpoints: build => (
        {
            getStudentScenarios: build.query<GetScenariosResponse, DefaultPostRequest>(
            {
                query: (requestData) => 
                ({
                    url: `students/get-student-scenarios/`,
                    method: 'POST',
                    formData : true,
                    body: requestData
                }),
                providesTags: [ ApiTags.Scenarios ],
            }),
            getTeacherScenarios: build.query<GetScenariosResponse, DefaultPostRequest>(
            {
                query: (requestData) => 
                ({
                    url: `teachers/get-teacher-scenarios/`,
                    method: 'POST',
                    formData : true,
                    body: requestData
                }),
                providesTags: [ ApiTags.Scenarios ],
            }),
            getScenarioById: build.query<GetScenarioByIdResponse, GetScenarioByIdRequest>(
            {
                query: (requestData) => 
                ({
                    url: `scenario/getscenariofromid`,
                    method: 'POST',
                    formData : true,
                    body: requestData
                }),
                providesTags: [ ApiTags.Scenarios ],
            }),
        }),
    });

    export const GetScenariosQueryByUserType = (userType : UserType) =>
    {
        switch(userType)
        {
            case 'teacher': return Scenarios.useGetTeacherScenariosQuery;
            case 'student': 
            default:
                return Scenarios.useGetStudentScenariosQuery;
        }
    }

    export const {
        useGetStudentScenariosQuery,
        useGetTeacherScenariosQuery,
        useGetScenarioByIdQuery
    } = api;
}