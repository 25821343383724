import * as React from 'react';

import { Box, Button, Container, Paper, Stack, Typography } from '@mui/material';

import AuthMethods, { AuthMethod } from './AuthMethodsButtons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Hostinfo from '../../../App/Framework/Utils/HostInfo';

export default function AuthMethodsView() : JSX.Element
{
    const [ t ] = useTranslation();

    return (
        <Container maxWidth='xl'>
            <Typography 
                variant='h4' 
                component='h2'
                textAlign='center'
                sx = {{
                   marginY: '3rem' 
                }} 
            >
                { t('auth.auth_methods_title') }
            </Typography>

            <MethodsStack authMethods = { AuthMethods(t) } />

            <Typography 
                variant = 'body1'
                textAlign = 'center'
                color = 'text.secondary'
                sx = {{
                   marginY: '3rem' 
                }}
            >
                { t('auth.auth_methods_notice') }
            </Typography>
        </Container>
    );
}

type MethodsStackProps = {
    authMethods : AuthMethod[]
};

function MethodsStack({ authMethods } : MethodsStackProps)
{
    const navigate = useNavigate();

    const buttons : JSX.Element[] = [];

    for(const authMethod of authMethods)
    {
        buttons.push(
            <Button
            //window.location.href = `${Hostinfo.GetHostUrl()}${SSOConfig.url}`;
                //onClick = { () => navigate(authMethod.url) }
                onClick={() => window.location.href = `${Hostinfo.GetHostUrl()}${authMethod.url}` }
                key = { authMethod.url as string }
            >
                <Paper
                    sx = {{ 
                        backgroundImage: `url(${ authMethod.img })`,
                        backgroundSize: 'contain',
                        color: 'white',
                        width: 200,
                        height: 200,

                        display: 'flex',
                        flexDirection : 'column',
                        alignItems: 'center',
                        justifyContent : 'flex-end',
                    }}
                >
                    <Box
                        sx = {{
                            width: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            padding: '0.1rem',
                            textTransform: 'none',
                        }}
                    >
                        { authMethod.title }
                    </Box>
                </Paper>
                
            </Button>
        );
    }

    return (
        <Stack
            direction =  {{ xs: 'column', sm : 'row' }}
            alignItems = 'center'
            justifyContent = 'center'
            flexWrap = 'wrap'
            spacing = { 3 }
            sx = {{
                padding: '1rem'
            }}
        >
            { buttons }
        </Stack>
    );
}