import * as React from 'react';

import {
    Science as ScienceIcon,
    // Insights as InsightsIcon,
    Dashboard as DashboardIcon,
    // NotificationsActive as NotificationsActiveIcon,
    // Settings as SettingsIcon,
    // HelpOutline as HelpOutlineIcon
} from '@mui/icons-material';

import { NavButtonConfig } from './NavigationConfigTypes';

/** How to use this config:
 * When you want to add a new button to the navigation panel, add it to the config.
 * There are too kind of buttons :
 * - The NavButton : This is the button that will be displayed in the navigation panel.
 * - The NavButtonChild : This is the button that will be displayed in the dropdown menu of a NavButton.
 * 
 * You can add a NavButtonChild to a NavButton by adding it to the childrend property of the NavButton.
 * 
 * You can customize the NavButton and NavButtonChild by changing the name, the icon and the navigate property.
 * For the navigation property you can specify the comportement of the button when it is clicked.
 * 
 * `openOnNavigate` : Open the navigation drawer when the button is clicked.
 * 
 * `closeOnNavigate` : Close the navigation drawer when the button is clicked.
 */
export const CenterButtonsConfig : NavButtonConfig[] = [
    {
        name: 'navbar.dashboard',
        icon: <DashboardIcon />,
        navigate: {
            openOnNavigate: true,
            location: '/',
        }
    },
    // {
    //     name: 'navbar.stats',
    //     icon: <InsightsIcon />,
    //     navigate: {
    //         openOnNavigate: true,
    //         location: '/',
    //     }
    // },
    {
        name: 'navbar.experiment.experiment',
        icon: <ScienceIcon />,
        navigate: {
            openOnNavigate: true,
        },
        childrend : [
            {
                name: 'navbar.experiment.play',
                navigate: {
                    closeOnNavigate : true,
                    location: '/experimentations/play',
                },
            },
            {
                name: 'navbar.experiment.my_experimentations',
                navigate: {
                    closeOnNavigate : true,
                    location: '/experimentations/my-experimentations',
                },
            },
            {
                name: 'navbar.experiment.play_alone',
                navigate: {
                    closeOnNavigate : true,
                    location: '/experimentations/play-alone',
                },
            }
        ]
    }
];

/** 
 * This config has the same comportement of CenterButtonsConfig, except that it is displayed at the bottom of the navigation panel.
 */
export const BottomButtonsConfig : NavButtonConfig[] = [
    // {
    //     name: 'navbar.settings',
    //     icon: <SettingsIcon />,
    //     navigate: {
    //         openOnNavigate: false,
    //         location: '/',
    //     }
    // },
    // {
    //     name: 'navbar.notifications',
    //     icon: <NotificationsActiveIcon />,
    //     navigate: {
    //         openOnNavigate: false,
    //         location: '/',
    //     }
    // },
    // {
    //     name: 'navbar.helpcenter',
    //     icon: <HelpOutlineIcon />,
    //     navigate: {
    //         openOnNavigate: false,
    //         location: '/',
    //     }
    // }
];