import {
    PayloadAction,
} from '@reduxjs/toolkit';

import AppSlice from '../../Framework/Store/AppSlice';
import Logger from '../../Framework/Utils/Logger';

import { 
    Student, 
    GroupName, 
    ScenarioGroup, 
    StudentGroup, 
    StudentId, 
    Scenario, 
    ScenarioId 
} from './Types';

import { Selectors } from './CreateExperimentSliceSelectors';

type CreateExprimentationStep = {
    active: boolean,
    reachable : boolean,
    finished: boolean,
    hideInSteper? : boolean
};

export type ClassStep = CreateExprimentationStep & {
    className : string,
    classId: number,
    collabMode: boolean,
    students : { [key: StudentId]: Student },
};

export type GroupsStep = CreateExprimentationStep & {
    studentGroupsIdCounter: number,
    needToBeGroupedStudents: StudentId[],
    studentGroups: { [key: GroupName]: StudentGroup }
};

export type ScenarioStep = CreateExprimentationStep & {
    activeScenarios: { [key: ScenarioId]: ScenarioGroup }
};

export type ExperiementationSteps = 'classStep' | 'groupsStep' | 'scenarioStep' | 'endStep';

export type CreateExperimentState = {
    [key in ExperiementationSteps]: CreateExprimentationStep;
} & {
    'classStep': ClassStep;
    'groupsStep': GroupsStep;
    'scenarioStep': ScenarioStep;
};

export const InitialState : CreateExperimentState = 
{
    'classStep': 
    {
        active: true,
        reachable: true,
        finished: false,
        collabMode: false,
        className: "",
        classId: 0,
        students: []
    },
    'groupsStep': 
    {
        active: false,
        reachable: false,
        finished: false,
        hideInSteper: true,
        needToBeGroupedStudents: [],
        studentGroupsIdCounter: 0,
        studentGroups: {}
    },
    'scenarioStep': 
    {
        active: false,
        reachable: false,
        finished: false,
        activeScenarios: {}
    },
    'endStep':
    {
        active: false,
        reachable: false,
        finished: false
    }
};

export default class CreateExperimentSlice extends AppSlice<CreateExperimentState>
{
    protected override getConfig()
    {
        return {
            name: 'CreateExperiment',
            initialState: InitialState
        }
    }

    public static Selectors = Selectors;

    private CreateGroupName(state: CreateExperimentState) : GroupName
    {
        return `Group ${state.groupsStep.studentGroupsIdCounter + 1}`;
    }

    private ChangeGroupName(state: CreateExperimentState, oldName : GroupName, newName : GroupName)
    {
        state.groupsStep.studentGroups[newName] = state.groupsStep.studentGroups[oldName];
        state.groupsStep.studentGroups[newName].name = newName;

        delete state.groupsStep.studentGroups[oldName];
    }

    private ResetGroupsStep(state: CreateExperimentState)
    {
        state.groupsStep.finished = false;
        state.groupsStep.studentGroups = {};
        state.groupsStep.studentGroupsIdCounter = 0;
    }

    private ResetScenarioStep(state: CreateExperimentState)
    {
        state.groupsStep.finished = false;
        state.scenarioStep.activeScenarios = {};
    }

    public override getReducers()
    {
        return {
            
            setStepActive(state: CreateExperimentState, action: PayloadAction<ExperiementationSteps>)
            {
                for(const step of Object.values(state))
                {
                    step.active = false;
                }

                state[action.payload].active = true;
            },

            setStepReachable(state: CreateExperimentState, action: PayloadAction<ExperiementationSteps>)
            {
                state[action.payload].reachable = true;
            },

            setStepFinished(state: CreateExperimentState, action: PayloadAction<ExperiementationSteps>)
            {
                state[action.payload].finished = true;
            },

            setClass(state: CreateExperimentState, setClassAction : PayloadAction<{ name: string, id: number }>)
            {
                state.classStep.className = setClassAction.payload.name;
                state.classStep.classId = setClassAction.payload.id;

                createExperimentSlice.ResetGroupsStep(state);
                createExperimentSlice.ResetScenarioStep(state);
            },

            setStudents(state: CreateExperimentState, setStudentsAction : PayloadAction<Student[]>)
            {
                const students = setStudentsAction.payload;

                state.classStep.students = [];
                state.groupsStep.needToBeGroupedStudents = [];
                
                for(const student of students)
                {
                    state.classStep.students[student.id] = student;
                    state.groupsStep.needToBeGroupedStudents.push(student.id);
                }

                state.groupsStep.needToBeGroupedStudents.sort();
            },

            setCollabMode(state: CreateExperimentState, setCollabModeAction : PayloadAction<boolean>)
            {
                state.classStep.collabMode = setCollabModeAction.payload;
                state.groupsStep.hideInSteper = !setCollabModeAction.payload;
            },

            addGroup(state: CreateExperimentState)
            {
                const groupName = createExperimentSlice.CreateGroupName(state);

                state.groupsStep.studentGroups[groupName] = {
                    name: groupName,
                    studentsIds: [],
                    drawerOpen: true,
                };
                
                ++state.groupsStep.studentGroupsIdCounter;
            },

            removeGroup(state: CreateExperimentState, removeGroupAction : PayloadAction<GroupName>)
            {
                const groupName = removeGroupAction.payload;

                // Remove from needToBeGroupedStudents
                const group = CreateExperimentSlice.Selectors(state).getGroupByName(groupName);
                
                if(!group)
                {
                    Logger.error(`You tried to remove a group that doesn't exist. Group name : ${groupName}`);
                    return;
                }

                // TODO : Maybe make a function to wrap this
                for(const studenntID of group.studentsIds)
                {
                    state.groupsStep.needToBeGroupedStudents.push(studenntID);
                }

                state.groupsStep.needToBeGroupedStudents.sort();
                
                // Remove from scenario
                const activeScenario = CreateExperimentSlice.Selectors(state).getActiveScenariosByGroupName(groupName);
                
                if(activeScenario)
                {
                    const groupsInScenario = activeScenario.groupsInScenario
                    groupsInScenario.splice(groupsInScenario.indexOf(groupName), 1);
                }

                // Remove from studentGroups
                delete state.groupsStep.studentGroups[groupName];
            },

            changeGroupName(state: CreateExperimentState, changeGroupNameAction : PayloadAction<{ oldName: GroupName, newName: GroupName }>)
            {
                const { oldName, newName } = changeGroupNameAction.payload;
                createExperimentSlice.ChangeGroupName(state, oldName, newName);
            },

            addStudentToGroup(state: CreateExperimentState, addStudentToGroupAction : PayloadAction<{ studentId: StudentId, groupName: GroupName }>)
            {
                const { studentId, groupName } = addStudentToGroupAction.payload;

                const group = CreateExperimentSlice.Selectors(state).getGroupByName(groupName);
                
                if(!group)
                {
                    Logger.error(`you tried to add a student to a group that doesn't exist. Group name : ${groupName}`);
                    return;
                }

                const studentIndex = state.groupsStep.needToBeGroupedStudents.indexOf(studentId);

                if(studentIndex !== -1)
                {
                    state.groupsStep.needToBeGroupedStudents.splice(studentIndex, 1);
                    state.groupsStep.needToBeGroupedStudents.sort();
                }
                else
                {
                    const oldGroup = CreateExperimentSlice.Selectors(state).getStudentGroupByStudentId(studentId);

                    if(oldGroup)
                    {
                        const oldGroupIndex = oldGroup.studentsIds.indexOf(studentId);
                        oldGroup.studentsIds.splice(oldGroupIndex, 1);
                    }
                }

                group.studentsIds.push(studentId);
            },

            removeStudentFromHisGroup(state: CreateExperimentState, removeStudentFromHisGroupAction : PayloadAction<StudentId>)
            {
                const studentId = removeStudentFromHisGroupAction.payload;

                const group = CreateExperimentSlice.Selectors(state).getStudentGroupByStudentId(studentId);

                if(group)
                {
                    group.studentsIds.splice(group.studentsIds.indexOf(studentId), 1);
                    
                    state.groupsStep.needToBeGroupedStudents.push(studentId);
                    state.groupsStep.needToBeGroupedStudents.sort();
                }
                else
                {
                    Logger.warn(`You tried to remove a student from his group but he doesn't have one. StudentId : ${studentId}`);
                }
            },

            setGroupDrawerOpen(state: CreateExperimentState, setGroupDrawerOpenAction : PayloadAction<{ groupName: GroupName, open: boolean }>)
            {
                const { groupName, open } = setGroupDrawerOpenAction.payload;

                const group = CreateExperimentSlice.Selectors(state).getGroupByName(groupName);
                
                if(!group)
                {
                    Logger.error(`You tried to set the drawerOpen of a group that doesn't exist. Group name : ${groupName}`);
                    return;
                }

                group.drawerOpen = open;
            },

            putAllStudentsInRandomGroups(state: CreateExperimentState)
            {
                // This is naive implementation of the Fisher-Yates shuffle algorithm
                // It could be performance improved but it work for now

                const needToBeGroupedStudents = state.groupsStep.needToBeGroupedStudents;
                const studentGroups = state.groupsStep.studentGroups;

                const students = Object.values(needToBeGroupedStudents);

                // suffle students array
                for(let i = students.length - 1; i > 0; --i)
                {
                    const j = Math.floor(Math.random() * (i + 1));
                    [students[i], students[j]] = [students[j], students[i]];
                }

                // groupsSize * studentGroups.length can not be < than students.length
                const groupsSize = Math.ceil(students.length / Object.keys(studentGroups).length);

                for(const group of Object.values(studentGroups))
                {
                    for(let i = 0; i < groupsSize; ++i)
                    {
                        const student = students.pop();

                        if(!student)
                            break;

                        group.studentsIds.push(student);

                        const studentIndex = needToBeGroupedStudents.findIndex((std) => std === student);
                        needToBeGroupedStudents.splice(studentIndex, 1);
                    }
                }
            },

            addActiveScenario(state: CreateExperimentState, addScenarioAction : PayloadAction<Scenario>)
            {
                const scenario = addScenarioAction.payload;
                
                state.scenarioStep.activeScenarios[scenario.id] = {
                    scenarioApplicationRef: scenario.applicationRef,
                    scenarioCategory: scenario.category,
                    scenarioId: scenario.id,
                    scenarioColorCode: scenario.colorCode,
                    studentsInScenario: [],
                    groupsInScenario: [],
                    drawerOpen: true,
                };
            },

            removeActiveScenario(state: CreateExperimentState, removeScenarioAction : PayloadAction<ScenarioId>)
            {
                const scenarioId = removeScenarioAction.payload;
                delete state.scenarioStep.activeScenarios[scenarioId];
            },

            addGroupToScenario(state: CreateExperimentState, addGroupToScenarioAction : PayloadAction<{ groupName: GroupName, scenarioId: ScenarioId }>)
            {
                const { groupName, scenarioId } = addGroupToScenarioAction.payload;

                const scenario = CreateExperimentSlice.Selectors(state).getActiveScenarioByScenarioId(scenarioId);

                if(scenario)
                {
                    // remove from previous scenario
                    const oldScenario = CreateExperimentSlice.Selectors(state).getActiveScenariosByGroupName(groupName)

                    if(oldScenario)
                    {
                        const oldScenarioIndex = oldScenario.groupsInScenario.indexOf(groupName);
                        
                        if(oldScenarioIndex !== -1)
                            oldScenario.groupsInScenario.splice(oldScenarioIndex, 1);
                    }

                    // add group to scenario
                    const group = CreateExperimentSlice.Selectors(state).getGroupByName(groupName);

                    if(!group)
                    {
                        Logger.error(`You tried to add an invalid groupName to a scenarios. GroupName : ${groupName}, ScenarioId : ${scenarioId}`);
                        return;
                    }

                    group.drawerOpen = false;
                    scenario.groupsInScenario.push(groupName);
                }
                else
                {
                    Logger.error(`You tried to add a group to a scenario that doesn't exist. ScenarioId : ${scenarioId}`);
                }
            },

            addStudentToScenario(state: CreateExperimentState, addStudentToScenarioAction : PayloadAction<{ studentId: StudentId, scenarioId: ScenarioId }>)
            {
                const { studentId, scenarioId } = addStudentToScenarioAction.payload;
                
                const scenario = Selectors(state).getActiveScenarioByScenarioId(scenarioId);

                if(scenario)
                {
                    const oldScenario = Selectors(state).getActiveScenariosByStudentId(studentId)

                    if(oldScenario)
                    {
                        const oldScenarioIndex = oldScenario.studentsInScenario.indexOf(studentId);
                        
                        if(oldScenarioIndex !== -1)
                            oldScenario.studentsInScenario.splice(oldScenarioIndex, 1);
                    }

                    scenario.studentsInScenario.push(studentId);
                }
            },

            setScenarioDrawerOpen(state: CreateExperimentState, setScenarioDrawerOpenAction : PayloadAction<{ scenarioId: ScenarioId, open: boolean }>)
            {
                const { scenarioId, open } = setScenarioDrawerOpenAction.payload;

                const scenario = Selectors(state).getActiveScenarioByScenarioId(scenarioId);

                if(!scenario)
                {
                    Logger.error(`You tried to set the drawerOpen of a scenario that doesn't exist. ScenarioId : ${scenarioId}`);
                    return;
                }

                scenario.drawerOpen = open;
            }
        };
    }    
}

export const createExperimentSlice = new CreateExperimentSlice();