import * as React from 'react'; 

import { useSelector } from 'react-redux';
import { RootState } from '../../../../../App/Store';
import ActiveScenarioBox from './ActiveScenarioBox';

export default function ActiveScenariosContainer() : JSX.Element
{
    const activeScenarios = useSelector((state: RootState) => state.CreateExperiment.scenarioStep.activeScenarios);

    const activeScenariosBoxes : JSX.Element[] = [];
    
    for(const scenarioGroup of Object.values(activeScenarios))
    {
        const activeScenarioBox = (
            <ActiveScenarioBox
                key = { scenarioGroup.scenarioId }
                scenarioGroup = { scenarioGroup }
            />
        );
        
        activeScenariosBoxes.push(activeScenarioBox);
    }

    return (
        <> { activeScenariosBoxes } </>
    );
}