import * as React from 'react'

import { 
    Collapse,
    IconButton,
    Paper,  
    SxProps,  
    Theme,  
    Typography 
} from "@mui/material";

import { 
    ExpandLess, 
    ExpandMore 
} from '@mui/icons-material';

import { useDispatch, useSelector } from 'react-redux';
import { createExperimentSlice, CreateExperimentTypes } from '../../../../../../App/Store/CreateExpriment';
import StudentsGroupItemList from './StudentsGroupItemList';
import { RootState } from '../../../../../../App/Store';

type StudentsGroupItemListItemProps = {
    groupName: CreateExperimentTypes.GroupName,
    sx? : SxProps<Theme>
};

export default function StudentsGroupItem({ groupName, sx } : StudentsGroupItemListItemProps)
{
    const dispatch = useDispatch();

    const drawerOpen = useSelector((rootState: RootState) => rootState.CreateExperiment.groupsStep.studentGroups[groupName].drawerOpen);

    function OnClickOpen()
    {
        dispatch(createExperimentSlice.Actions.setGroupDrawerOpen({
            groupName: groupName,
            open: !drawerOpen
        }));
    }
    
    return (
        <Paper
            elevation = { 3 }
            sx = {{
                marginBottom: '.2rem',
                width: '100%',
                ...sx
            }}
        >
            <Paper 
                sx = {{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.2rem',
                }}
            >
                <Typography variant='body2' sx = {{ userSelect: 'none' }}>{ groupName }</Typography>
                
                <IconButton aria-label="open" onClick = { OnClickOpen }>
                    { !drawerOpen ? <ExpandMore /> : <ExpandLess /> }
                </IconButton>

            </Paper>
            
            <Collapse in = { drawerOpen }>
                <StudentsGroupItemList groupName = { groupName } />
            </Collapse>
        </Paper>
    );
}