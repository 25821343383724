import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { 
    Box, 
    Button, 
    Chip, 
    Stack, 
    Typography,
    darken
} from '@mui/material';

import { RootState } from '../../../../App/Store';

import ChoiceContainer from './ChoiceContainer';
import CreateExperiementationScenarioModal from '../ScenariosModal/CreateExperiementationScenarioModal';

export default function ScenarioChoice() : JSX.Element
{
    const [ t ] = useTranslation();

    const [ isScenarioBrowserOpen, setScenarioBrowserOpen ] = React.useState(false);

    const selectedScenario = useSelector((state : RootState) => state.SimpleCreateExperiment.scenario);

    const BottomContent = (
        <Stack
            direction = "row"
            justifyContent = "center"
            alignItems = "center"
            spacing = { 1 }
            
        >
            {
                (selectedScenario?.category) ? <Chip
                    size='small'
                    label = { t(`scenarios.categories.${selectedScenario.category}`) }
                    variant = 'outlined'
                    sx = {{
                        fontSize: '10pt',
                        backgroundColor: selectedScenario.colorCode,
                        borderColor: darken(selectedScenario.colorCode, 0.2),
                    }}
                /> : null
            }
            <Typography 
                variant='body2'
                fontWeight='bold'
            >
                {t(`scenarios.names.${selectedScenario?.applicationRef}`) || ''}
            </Typography>
        </Stack>
    );

    return (
        <>
            <ChoiceContainer
                title = { t('experimentation.create.scenario.choice_title') }
                displayBottom = { !!(selectedScenario) }
                bottomContent = { BottomContent }

                isOnError = { false }
                errorMessage = { t('experimentation.create.scenario.choice_noScenario') as string }
            >
                <Box
                    sx = {{
                        paddingX: '1rem',
                        paddingY: '2rem',
                    }}
                >
                    <Button 
                        variant = 'contained' 
                        fullWidth
                        onClick = { () => setScenarioBrowserOpen(true) }
                    >
                        { t('experimentation.create.scenario.choice_button') }
                    </Button>
                </Box>
            </ChoiceContainer>
            <CreateExperiementationScenarioModal
                open = { isScenarioBrowserOpen }
                handleClose = { () => setScenarioBrowserOpen(false) }
            />
        </>
    );
}