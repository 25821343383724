import { PayloadAction } from '@reduxjs/toolkit';
import AppSlice from '../../Framework/Store/AppSlice';

export type UserType = 'student' | 'teacher' | null;

export type UserState = 
{
    loggedIn: boolean,
    userType: UserType,
    firstName: string,
    lastName: string,
    token: string,
};

export type UserStorageMode = 'local' | 'session';

export default class UserSlice extends AppSlice<UserState>
{
    protected getConfig()
    {
        return {
            name: 'User',
            initialState: UserSlice.InitialState
        };
    }

    public static StorageSystem = localStorage;
    public static StorageSystemMode : UserStorageMode = 'local';

    public static readonly InitialState : UserState = UserSlice.GetPersistantState();

    public static GetPersistantState() : UserState
    {
        const userType = UserSlice.StorageSystem.getItem('userType') as UserType;
        const firstName = UserSlice.StorageSystem.getItem('firstName') || '';
        const lastName = UserSlice.StorageSystem.getItem('lastName') || '';
        const token = UserSlice.StorageSystem.getItem('token') || '';

        return {
            loggedIn: (token !== '' && userType !== null),
            userType,
            token,
            firstName,
            lastName,
        };
    }

    public setLogin(state: UserState, loggedIn: boolean, userType: UserType, token: string)
    {
        state.loggedIn = loggedIn;
        state.userType = userType;
        state.token = token;

        UserSlice.StorageSystem.setItem('userType', userType || '');
        UserSlice.StorageSystem.setItem('token', token);

        if(!loggedIn)
        {
            state.firstName = '';
            state.lastName = '';

            UserSlice.StorageSystem.removeItem('token');
            UserSlice.StorageSystem.removeItem('userType');

            state.userType = null;
            state.token = '';
        }
    }

    public getReducers()
    {
        return {

            setLogin(state: UserState, action : PayloadAction<{ loggedIn: boolean, userType: UserType, token: string }>) 
            {
                const { loggedIn, userType, token } = action.payload;
                userSlice.setLogin(state, loggedIn, userType, token);
            },

            setName(state: UserState, action : PayloadAction<{ firstName: string, lastName: string }>)
            {
                const { firstName, lastName } = action.payload;
                
                state.firstName = firstName;
                state.lastName = lastName;

                UserSlice.StorageSystem.setItem('firstName', state.firstName);
                UserSlice.StorageSystem.setItem('lastName', state.lastName);
            },
            
            setStorageType(state: UserState, action : PayloadAction<UserStorageMode>)
            {
                const mode = action.payload;
                
                if(mode === UserSlice.StorageSystemMode) return;

                userSlice.setLogin(state, false, null, '');

                switch(mode)
                {
                    case 'local': 
                        UserSlice.StorageSystem = localStorage;
                        UserSlice.StorageSystemMode = 'local';
                        break;
                    case 'session': 
                        UserSlice.StorageSystem = sessionStorage;
                        UserSlice.StorageSystemMode = 'session';
                        break;
                }
        
                state = UserSlice.GetPersistantState();
            }
        };
    }
}

export const userSlice = new UserSlice();