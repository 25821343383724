import { IdentifiableClassType } from "./IdentifiableClass";

export type Identity = {
    readonly ID: symbol,
    readonly NAME: string,
    readonly CLASS: IdentifiableClassType,
}

type IdentitiesMap = {
    [key: string] : Identity
}

export class IdentitiesGenerator
{
    private static identitiesMap: IdentitiesMap = {};

    public static GetIdentityOf(sourceClass: IdentifiableClassType): Identity
    {
        const className = sourceClass.name;

        if(IdentitiesGenerator.identitiesMap[className] === undefined)
        {
            const identity : Identity = {
                ID: Symbol(),
                NAME: className,
                CLASS: sourceClass
            };

            IdentitiesGenerator.identitiesMap[className] = identity;
            return identity;
        }
        else
        {
            return IdentitiesGenerator.identitiesMap[className];
        }
    }
}