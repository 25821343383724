import * as React from 'react';

import { FormControlLabel, Grid, Paper, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type SkipPreparationOptProps = {
    setModified: (state: boolean) => void
}

export default function SkipPreparationOpt({ setModified } : SkipPreparationOptProps) : JSX.Element
{
    const [collabMode, setCollabMode] = React.useState(false);
    const [t] = useTranslation();

    function IsDefaultState() : boolean { return (collabMode === false) }

    function OnClickCheck()
    {
        setCollabMode(!collabMode)
        setModified(IsDefaultState());
    }

    return (
        <Grid item xs={ 1 }>
            <Paper
                sx = {{
                    padding: '1rem'
                }}
            >
                
                <Typography variant='h6' component='h3'>{t('experiment.skip_preparation.title')}</Typography>
                <Typography variant='body2'>{t('experiment.skip_preparation.desc')}</Typography>
                
                <Paper
                    square = { false }
                    variant="outlined"
                    sx = {{
                        marginTop: '1rem',
                        maxWidth: 'fit-content',
                        paddingY: '0.3rem',
                        paddingX: '0.7rem',
                        backgroundColor: collabMode ? 'rgb(0, 184, 81)' : undefined,
                        borderRadius: '2rem',
                        userSelect: 'none'
                    }}
                >
                    <FormControlLabel
                        control = {
                            <Switch
                                checked = { collabMode }
                                onChange = { OnClickCheck }
                                value = "checked"
                                color = "default"
                            />
                        }
                        labelPlacement = "end"
                        label = { collabMode ? 'Activé' : 'Désactivé' }
                    />
                </Paper>
            </Paper>
        </Grid>
    )
}