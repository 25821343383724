import * as React from 'react'

import { 
    Box,
    Container, Typography 
} from '@mui/material';

import BackButton from '../../Components/Shared/BackButton';

import { useTranslation } from 'react-i18next'

export default function CGUView() : JSX.Element 
{
    const [ t ] = useTranslation();

    return (
        <Container maxWidth='md' >
            <BackButton />
            <Box sx = {{ textAlign:'center' }}>
                <Typography variant='h3' component='h1' gutterBottom>{t('cgu.title')}</Typography>
                <Typography variant='h4' component='h2'gutterBottom>{t('cgu.desc.title')}</Typography>
                <Typography variant='body1' gutterBottom>{t('cgu.desc.desc')}</Typography>
                <Typography variant='h4' component='h2' gutterBottom>{t('cgu.rights.title')}</Typography>
                <Typography variant='body1' gutterBottom>{t('cgu.rights.desc')}</Typography>
                <Typography variant='h4' component='h2' gutterBottom>{t('cgu.school_usage.title')}</Typography>
                <Typography variant='body1' gutterBottom>{t('cgu.school_usage.desc')}</Typography>
            </Box>
        </Container>
    );
}