import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../App/Store';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { 
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    Container, 
    Grid,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';

import {
    ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';

import ClassChoice from './Choices/ClassChoice';
import OptionsChoice from './Choices/OptionsChoice';
import ScenarioChoice from './Choices/ScenarioChoice';

import QuerryErrorSnackbarAlert from '../../Components/Shared/QuerryErrorAlert';

import { simpleCreateExperimentSlice } from '../../../App/Store/SimpleCreateExperiment/SimpleCreateExperiementSlice';
import { Experiments } from '../../../App/API/Experiments';
import Logger from '../../../App/Framework/Utils/Logger';

export default function SimpleCreateExperiementation() : JSX.Element
{
    const [ t ] = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const simplExpState = useSelector((state: RootState) => state.SimpleCreateExperiment);
    const token = useSelector((state : RootState) => state.User.token);

    const [ newExperiementForClass, { isLoading, error } ] = Experiments.useNewExperiementForClassMutation();

    async function OnClickCreate()
    {
        if(isLoading) return;

        try {
            const { experimentId } = await newExperiementForClass({
                token,
                classId: simplExpState.class?.id || -1,
                scenarioId: simplExpState.scenario?.id || -1,
                skipPreparationSteps: simplExpState.options.skipPreparation,
            }).unwrap();

            Logger.info(`Created new experimentation with index ${experimentId}`);

            dispatch(simpleCreateExperimentSlice.Actions.resetCreateExperiment(undefined));

            navigate(`/experimentations/${experimentId}`);
        }
        catch(err) 
        {
            Logger.error('While create newExperiementForClass', err);
        }
    }

    const isExperimentationOptionsSelected = () => !(!simplExpState.class || !simplExpState.scenario)

    return (
        <Container maxWidth='md'>
            <Grid container spacing={ 3 }>
                <Grid item xs={ 12 } md={ 4 }>
                    <ClassChoice/>
                </Grid>
                <Grid item xs={ 12 } md={ 4 }>
                    <ScenarioChoice/>
                </Grid>
                <Grid item xs={ 12 } md={ 4 }>
                    <OptionsChoice/>
                </Grid>

                <Grid item xs={ 12 }
                    sx = {{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <Typography variant="h4">
                        
                    </Typography>
                    { error && <QuerryErrorSnackbarAlert error={error}/> }
                    {
                        isExperimentationOptionsSelected() &&
                        <Button
                            onClick = { OnClickCreate }
                            variant='contained'
                            size='large'
                            disabled = { !isExperimentationOptionsSelected() }
                            sx = {{
                                paddingX: '4rem'
                            }}
                        >
                            { t('experimentation.create.create_button') }
                        </Button>
                    }
                </Grid>
                <Grid item xs={ 12 }>
                    <BottomAccordion/>
                </Grid>
            </Grid>
        </Container>
    );
}

function BottomAccordion() : JSX.Element
{
    const [ t ] = useTranslation();

    const [ expanded, setExpanded ] = React.useState(true);

    function OnClickConfig()
    {
        // TODO : Implement Advanced creation redirection
    }

    return (
        <Box
            sx = {{
                paddingY : '2rem'
            }}
        >
            <Accordion
                expanded = { expanded }
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    onClick={ () => setExpanded(!expanded) }
                >
                    <Typography variant='h6' component='h4'>{ t('experimentation.create.advanced.title') }</Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx = {{
                        userSelect: 'none'
                    }}
                >
                    <Typography 
                        variant = 'body1' 
                        color = 'text.secondary'
                        sx = {{
                            marginBottom: '1.5rem'
                        }}
                    >
                        { t('experimentation.create.advanced.description') }
                    </Typography>
                    
                    <Stack
                        direction = {{ xs: 'column', sm: 'row' }}
                        justifyContent = "space-between"
                        alignItems = "center"
                        spacing = { 2 }
                    >
                        <Alert severity = 'warning'>
                            { t('experimentation.create.advanced.warning') }
                        </Alert>
                        <Tooltip title="Unvailable for now" placement='top'>
                            <Button
                                variant = 'contained'
                                color = 'info'
                                size = 'large'
                                onClick = { OnClickConfig }
                                sx = {{
                                    cursor: 'not-allowed'
                                }}
                            >
                                { t('experimentation.create.advanced.button') }
                            </Button>
                        </Tooltip>  
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}