import * as React from 'react';

import {
    Button,
    Container, 
    Stack, 
    Typography
} from '@mui/material';

import { UnityAppPlayer } from '../../Components/UnityApp';
import { Scenario } from '../../../App/Store/SimpleCreateExperiment/Types';
import { useTranslation } from 'react-i18next';

import PlayScenarioModal from './ScenariosModal/PlayScenarioModal';

export default function TeacherPlayAloneView()
{
    const { t } = useTranslation();

    const [ modalOpen, setModalOpen ] = React.useState(false);
    const [ selectedScenario, setSelectedScenario ] = React.useState<Scenario | null>(null);

    return (
        <Container maxWidth='xl'>
            { 
                !selectedScenario && 
                (
                    <>
                        <Stack
                            direction='column'
                            justifyContent='center'
                            alignItems='center'
                            my='2rem'
                        >
                            <Typography variant='body1' textAlign='center' color='text.secondary' my='1rem'>
                                { t('teacher_play_alone.start_notice') }
                            </Typography>
                            <Button 
                                variant = 'contained'
                                onClick = { () => setModalOpen(true) }
                                sx = {{
                                    mb: '2rem'
                                }}
                            >
                                { t('teacher_play_alone.scenario_modal.open') }
                            </Button>
                        </Stack>
                        <PlayScenarioModal
                            open = { modalOpen }
                            handleClose = { () => setModalOpen(false) }
                            selectedScenario = { selectedScenario }
                            setSelectedScenario = { (scenario) => setSelectedScenario(scenario) }
                        />
                    </>
                )
            }
            {
                selectedScenario &&
                (
                    <UnityAppPlayer
                        scenarioRef = { selectedScenario.applicationRef }
                        downloadOnEnd = { true }
                        skipPreparationSteps = { false }
                        trackProgress = { false }
                    />
                )
            }
        </Container>
    );
}