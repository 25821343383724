import { io } from 'socket.io-client';
import Logger from '../Framework/Utils/Logger';

export function connectSocket(socketNamespace : string = '', token? : string)
{
    const address = `${process.env.REACT_APP_URL_ROOT || ''}${socketNamespace}`

    const socket = io(
        address, 
        {
            auth: {
                token,
            },
            transports: [ 'polling', 'websocket', 'webtransport' ]
        }
    );


    socket.on('connect', () => 
    {
        Logger.info('Successfully connected socket to remote', address);
    });

    return socket;
}