import NeurolaboIcon from '../../assets/images/neurolabo-logo.svg';
import NeurolaboFullIcon from '../../assets/images/neurolabo-logo-full.svg';

export function NeurolaboLogoIcon() : JSX.Element
{
    return (
        <img 
            alt='neurolabo-icon'
            src = { NeurolaboIcon } 
            style={{
                width: 30,
                height: 30
            }}
        />
    );
}

export function NeurolaboLogoFullIcon() : JSX.Element
{
    return (
        <img
            alt='neurolabo-icon'
            src= { NeurolaboFullIcon }
            style={{
                width: 170,
                height: 34
            }}
        />
    );
}