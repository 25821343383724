import * as React from 'react';

import { Container } from '@mui/material';

import WellcomeWidget from './WellcomeWidget';
import { MyExperimentationsWidget } from '../MyExperimentations';
import { PlayExperimentationWidget } from '../Play';

export default function StudentHomeView() : JSX.Element
{
    return (
        <Container maxWidth='xl'>
            <WellcomeWidget />
            <PlayExperimentationWidget />
            <MyExperimentationsWidget/>
        </Container>
    )
}