import * as React from 'react';

import {
    Button,
    ButtonGroup,
    ButtonPropsSizeOverrides,
    ButtonPropsVariantOverrides,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    styled,
    StyledComponentProps
} from '@mui/material';

import { 
    ArrowDropDown, 
    ArrowDropUp 
} from '@mui/icons-material';

import { OverridableStringUnion } from '@mui/types';

import { ButtonStartIcon } from '../Shared/ButtonIcons';

export type SelectOptionItemConfig = {
    icon?: JSX.Element,
    text: string,
    onClick?: () => void
};

type OnClickButtonCallack = (open : boolean, setOpen : (op : boolean) => void) => void | (() => void);

export interface SelectOptionWithIconProps extends StyledComponentProps
{
    placeholderIcon?: JSX.Element;
    placeholder?: string;
    buttonGroupAriaLabel?: string;
    buttonAriaLabel?: string;
    onClick?: OnClickButtonCallack;
    itemsConfigs: SelectOptionItemConfig[];
    activeItemKey? : string;
    fullWidth?: boolean;
    variant?: OverridableStringUnion<"text" | "contained" | "outlined", ButtonPropsVariantOverrides> | undefined;
    size?: OverridableStringUnion<"small" | "medium" | "large", ButtonPropsSizeOverrides> ;
};

export const DarkSelectOptionWithIcon = styled(SelectOptionWithIcon)
(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
}))

export default function SelectOptionWithIcon({ placeholderIcon, placeholder, buttonGroupAriaLabel, buttonAriaLabel, onClick = () => {}, itemsConfigs, activeItemKey, fullWidth, variant, size } : SelectOptionWithIconProps) : JSX.Element
{
    const [ open, setOpen ] = React.useState(false);
    const [ selectedIndex, setSelectedIndex ] = React.useState(-1);

    const anchorRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => 
    {
        if(activeItemKey)
        {
            const activeIndex = itemsConfigs.findIndex(item => item.text === activeItemKey);
            if(activeIndex !== -1)
                setSelectedIndex(activeIndex);
        }
    }, [activeItemKey, itemsConfigs]);

    return (
        <>
            <ButtonGroup
                variant='contained'
                ref = { anchorRef }
                aria-label= { buttonGroupAriaLabel }
                fullWidth = { fullWidth }
            >
                <Button
                    size = { size || 'small' }
                    onClick={ () => { if(onClick) onClick(open, setOpen); setOpen(!open)} }
                    variant = { variant || 'outlined' }
                    startIcon = 
                    { 
                        (selectedIndex === -1 || !itemsConfigs[selectedIndex]?.icon) 
                            ? placeholderIcon 
                            : itemsConfigs[selectedIndex].icon
                    }
                    sx = { fullWidth ? { width: '75%' } : undefined}

                >
                {
                    // Button Content
                    (selectedIndex === -1) ? placeholder : itemsConfigs[selectedIndex].text
                }
                </Button>
                
                <Button
                    size = { size || 'small' }
                    variant = { variant || 'outlined' }
                    aria-controls = { open ? 'split-button-menu' : undefined }
                    aria-expanded = { open ? 'true' : undefined }
                    aria-label = { buttonAriaLabel }
                    aria-haspopup = "menu"
                    onClick = { () => setOpen(!open) }

                    sx = { fullWidth ? { width: '25%' } : undefined}
                >
                    { open ? <ArrowDropUp/> : <ArrowDropDown/> }
                </Button>

            </ButtonGroup>

            <Popper
                open = { open }
                anchorEl = { anchorRef.current }
                role = { undefined }    
                sx={{
                    zIndex: 1
                }}
                transition
                disablePortal
            >
                {
                    // Options Popper 
                    ({ TransitionProps, placement }) => (
                        <ClickAwayListener
                            onClickAway = { () => setOpen(false) }
                        >
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={ () => setOpen(false) }>
                                        <>
                                            <OptionItems
                                                setSelectedIndex = { (index) => { setOpen(false); setSelectedIndex(index) } }
                                                selectedIndex = { selectedIndex } 
                                                configs = { itemsConfigs } 
                                            />
                                        </>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        </ClickAwayListener>
                    )
                }
            </Popper>
        </>
    );
}

type OptionItemsProps = {
    selectedIndex: number,
    setSelectedIndex: (index: number) => void,
    configs: SelectOptionItemConfig[]
};

function OptionItems({ selectedIndex, setSelectedIndex, configs } : OptionItemsProps) : JSX.Element
{
    const options: JSX.Element[] = [];

    for(let i = 0; i < configs.length; ++i)
    {
        const config = configs[i];

        const option = (
            <OptionItem 
                key = { config.text }
                setSelectedIndex = { () => setSelectedIndex(i) }
                selected = { i === selectedIndex }
                config = { config } 
            />
        );

        options.push(option);
    }

    return (
        <MenuList autoFocusItem>
            { options }
        </MenuList>
    );
}

type OptionItemProps = {
    selected: boolean
    setSelectedIndex: () => void,
    config: SelectOptionItemConfig
};

function OptionItem({ selected, setSelectedIndex, config } : OptionItemProps) : JSX.Element
{
    function onClick()
    {
        setSelectedIndex(); 
        if(config.onClick)
            config.onClick();
    }

    return (
        <MenuItem
            selected = { selected }
            onClick = { onClick }
        >
            <ButtonStartIcon>
                { config.icon }
            </ButtonStartIcon>

            { config.text }
        </MenuItem>
    );
}