import * as React from 'react'

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../App/Store';

import Logger from '../../../../App/Framework/Utils/Logger';
import { Classes } from '../../../../App/API/Classes';
import { Class } from '../../../../App/Store/SimpleCreateExperiment/Types';

import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';

type InvalidateLinkButtonProps = {
    selectedClass: Class
}

export default function InvalidateLinkButton({ selectedClass }: InvalidateLinkButtonProps): JSX.Element {
    const { t } = useTranslation();
    const token = useSelector((state: RootState) => state.User.token);
    const [invalidateLinkRequest] = Classes.useInvalidateLinkMutation();
    const [popupOpened, setPopupOpened] = React.useState(false);

    async function InvalidateLink() {
        try {
            const className = selectedClass.name;
            await invalidateLinkRequest({
                token,
                classId: selectedClass.id,
            }).unwrap();
            Logger.info(t('teacher.classes_view.remove_class.success') + className);
        }
        catch (err) {
            Logger.error("When removing class : ", err);
        }
    }

    return (
        <>
            <Button
                variant='contained'
                onClick={() => setPopupOpened(true)}
                color='error'
                sx={{ ml: 5 }}
            >
                {t('teacher.classes_view.list.manage.invalidate')}
            </Button>
            <Dialog
                open={popupOpened}
                keepMounted
                onClose={() => setPopupOpened(false)}
            >
                <DialogTitle> {t('teacher.classes_view.list.manage.invalidate')} </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        color='text.primary'
                    >
                        {t('teacher.classes_view.list.manage.invalidate_desc')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={
                            () => {
                                setPopupOpened(false);
                                InvalidateLink();
                            }
                        }
                        variant='contained'
                        color='error'
                    >
                        {t('teacher.classes_view.list.manage.invalidate')}
                    </Button>
                    <Button
                        onClick={() => setPopupOpened(false)}
                        variant='contained'
                        color='info'
                    >
                        {t('teacher.classes_view.list.manage.invalidate_cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}