import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Hostinfo from '../Framework/Utils/HostInfo';

const baseQuery = fetchBaseQuery(
{
    baseUrl: Hostinfo.GetHostUrl(),
});

type GetEppnPostResponse = string;

export const FrontAPI = createApi({
    reducerPath: '/',
    baseQuery,
    endpoints: (build) => (
    {
        getEppn: build.query<GetEppnPostResponse, void>(
        {
            query: () => ({
                url: '/get-eppn',
                method: 'GET',
                responseType: 'text',
                responseHandler: async (response: Response) => await response.text(),
            })
        }),
        shibbolethSSOLogout : build.mutation<string, void>(
        {
            query: () => ({
                url: process.env.REACT_APP_SHIB_LOGOUT || 'INVALID .ENV VALUE REACT_APP_SHIB_LOGOUT',
                method: 'GET'
            }),
        })
    })
});

export const {
    useGetEppnQuery,
    useShibbolethSSOLogoutMutation,
} = FrontAPI;