import * as React from 'react';

import { 
    Box,
    Container, 
    Link,
    Typography
} from '@mui/material';

import { UnityAppPlayer } from '../../Components/UnityApp';
import { useTranslation } from 'react-i18next';

type UnityAppDemoViewProps = {
    displaydesc?: boolean,
    displaycontact?: boolean,
    applicationRef?: string,
    downloadOnEnd?: boolean
}

export default function UnityAppDemoView({ displaydesc = false, displaycontact = true, applicationRef = "", downloadOnEnd = true } : UnityAppDemoViewProps)
{
    const { t } = useTranslation();

    return (
        <Container maxWidth='xl'>
            <UnityAppPlayer
                scenarioRef = { applicationRef }
                downloadOnEnd = { downloadOnEnd }
                skipPreparationSteps = { false }
                trackProgress = { false }
            />
            <Box
                textAlign='center'
                marginY='2rem'
            >
                <Typography variant='body1'>
                    { t('demo.made_by') }<Link href="https://practeex.com/" target="_blank">Practeex</Link>
                </Typography>
                {
                    displaydesc && <Typography my='1rem' variant='body2'>{ t('demo.desc') }</Typography>
                }
                {
                    displaycontact && <Typography variant='body1'>{ t('demo.contact') }</Typography>
                }
            </Box>
        </Container>
    );
}